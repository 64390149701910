export const strict = false

export const state = () => ({
  active: false
})

export const mutations = {
  SET_SESSION: (state, active) => {
    state.active = active
  }
}

export const actions = {
  setSession({ commit }, value) {
    commit('SET_SESSION', value)
  }
}
