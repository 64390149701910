<template>
  <v-menu bottom offset-y>
    <template #activator="{ on }">
      <v-btn
        :max-height="36"
        :max-width="36"
        rounded
        outlined
        class="mr-4"
        fab
        small
        v-on="on"
      >
        <v-badge
          :value="hasUnreadNotifications"
          :offset-x="2"
          color="error"
          overlap
          dot
        >
          <v-icon small>fal fa-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card outlined>
      <v-list class="pb-0 notification-list">
        <span
          v-if="hasUnreadNotifications"
          class="d-block text-right text-caption mb-2 mr-4 mark-as-read"
          @click="markAllAsRead()"
        >
          mark all as read
        </span>
        <template v-for="(n, i) in lastNotifications">
          <Notifications-description :key="n.id" :n="n" />
          <v-divider :key="i"></v-divider>
        </template>
        <v-list-item href="/notifications">
          <v-list-item-content class="AlignItemsCentre">
            <v-list-item-action-text>
              See all notifications
            </v-list-item-action-text>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import NotificationsDescription from '@/components/notifications/NotificationsDescription.vue'

export default {
  name: 'HeaderNotificationsMenu',
  components: {
    NotificationsDescription
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.list
    }),
    hasUnreadNotifications() {
      for (let i = 0; i < this.notifications.length; i += 1) {
        const n = this.notifications[i]
        if (!n.hasBeenClicked && n.type !== 'InternalCommunication') return true
      }
      return false
    },
    lastNotifications() {
      return this.notifications.length > 6
        ? this.notifications.slice(0, 6)
        : this.notifications
    }
  },
  methods: {
    async markAllAsRead() {
      try {
        await this.$store.dispatch('notifications/markAllAsRead')
      } catch (error) {
        this.$asa.appInsights('Comp: header/NotificationsMenu.vue - method: markAllAsRead', error)
      }
    }
  }
}
</script>

<style scoped>
.v-list__tile__content.AlignItemsCentre {
  align-items: center;
}
.v-list.NoPaddingBottom {
  padding-bottom: 0;
}
.mark-as-read {
  cursor: pointer;
}
.mark-as-read:hover {
  text-decoration: underline;
}
.notification-list .v-list-item__subtitle,
.v-list-item__title {
  white-space: normal;
}
</style>
