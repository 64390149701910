<template>
  <v-dialog v-model="showNewVersionDialog" persistent width="400">
    <v-card outlined>
      <v-card-text class="pt-4">
        <div class="text-center mb-6">
          <v-icon x-large color="success">
            fal fa-smile-wink
          </v-icon>
        </div>
        <div class="text-center">
          <div>
            A new version of 100ASA has been released, please reload your
            browser.
          </div>
        </div>
        <div class="text-center mt-6">
          <v-btn
            outlined
            depressed
            class="mx-3"
            @click="showNewVersionDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn depressed dark class="mx-3" @click="reloadBrowser">
            Reload
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewVersionDialog',
  data() {
    return {
      showNewVersionDialog: false,
      showDialog: false,
      checkVersionTimeoutId: undefined
    }
  },
  destroyed() {
    if (this.checkVersionTimeoutId) {
      clearTimeout(this.checkVersionTimeoutId)
    }
  },
  mounted() {
    this.checkVersionTimeoutId = setTimeout(() => {
      this.checkForNewVersion()
    })
  },
  methods: {
    async checkForNewVersion() {
      const workbox = await window.$workbox
      if (workbox) {
        workbox.addEventListener('installed', event => {
          if (event.isUpdate && this.showDialog) {
            this.showNewVersionDialog = true
          }
        })
      }
    },
    reloadBrowser() {
      window.location.reload()
    }
  }
}
</script>
