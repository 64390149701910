import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ead7bd3 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _2c0753cb = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4b404edc = () => interopDefault(import('../pages/albums/index.vue' /* webpackChunkName: "pages/albums/index" */))
const _e0dfaa46 = () => interopDefault(import('../pages/ambassadors.vue' /* webpackChunkName: "pages/ambassadors" */))
const _25b0a74c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _ef41ee70 = () => interopDefault(import('../pages/books/index.vue' /* webpackChunkName: "pages/books/index" */))
const _37aec3ba = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _8a833c9a = () => interopDefault(import('../pages/community-awarded.vue' /* webpackChunkName: "pages/community-awarded" */))
const _47e7aa92 = () => interopDefault(import('../pages/community-reviews.vue' /* webpackChunkName: "pages/community-reviews" */))
const _f722d7ee = () => interopDefault(import('../pages/competitions.vue' /* webpackChunkName: "pages/competitions" */))
const _e7e246c0 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _fe6b21dc = () => interopDefault(import('../pages/curators.vue' /* webpackChunkName: "pages/curators" */))
const _4116036f = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _7b0cdd24 = () => interopDefault(import('../pages/elite.vue' /* webpackChunkName: "pages/elite" */))
const _088ac131 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _49d31ad8 = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _f3a32ee4 = () => interopDefault(import('../pages/galleries/index.vue' /* webpackChunkName: "pages/galleries/index" */))
const _63611f1e = () => interopDefault(import('../pages/how-does-it-work.vue' /* webpackChunkName: "pages/how-does-it-work" */))
const _43f4b8f6 = () => interopDefault(import('../pages/initiatives/index.vue' /* webpackChunkName: "pages/initiatives/index" */))
const _00756405 = () => interopDefault(import('../pages/inspiration.vue' /* webpackChunkName: "pages/inspiration" */))
const _db4641d0 = () => interopDefault(import('../pages/leaderboard.vue' /* webpackChunkName: "pages/leaderboard" */))
const _7ecd2354 = () => interopDefault(import('../pages/learning/index.vue' /* webpackChunkName: "pages/learning/index" */))
const _e82da178 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3e4dc1bf = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5280afae = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _20c809ce = () => interopDefault(import('../pages/membership/index.vue' /* webpackChunkName: "pages/membership/index" */))
const _5d96229c = () => interopDefault(import('../pages/notes.vue' /* webpackChunkName: "pages/notes" */))
const _3936eb7a = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _2bf2a0f3 = () => interopDefault(import('../pages/opening.vue' /* webpackChunkName: "pages/opening" */))
const _787e85c0 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _36fa5a50 = () => interopDefault(import('../pages/photo/index.vue' /* webpackChunkName: "pages/photo/index" */))
const _2bdf7301 = () => interopDefault(import('../pages/photographer/index.vue' /* webpackChunkName: "pages/photographer/index" */))
const _d30b4436 = () => interopDefault(import('../pages/photographers.vue' /* webpackChunkName: "pages/photographers" */))
const _f681114c = () => interopDefault(import('../pages/prime.vue' /* webpackChunkName: "pages/prime" */))
const _c2c8d868 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _cce9c596 = () => interopDefault(import('../pages/public-critiques/index.vue' /* webpackChunkName: "pages/public-critiques/index" */))
const _6f944aae = () => interopDefault(import('../pages/reputation.vue' /* webpackChunkName: "pages/reputation" */))
const _34b9f61c = () => interopDefault(import('../pages/reward-points.vue' /* webpackChunkName: "pages/reward-points" */))
const _5d8a1cbf = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _6ee03eae = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _3d96a64d = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _3977fc2e = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _5b922dde = () => interopDefault(import('../pages/upcoming.vue' /* webpackChunkName: "pages/upcoming" */))
const _e7495558 = () => interopDefault(import('../pages/use-of-cookies.vue' /* webpackChunkName: "pages/use-of-cookies" */))
const _26b6d1fd = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _0cd3298a = () => interopDefault(import('../pages/account/albums.vue' /* webpackChunkName: "pages/account/albums" */))
const _e1cc7bc6 = () => interopDefault(import('../pages/account/critiques/index.vue' /* webpackChunkName: "pages/account/critiques/index" */))
const _7d84d10b = () => interopDefault(import('../pages/account/curated.vue' /* webpackChunkName: "pages/account/curated" */))
const _1cd8b7cc = () => interopDefault(import('../pages/account/curation.vue' /* webpackChunkName: "pages/account/curation" */))
const _4e07cee9 = () => interopDefault(import('../pages/account/galleries.vue' /* webpackChunkName: "pages/account/galleries" */))
const _11d95d92 = () => interopDefault(import('../pages/account/licensing.vue' /* webpackChunkName: "pages/account/licensing" */))
const _0dc50d58 = () => interopDefault(import('../pages/account/photos.vue' /* webpackChunkName: "pages/account/photos" */))
const _3df06cc1 = () => interopDefault(import('../pages/account/portfolio.vue' /* webpackChunkName: "pages/account/portfolio" */))
const _40f99ce2 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _545fd24b = () => interopDefault(import('../pages/account/reset/index.vue' /* webpackChunkName: "pages/account/reset/index" */))
const _907e278c = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _6c35b2b2 = () => interopDefault(import('../pages/learning/professional-critiques.vue' /* webpackChunkName: "pages/learning/professional-critiques" */))
const _6f3e70cd = () => interopDefault(import('../pages/membership/canceled.vue' /* webpackChunkName: "pages/membership/canceled" */))
const _9b965102 = () => interopDefault(import('../pages/membership/success.vue' /* webpackChunkName: "pages/membership/success" */))
const _553400c4 = () => interopDefault(import('../pages/orders/canceled.vue' /* webpackChunkName: "pages/orders/canceled" */))
const _5267aa8e = () => interopDefault(import('../pages/orders/success.vue' /* webpackChunkName: "pages/orders/success" */))
const _2ad75b86 = () => interopDefault(import('../pages/shop/prints/index.vue' /* webpackChunkName: "pages/shop/prints/index" */))
const _6715a21e = () => interopDefault(import('../pages/account/critiques/canceled.vue' /* webpackChunkName: "pages/account/critiques/canceled" */))
const _5a0c8fe4 = () => interopDefault(import('../pages/account/critiques/success.vue' /* webpackChunkName: "pages/account/critiques/success" */))
const _3a40c404 = () => interopDefault(import('../pages/shop/prints/canceled.vue' /* webpackChunkName: "pages/shop/prints/canceled" */))
const _3a10c02e = () => interopDefault(import('../pages/shop/prints/success.vue' /* webpackChunkName: "pages/shop/prints/success" */))
const _795e9bc7 = () => interopDefault(import('../pages/albums/_id/index.vue' /* webpackChunkName: "pages/albums/_id/index" */))
const _7bc1edb9 = () => interopDefault(import('../pages/blog/_uid.vue' /* webpackChunkName: "pages/blog/_uid" */))
const _69d368ea = () => interopDefault(import('../pages/books/_uid.vue' /* webpackChunkName: "pages/books/_uid" */))
const _5fb93676 = () => interopDefault(import('../pages/galleries/_id.vue' /* webpackChunkName: "pages/galleries/_id" */))
const _cd04658a = () => interopDefault(import('../pages/photo/_id/index.vue' /* webpackChunkName: "pages/photo/_id/index" */))
const _89ff7cae = () => interopDefault(import('../pages/photographer/_id.vue' /* webpackChunkName: "pages/photographer/_id" */))
const _230ed4c0 = () => interopDefault(import('../pages/public-critiques/_id/index.vue' /* webpackChunkName: "pages/public-critiques/_id/index" */))
const _77a7fa7f = () => interopDefault(import('../pages/albums/_id/_slug.vue' /* webpackChunkName: "pages/albums/_id/_slug" */))
const _d071a81a = () => interopDefault(import('../pages/photo/_id/_slug.vue' /* webpackChunkName: "pages/photo/_id/_slug" */))
const _267c1750 = () => interopDefault(import('../pages/public-critiques/_id/_slug.vue' /* webpackChunkName: "pages/public-critiques/_id/_slug" */))
const _7b7ce7a6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _7ead7bd3,
    name: "about-us"
  }, {
    path: "/account",
    component: _2c0753cb,
    name: "account"
  }, {
    path: "/albums",
    component: _4b404edc,
    name: "albums"
  }, {
    path: "/ambassadors",
    component: _e0dfaa46,
    name: "ambassadors"
  }, {
    path: "/blog",
    component: _25b0a74c,
    name: "blog"
  }, {
    path: "/books",
    component: _ef41ee70,
    name: "books"
  }, {
    path: "/callback",
    component: _37aec3ba,
    name: "callback"
  }, {
    path: "/community-awarded",
    component: _8a833c9a,
    name: "community-awarded"
  }, {
    path: "/community-reviews",
    component: _47e7aa92,
    name: "community-reviews"
  }, {
    path: "/competitions",
    component: _f722d7ee,
    name: "competitions"
  }, {
    path: "/contact-us",
    component: _e7e246c0,
    name: "contact-us"
  }, {
    path: "/curators",
    component: _fe6b21dc,
    name: "curators"
  }, {
    path: "/dashboard",
    component: _4116036f,
    name: "dashboard"
  }, {
    path: "/elite",
    component: _7b0cdd24,
    name: "elite"
  }, {
    path: "/faq",
    component: _088ac131,
    name: "faq"
  }, {
    path: "/forgot",
    component: _49d31ad8,
    name: "forgot"
  }, {
    path: "/galleries",
    component: _f3a32ee4,
    name: "galleries"
  }, {
    path: "/how-does-it-work",
    component: _63611f1e,
    name: "how-does-it-work"
  }, {
    path: "/initiatives",
    component: _43f4b8f6,
    name: "initiatives"
  }, {
    path: "/inspiration",
    component: _00756405,
    name: "inspiration"
  }, {
    path: "/leaderboard",
    component: _db4641d0,
    name: "leaderboard"
  }, {
    path: "/learning",
    component: _7ecd2354,
    name: "learning"
  }, {
    path: "/login",
    component: _e82da178,
    name: "login"
  }, {
    path: "/logout",
    component: _3e4dc1bf,
    name: "logout"
  }, {
    path: "/maintenance",
    component: _5280afae,
    name: "maintenance"
  }, {
    path: "/membership",
    component: _20c809ce,
    name: "membership"
  }, {
    path: "/notes",
    component: _5d96229c,
    name: "notes"
  }, {
    path: "/notifications",
    component: _3936eb7a,
    name: "notifications"
  }, {
    path: "/opening",
    component: _2bf2a0f3,
    name: "opening"
  }, {
    path: "/partners",
    component: _787e85c0,
    name: "partners"
  }, {
    path: "/photo",
    component: _36fa5a50,
    name: "photo"
  }, {
    path: "/photographer",
    component: _2bdf7301,
    name: "photographer"
  }, {
    path: "/photographers",
    component: _d30b4436,
    name: "photographers"
  }, {
    path: "/prime",
    component: _f681114c,
    name: "prime"
  }, {
    path: "/privacy-policy",
    component: _c2c8d868,
    name: "privacy-policy"
  }, {
    path: "/public-critiques",
    component: _cce9c596,
    name: "public-critiques"
  }, {
    path: "/reputation",
    component: _6f944aae,
    name: "reputation"
  }, {
    path: "/reward-points",
    component: _34b9f61c,
    name: "reward-points"
  }, {
    path: "/rewards",
    component: _5d8a1cbf,
    name: "rewards"
  }, {
    path: "/shop",
    component: _6ee03eae,
    name: "shop"
  }, {
    path: "/signup",
    component: _3d96a64d,
    name: "signup"
  }, {
    path: "/terms-and-conditions",
    component: _3977fc2e,
    name: "terms-and-conditions"
  }, {
    path: "/upcoming",
    component: _5b922dde,
    name: "upcoming"
  }, {
    path: "/use-of-cookies",
    component: _e7495558,
    name: "use-of-cookies"
  }, {
    path: "/welcome",
    component: _26b6d1fd,
    name: "welcome"
  }, {
    path: "/account/albums",
    component: _0cd3298a,
    name: "account-albums"
  }, {
    path: "/account/critiques",
    component: _e1cc7bc6,
    name: "account-critiques"
  }, {
    path: "/account/curated",
    component: _7d84d10b,
    name: "account-curated"
  }, {
    path: "/account/curation",
    component: _1cd8b7cc,
    name: "account-curation"
  }, {
    path: "/account/galleries",
    component: _4e07cee9,
    name: "account-galleries"
  }, {
    path: "/account/licensing",
    component: _11d95d92,
    name: "account-licensing"
  }, {
    path: "/account/photos",
    component: _0dc50d58,
    name: "account-photos"
  }, {
    path: "/account/portfolio",
    component: _3df06cc1,
    name: "account-portfolio"
  }, {
    path: "/account/profile",
    component: _40f99ce2,
    name: "account-profile"
  }, {
    path: "/account/reset",
    component: _545fd24b,
    name: "account-reset"
  }, {
    path: "/account/settings",
    component: _907e278c,
    name: "account-settings"
  }, {
    path: "/learning/professional-critiques",
    component: _6c35b2b2,
    name: "learning-professional-critiques"
  }, {
    path: "/membership/canceled",
    component: _6f3e70cd,
    name: "membership-canceled"
  }, {
    path: "/membership/success",
    component: _9b965102,
    name: "membership-success"
  }, {
    path: "/orders/canceled",
    component: _553400c4,
    name: "orders-canceled"
  }, {
    path: "/orders/success",
    component: _5267aa8e,
    name: "orders-success"
  }, {
    path: "/shop/prints",
    component: _2ad75b86,
    name: "shop-prints"
  }, {
    path: "/account/critiques/canceled",
    component: _6715a21e,
    name: "account-critiques-canceled"
  }, {
    path: "/account/critiques/success",
    component: _5a0c8fe4,
    name: "account-critiques-success"
  }, {
    path: "/shop/prints/canceled",
    component: _3a40c404,
    name: "shop-prints-canceled"
  }, {
    path: "/shop/prints/success",
    component: _3a10c02e,
    name: "shop-prints-success"
  }, {
    path: "/albums/:id",
    component: _795e9bc7,
    name: "albums-id"
  }, {
    path: "/blog/:uid",
    component: _7bc1edb9,
    name: "blog-uid"
  }, {
    path: "/books/:uid",
    component: _69d368ea,
    name: "books-uid"
  }, {
    path: "/galleries/:id",
    component: _5fb93676,
    name: "galleries-id"
  }, {
    path: "/photo/:id",
    component: _cd04658a,
    name: "photo-id"
  }, {
    path: "/photographer/:id",
    component: _89ff7cae,
    name: "photographer-id"
  }, {
    path: "/public-critiques/:id",
    component: _230ed4c0,
    name: "public-critiques-id"
  }, {
    path: "/albums/:id/:slug",
    component: _77a7fa7f,
    name: "albums-id-slug"
  }, {
    path: "/photo/:id/:slug",
    component: _d071a81a,
    name: "photo-id-slug"
  }, {
    path: "/public-critiques/:id?/:slug",
    component: _267c1750,
    name: "public-critiques-id-slug"
  }, {
    path: "/",
    component: _7b7ce7a6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
