import findIndex from 'lodash/findIndex'
import each from 'lodash/each'

export const strict = false

const commonPhotoQuery = '?size=10&page=1&nude=false&sort=desc'

const getDefaultState = () => {
  return {
    // Logged
    photosInCuration: [],
    photosToPromoteElite: [],
    photosToPromoteInspiration: [],
    inspirationPhotos: [],
    elitePhotos: [],
    primePhotos: [],
    albumPhotos: [],
    photographersToFollow: [],
    communityReview: {},

    // Guest
    photos: []
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_PHOTOS: (state, payload) => {
    state.photos = payload
  },

  SET_INSPIRATION_PHOTOS: (state, payload) => {
    state.inspirationPhotos = payload
  },

  SET_ELITE_PHOTOS: (state, payload) => {
    state.elitePhotos = payload
  },

  SET_PRIME_PHOTOS: (state, payload) => {
    state.primePhotos = payload
  },

  SET_ALBUM_PHOTOS: (state, payload) => {
    state.albumPhotos = payload
  },

  SET_PHOTOS_IN_CURATION: (state, payload) => {
    state.photosInCuration = payload
  },

  SET_PHOTOS_TO_PROMOTE_ELITE: (state, payload) => {
    state.photosToPromoteElite = payload
  },

  SET_PHOTOS_TO_PROMOTE_INSPIRATION: (state, payload) => {
    state.photosToPromoteInspiration = payload
  },

  SET_PHOTOGRAPHERS_TO_FOLLOW: (state, payload) => {
    state.photographersToFollow = payload
  },

  SET_IS_FOLLOWING: (state, payload) => {
    const index = findIndex(
      state.photographersToFollow,
      p => p.id === payload.id
    )
    if (index > -1) {
      state.photographersToFollow[index].following = payload.isFollowing
    }
  },

  SET_COMMUNITY_REVIEW: (state, payload) => {
    state.communityReview = payload
  }
}

export const actions = {
  async getPhotos({ commit }) {
    const { result } = await this.$axios.$get(
      `/photos?size=10&page=1&nude=false&sort=desc&status=elite`
    )
    commit('SET_PHOTOS', result)
  },

  async getPhotosInCuration({ commit, rootState }) {
    const nude =
      rootState.auth.user && !rootState.auth.user.isNudeFilterActive
        ? '&nude=true'
        : '&nude=false'
    const { result } = await this.$axios.$get(
      `/me/curate?size=10&page=1&curated=false&sort=desc${nude}`
    )
    commit('SET_PHOTOS_IN_CURATION', result)
  },

  async getPhotosToPromoteElite({ commit, rootState }) {
    const nude =
      rootState.auth.user && !rootState.auth.user.isNudeFilterActive
        ? '&nude=true'
        : '&nude=false'
    const { result } = await this.$axios.$get(
      `/photos?size=10&page=1&status=prime&sort=desc${nude}&voted=true`
    )
    commit('SET_PHOTOS_TO_PROMOTE_ELITE', result)
  },

  async getPhotosToPromoteInspiration({ commit, rootState }) {
    const nude =
      rootState.auth.user && !rootState.auth.user.isNudeFilterActive
        ? '&nude=true'
        : '&nude=false'
    const { result } = await this.$axios.$get(
      `/photos?size=10&page=1&status=prime&sort=desc${nude}&votedInspiration=true`
    )
    commit('SET_PHOTOS_TO_PROMOTE_INSPIRATION', result)
  },

  async getElitePhotos({ commit }) {
    const query = `${commonPhotoQuery}&status=elite`
    const response = await this.$axios.$get(`/photos${query}`)
    commit('SET_ELITE_PHOTOS', response.result)
  },

  async getInspirationPhotos({ commit }) {
    const query = `${commonPhotoQuery}&status=inspiration`
    const response = await this.$axios.$get(`/photos${query}`)
    commit('SET_INSPIRATION_PHOTOS', response.result)
  },

  async getPrimePhotos({ commit }) {
    const query = `${commonPhotoQuery}&status=prime`
    const response = await this.$axios.$get(`/photos${query}`)
    commit('SET_PRIME_PHOTOS', response.result)
  },

  async getAlbumPhotos({ commit }) {
    const query = '?page=1&size=5'
    const { result } = await this.$axios.$get(`/albums${query}`)
    const photos = []
    each(result, r => {
      const o = {}
      o.thumbnailUrl = r.thumbnail
      o.title = r.title
      o.photographerName = r.ownerFullName
      o.width = r.thumbnailWidth
      o.height = r.thumbnailHeight
      return photos.push(o)
    })
    commit('SET_ALBUM_PHOTOS', photos)
  },

  async getPhotographersToFollow({ commit }) {
    const { result } = await this.$axios.$get('/users/to-follow')
    commit('SET_PHOTOGRAPHERS_TO_FOLLOW', result)
  },

  async getCommunityReview({ commit }) {
    const { result } = await this.$axios.$get(
      '/comments/list?take=1&cursor=0'
    )
    commit('SET_COMMUNITY_REVIEW', result[0])
  }
}
