<template>
  <v-dialog v-model="curationMessageDialog" persistent width="400">
    <v-card outlined>
      <v-card-text class="pt-4">
        <div class="text-center mb-6">
          <v-icon x-large color="warning">
            fal fa-exclamation-triangle
          </v-icon>
        </div>
        <div class="text-center">
          <div>
            <p>
              Our system has detected that you are curating photos without paying attention. Your account will be monitored for the next 24 hours. We take curation very seriously.
            </p>
            <p class="mb-0">
              If you have any questions about this message please contact us at <a href="mailto:info@100asa.com">info@100asa.com</a>
            </p>
          </div>
        </div>
        <div class="text-center mt-6">
          <v-btn
            outlined
            depressed
            class="mx-3"
            @click="curationMessageDialog = false"
          >
            Ok
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CurationMessageDialog',
  data() {
    return {
      curationMessageDialog: false
    }
  },
  computed: {
    ...mapState({
      curationMessage: state => state.comments.curationMessage
    })
  },
  watch: {
    curationMessage: 'checkVisibility',
    curationMessageDialog: 'clearMessage'
  },
  methods: {
    checkVisibility(value) {
      if (value) {
        this.curationMessageDialog = true
      }
    },
    clearMessage(value) {
      if (!value) {
        setTimeout(() => {
          this.$store.commit('comments/SET_CURATION_MESSAGE', false)
        }, 500)
      }
    }
  }
}
</script>
