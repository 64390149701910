import findIndex from 'lodash/findIndex'

export const strict = false

const getDefaultState = () => {
  return {
    // account / user
    galleries: [],
    isLastPage: false,
    page: -1,
    addPhotoToGalleryDialogVisible: false,
    selectedPhotoToAdd: null,

    // Selected gallery
    userGalleries: [],
    userGalleriesIsLastPage: false,
    userGalleriesPage: -1,
    galleryPhotos: [],
    galleryPhotosIsLastPage: false,
    galleryPhotosPage: -1,
    galleryInfo: {},
    galleryOwner: {},
    infoMe: {}
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_GALLERIES: (state, galleries) => {
    state.galleries = [...state.galleries, ...galleries]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_LAST_PAGE: (state, page) => {
    state.page = page
  },

  SET_USER_GALLERIES: (state, galleries) => {
    state.userGalleries = [...state.userGalleries, ...galleries]
  },

  SET_USER_GALLERIES_IS_LAST_PAGE: (state, isLastPage) => {
    state.userGalleriesIsLastPage = isLastPage
  },

  SET_USER_GALLERIES_LAST_PAGE: (state, page) => {
    state.userGalleriesPage = page
  },

  SET_GALLERY_PHOTOS: (state, photos) => {
    state.galleryPhotos = [...state.galleryPhotos, ...photos]
  },

  SET_GALLERY_IS_LAST_PAGE: (state, isLastPage) => {
    state.galleryPhotosIsLastPage = isLastPage
  },

  SET_GALLERY_LAST_PAGE: (state, page) => {
    state.galleryPhotosPage = page
  },

  SET_GALLERY_INFO: (state, info) => {
    state.galleryInfo = info
  },

  SET_GALLERY_OWNER: (state, owner) => {
    state.galleryOwner = owner
  },

  SET_INFO_ME: (state, info) => {
    state.infoMe = info
  },

  ADD_GALLERY: (state, gallery) => {
    state.galleries.unshift(gallery)
  },

  SET_SELECTED_PHOTO_TO_ADD: (state, photo) => {
    state.selectedPhotoToAdd = photo
  },

  SET_ADD_PHOTO_TO_GALLERY_DIALOG_VISIBLE: (state, visible) => {
    state.addPhotoToGalleryDialogVisible = visible
  },

  ADD_PHOTO_TO_GALLERY: (state, payload) => {
    const index = findIndex(state.galleries, g => g.id === payload.galleryId)
    if (index > -1) {
      if (!state.galleries[index].thumbnail) {
        state.galleries[index].thumbnail = payload.thumbnail
      }
      state.galleries[index].totalNumberOfPhotos = payload.totalNumberOfPhotos
    }
  },

  REMOVE_PHOTO_FROM_GALLERY: (state, payload) => {
    const index = findIndex(state.galleryPhotos, p => p.id === payload.photoId)
    if (index > -1) {
      state.galleryPhotos.splice(index, 1)
      state.galleries = []
      state.isLastPage = false
      state.page = -1
      state.addPhotoToGalleryDialogVisible = false
      state.selectedPhotoToAdd = null
      state.photoAlreadyAdded = undefined
    }
  },

  UPDATE_GALLERY: (state, payload) => {
    const index = findIndex(state.galleries, g => g.id === payload.id)
    if (index > -1) {
      state.galleries[index] = payload
    }
  },

  REMOVE_GALLERY: (state, id) => {
    const index = findIndex(state.galleries, g => g.id === id)
    if (index > -1) {
      state.galleries.splice(index, 1)
    }
  },

  SET_GALLERY_LIKE: (state, data) => {
    state.galleryInfo.numberOfLikes = data.numberOfLikes
    state.infoMe.likedAlready = data.value === 1
  },

  SET_GALLERY_THUMBNAIL: (state, thumbnail) => {
    state.galleryInfo.thumbnail = thumbnail
  },

  SET_PHOTO_LIKE: (state, data) => {
    const index = findIndex(state.galleryPhotos, p => p.id === data.id)
    if (index > -1) {
      state.galleryPhotos[index].numberOfLikes = data.numberOfLikes
      state.galleryPhotos[index].hasLikedAlready = data.value === 1
    }
  },

  RESET_ACCOUNT_USER_STORE: state => {
    state.galleries = []
    state.isLastPage = false
    state.page = -1
    state.addPhotoToGalleryDialogVisible = false
    state.selectedPhotoToAdd = null
  },

  RESET_SELECTED_GALLERY_STORE: state => {
    state.userGalleries = []
    state.userGalleriesIsLastPage = false
    state.userGalleriesPage = -1
    state.galleryPhotos = []
    state.galleryPhotosIsLastPage = false
    state.galleryPhotosPage = -1
    state.galleryInfo = {}
    state.galleryOwner = {}
    state.infoMe = {}
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getGalleries({ commit }, payload) {
    const size = 10
    const page = payload.page
    const query = `?userid=${payload.userId}&page=${page}&size=${size}`
    const response = await this.$axios.$get(`/galleries${query}`)
    commit('SET_GALLERIES', response.result)
    commit('SET_IS_LAST_PAGE', response.isLastPage)
    commit('SET_LAST_PAGE', payload.page)
  },

  async getUserGalleries({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?userid=${payload.userId}&page=${page}&size=${size}`
    const response = await this.$axios.$get(`/galleries${query}`)
    commit('SET_USER_GALLERIES', response.result)
    commit('SET_USER_GALLERIES_IS_LAST_PAGE', response.isLastPage)
    commit('SET_USER_GALLERIES_LAST_PAGE', payload.page)
  },

  async getGallery({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(
      `/galleries/${payload.id}${query}`
    )
    commit('SET_GALLERY_PHOTOS', response.result)
    commit('SET_GALLERY_IS_LAST_PAGE', response.isLastPage)
    commit('SET_GALLERY_LAST_PAGE', payload.page)
    if (payload.page === 1) {
      commit('SET_GALLERY_OWNER', response.owner)
      commit('SET_GALLERY_INFO', response.info)
    }
  },

  async getInfo({ commit, rootState }, id) {
    if (rootState.auth.loggedIn) {
      const info = await this.$axios.$get(`/galleries/${id}/me`)
      commit('SET_INFO_ME', info)
    }
  },

  async createGallery({ commit }, payload) {
    const response = await this.$axios.$post('/galleries', payload)
    if (response === 'photo_added_already_to_gallery') {
      return 'photo_added_already_to_gallery'
    } else {
      commit('ADD_GALLERY', response)
    }
  },

  async updateGallery({ commit }, payload) {
    const response = await this.$axios.$patch(
      `/galleries/${payload.id}`,
      payload
    )
    commit('UPDATE_GALLERY', response)
  },

  async addPhotoToGallery({ commit }, payload) {
    const response = await this.$axios.$post('/galleries/add', payload)
    if (response === 'photo_added_already_to_gallery') {
      return 'photo_added_already_to_gallery'
    } else {
      commit('ADD_PHOTO_TO_GALLERY', { ...payload, ...response })
    }
  },

  async removePhoto({ commit }, payload) {
    await this.$axios.$post('/galleries/remove', payload)
    commit('REMOVE_PHOTO_FROM_GALLERY', payload)
  },

  async removeGallery({ commit }, id) {
    await this.$axios.$delete(`/galleries/${id}`)
    commit('REMOVE_GALLERY', id)
  },

  async like({ commit }, data) {
    const res = await this.$axios.$post(
      `/galleries/${data.id}/${data.value === 1 ? 'like' : 'dislike'}`
    )
    res.value = data.value
    if (data.from === 'feeds') {
      const payload = { ...data, ...res }
      commit('feeds/SET_USER_LIKED', payload, { root: true })
    } else {
      commit('SET_GALLERY_LIKE', res)
    }
  },

  async reportGallery({ commit }, id) {
    await this.$axios.$post(`/galleries/${id}/report`)
  },

  async setGalleryThumbnail({ commit }, payload) {
    const { thumbnail } = await this.$axios.$post(
      '/galleries/set-thumbnail',
      payload
    )
    commit('SET_GALLERY_THUMBNAIL', thumbnail)
  },

  setSelectedPhotoToAdd({ commit }, photo) {
    commit('SET_SELECTED_PHOTO_TO_ADD', photo)
  },

  setAddPhotoToGalleryDialogVisible({ commit }, visible) {
    commit('SET_ADD_PHOTO_TO_GALLERY_DIALOG_VISIBLE', visible)
  },

  resetAccountUserStore({ commit }) {
    commit('RESET_ACCOUNT_USER_STORE')
  },

  resetSelectedGallery({ commit }) {
    commit('RESET_SELECTED_GALLERY_STORE')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
