import findIndex from 'lodash/findIndex'
import types from '@/utils/types'

export const strict = false

const getDefaultState = () => {
  return {
    // Public critiques
    critiques: [],
    critiquesIsLastPage: false,
    critiquesPage: 1,
    critiquesLoadDate: undefined,

    // Filters
    searchTerm: '',
    photographerLevel: '',
    critiqueFocus: '',
    categoryId: '0',

    // Current user submitted critiques
    submitted: [],
    submittedIsLastPage: false,
    submittedPage: 1,

    // Current user reviewed critiques
    reviewed: [],
    reviewedIsLastPage: false,
    reviewedPage: 1,

    // Ready to be curated by curators
    awaiting: [],
    awaitingIsLastPage: false,
    awaitingPage: 1,

    // Current curator in progress critiques
    inProgress: [],
    inProgressIsLastPage: false,
    inProgressPage: 1,

    // Current curator completed critiques
    completed: [],
    completedIsLastPage: false,
    completedPage: 1,

    // Current Critique
    critiqueDialogVisible: false,
    critiqueRequest: {},
    critiqueResponse: {},
    critiqueResponseItems: [],
    pinMode: false,

    // Preview Critique
    critiquePreviewDialogVisible: false,
    critiquePreviewRequest: {},
    critiquePreviewResponse: {},
    critiquePreviewResponseItems: [],

    // Chat
    chatDialogVisible: false,
    chatInfo: {}
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_CRITIQUES: (state, critiques) => {
    state.critiques = [...state.critiques, ...critiques]
  },

  SET_CRITIQUES_IS_LAST_PAGE: (state, isLastPage) => {
    state.critiquesIsLastPage = isLastPage
  },

  SET_CRITIQUES_PAGE: (state, page) => {
    state.critiquesPage = page
  },

  SET_SUBMITTED: (state, critiques) => {
    state.submitted = [...state.submitted, ...critiques]
  },

  SET_SUBMITTED_IS_LAST_PAGE: (state, isLastPage) => {
    state.uploadedIsLastPage = isLastPage
  },

  SET_SUBMITTED_PAGE: (state, page) => {
    state.uploadedPage = page
  },

  SET_REVIEWED: (state, critiques) => {
    state.reviewed = [...state.reviewed, ...critiques]
  },

  SET_REVIEWED_IS_LAST_PAGE: (state, isLastPage) => {
    state.reviewedIsLastPage = isLastPage
  },

  SET_REVIEWED_PAGE: (state, page) => {
    state.reviewedPage = page
  },

  SET_AWAITING: (state, critiques) => {
    state.awaiting = [...state.awaiting, ...critiques]
  },

  SET_AWAITING_IS_LAST_PAGE: (state, isLastPage) => {
    state.awaitingIsLastPage = isLastPage
  },

  SET_AWAITING_PAGE: (state, page) => {
    state.awaitingPage = page
  },

  SET_IN_PROGRESS: (state, critiques) => {
    state.inProgress = [...state.inProgress, ...critiques]
  },

  SET_IN_PROGRESS_IS_LAST_PAGE: (state, isLastPage) => {
    state.inProgressIsLastPage = isLastPage
  },

  SET_IN_PROGRESS_PAGE: (state, page) => {
    state.inProgressPage = page
  },

  SET_COMPLETED: (state, critiques) => {
    state.completed = [...state.completed, ...critiques]
  },

  SET_COMPLETED_IS_LAST_PAGE: (state, isLastPage) => {
    state.completedIsLastPage = isLastPage
  },

  SET_COMPLETED_PAGE: (state, page) => {
    state.completedPage = page
  },

  SET_LOCK: (state, id) => {
    const index = findIndex(state.awaiting, c => c.id === id)
    if (index > -1) {
      state.awaiting[index].status = types.critique.status.inReview
      state.inProgress.unshift(state.awaiting[index])
      state.awaiting.splice(index, 1)
    }
  },

  SET_UNLOCK: (state, id) => {
    const index = findIndex(state.inProgress, c => c.id === id)
    if (index > -1) {
      state.inProgress[index].status = types.critique.status.submitted
      state.awaiting.unshift(state.inProgress[index])
      state.inProgress.splice(index, 1)
    }
  },

  SET_CRITIQUE_REQUEST: (state, payload) => {
    state.critiqueDialogVisible = payload.critiqueDialogVisible
    state.critiqueRequest = payload.critiqueRequest
  },

  SET_CRITIQUE_RESPONSE: (state, payload) => {
    state.critiqueResponse = payload
  },

  SET_CRITIQUE_RESPONSE_ITEMS: (state, payload) => {
    if (Array.isArray(payload)) {
      state.critiqueResponseItems = payload
    } else {
      state.critiqueResponseItems.push(payload)
    }
  },

  UPDATE_CRITIQUE_RESPONSE_ITEMS: (state, payload) => {
    const index = findIndex(
      state.critiqueResponseItems,
      i => i.id === payload.id
    )
    if (index > -1) {
      state.critiqueResponseItems[index].pinName = payload.pinName || ''
      state.critiqueResponseItems[index].content = payload.content || ''
    }
  },

  SET_PIN_MODE: (state, value) => {
    state.pinMode = value
  },

  DELETE_ITEM: (state, id) => {
    const index = findIndex(state.critiqueResponseItems, i => i.id === id)
    if (index > -1) {
      state.critiqueResponseItems.splice(index, 1)
    }
  },

  SET_CRITIQUE_PREVIEW_REQUEST: (state, payload) => {
    state.critiquePreviewDialogVisible = payload.critiquePreviewDialogVisible
    state.critiquePreviewRequest = payload.critiquePreviewRequest
  },

  SET_CRITIQUE_COMPLETED: (state, id) => {
    const index = findIndex(state.inProgress, c => c.id === id)
    if (index > -1) {
      state.inProgress[index].status = types.critique.status.reviewed
      state.inProgress[index].completedAt = Date.now()
      state.completed.unshift(state.inProgress[index])
      state.inProgress.splice(index, 1)
    }
  },

  SET_CRITIQUE_PREVIEW_RESPONSE: (state, payload) => {
    state.critiquePreviewResponse = payload
  },

  SET_CRITIQUE_PREVIEW_RESPONSE_ITEMS: (state, payload) => {
    state.critiquePreviewResponseItems = payload
  },

  SET_PUBLIC_CRITIQUES_LOAD_DATE: (state, date) => {
    state.critiquesLoadDate = date
  },

  SET_FILTERS: (state, filters) => {
    state.searchTerm = filters._searchTerm
    state.photographerLevel = filters._photographerLevel
    state.critiqueFocus = filters._critiqueFocus
    state.categoryId = filters._categoryId
  },

  SET_CHAT_DIALOG: (state, payload) => {
    state.chatDialogVisible = payload.visible
    state.chatInfo = payload.critique
  },

  SET_CHAT_READ: (state, id) => {
    const indexReviewed = findIndex(state.reviewed, c => c.id === id)
    if (indexReviewed > -1) {
      state.reviewed[indexReviewed].hasLastMessageBeenRead = true
    }
    const indexCompleted = findIndex(state.completed, c => c.id === id)
    if (indexCompleted > -1) {
      state.completed[indexCompleted].hasLastMessageBeenRead = true
    }
    if (state.chatInfo.id === id) {
      state.chatInfo.hasLastMessageBeenRead = true
    }
  },

  REMOVE_DELETED_CRITIQUE: (state, id) => {
    const index = findIndex(state.submitted, c => c.id === id)
    if (index > -1) {
      state.submitted.splice(index, 1)
    }
  },

  RESET_PUBLIC_CRITIQUES: state => {
    state.critiques = []
    state.critiquesIsLastPage = false
    state.critiquesPage = 1
    state.critiquesLoadDate = undefined
  },

  RESET_CURRENT_CRITIQUE_RESPONSE: state => {
    state.critiqueResponse = {}
    state.critiqueResponseItems = []
    state.pinMode = false
  },

  RESET_CURRENT_CRITIQUE_PREVIEW_RESPONSE: state => {
    state.critiquePreviewResponse = {}
    state.critiquePreviewResponseItems = []
    state.pinMode = false
  },

  RESET_ACCOUNT_CRITIQUES: state => {
    state.submitted = []
    state.submittedIsLastPage = false
    state.submittedPage = 1
    state.reviewed = []
    state.reviewedIsLastPage = false
    state.reviewedPage = 1
    state.awaiting = []
    state.awaitingIsLastPage = false
    state.awaitingPage = 1
    state.inProgress = []
    state.inProgressIsLastPage = false
    state.inProgressPage = 1
    state.completed = []
    state.completedIsLastPage = false
    state.completedPage = 1
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getPublicCritiques({ commit, state }, data) {
    const size = 30
    const page = data.page

    let query = `?page=${page}&size=${size}`

    if (state.searchTerm) {
      query += `&search=${state.searchTerm}`
    }
    if (state.photographerLevel) {
      query += `&photographerLevel=${state.photographerLevel}`
    }
    if (state.critiqueFocus) {
      query += `&critiqueFocus=${state.critiqueFocus}`
    }
    if (state.categoryId !== '0') {
      query += `&categoryId=${state.categoryId}`
    }

    const response = await this.$axios.$get(`/critiques${query}`)
    commit('SET_CRITIQUES', response.result)
    commit('SET_CRITIQUES_IS_LAST_PAGE', response.isLastPage)
    commit('SET_CRITIQUES_PAGE', data.page)
  },

  async getSubmitted({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&status=submitted`
    const response = await this.$axios.$get(`/critiques/me${query}`)
    commit('SET_SUBMITTED', response.result)
    commit('SET_SUBMITTED_IS_LAST_PAGE', response.isLastPage)
    commit('SET_SUBMITTED_PAGE', data.page)
  },

  async getReviewed({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&status=reviewed`
    const response = await this.$axios.$get(`/critiques/me${query}`)
    commit('SET_REVIEWED', response.result)
    commit('SET_REVIEWED_IS_LAST_PAGE', response.isLastPage)
    commit('SET_REVIEWED_PAGE', data.page)
  },

  async getAwaiting({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&status=submitted`
    const response = await this.$axios.$get(`/critiques/curators${query}`)
    commit('SET_AWAITING', response.result)
    commit('SET_AWAITING_IS_LAST_PAGE', response.isLastPage)
    commit('SET_AWAITING_PAGE', data.page)
  },

  async getInProgress({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&status=inreview`
    const response = await this.$axios.$get(`/critiques/curators${query}`)
    commit('SET_IN_PROGRESS', response.result)
    commit('SET_IN_PROGRESS_IS_LAST_PAGE', response.isLastPage)
    commit('SET_IN_PROGRESS_PAGE', data.page)
  },

  async getCompleted({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&status=reviewed&sort=desc`
    const response = await this.$axios.$get(`/critiques/curators${query}`)
    commit('SET_COMPLETED', response.result)
    commit('SET_COMPLETED_IS_LAST_PAGE', response.isLastPage)
    commit('SET_COMPLETED_PAGE', data.page)
  },

  async lock({ commit }, id) {
    await this.$axios.$post(`/critiques/curators/lock`, { id })
    commit('SET_LOCK', id)
  },

  async unlock({ commit }, id) {
    await this.$axios.$post(`/critiques/curators/unlock`, { id })
    commit('SET_UNLOCK', id)
  },

  async saveDraft({ state, rootState }, id) {
    if (state.critiqueResponseItems.length) {
      const payload = {
        critiqueRequestId: id,
        curatorId: rootState.auth.user.id,
        items: state.critiqueResponseItems
      }
      await this.$axios.$post(`/critiques/curators/save-draft`, payload)
    }
  },

  async complete({ state, commit, rootState }, id) {
    const payload = {
      critiqueRequestId: id,
      curatorId: rootState.auth.user.id,
      items: state.critiqueResponseItems
    }
    await this.$axios.$post(`/critiques/curators/save`, payload)
    commit('SET_CRITIQUE_COMPLETED', id)
  },

  async getResponse({ commit }, payload) {
    const response = await this.$axios.$get(
      `/critiques/curators/${payload.id}`
    )
    if (payload.preview) {
      commit('SET_CRITIQUE_PREVIEW_RESPONSE_ITEMS', response.items)
      delete response.items
      commit('SET_CRITIQUE_RESPONSE', response)
    } else {
      commit('SET_CRITIQUE_RESPONSE_ITEMS', response.items)
      delete response.items
      commit('SET_CRITIQUE_RESPONSE', response)
    }
  },

  async getPublicResponse({ commit }, payload) {
    const response = await this.$axios.$get(`/critiques/${payload.id}/items`)
    commit('SET_CRITIQUE_PREVIEW_RESPONSE_ITEMS', response.items)
    delete response.items
    commit('SET_CRITIQUE_RESPONSE', response)
  },

  async deleteCritique({ commit }, id) {
    await this.$axios.$delete(`/critiques/${id}`)
    commit('REMOVE_DELETED_CRITIQUE', id)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
