<template>
  <div>
    <v-dialog v-model="visible" persistent width="1000">
      <v-card outlined>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div class="rwd-video">
            <iframe
              :src="`https://www.youtube-nocookie.com/embed/${link}`"
              width="1000"
              height="562"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text small @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      visible: state => state.tutorial.visible,
      title: state => state.tutorial.title,
      link: state => state.tutorial.link
    })
  },
  methods: {
    close() {
      this.$store.dispatch('tutorial/setVisibility', {
        visible: false
      })
    }
  }
}
</script>

<style>
.rwd-video {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.rwd-video iframe,
.rwd-video object,
.rwd-video embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
