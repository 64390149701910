var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{staticClass:"px-0 pt-4 pb-0 main-footer",class:_vm.$vuetify.theme.dark ? 'dark' : '',attrs:{"height":"auto"}},[_c('v-container',{staticClass:"px-6 py-2",attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('nuxt-link',{staticClass:"text-h6",attrs:{"to":"/"}},[_c('img',{staticClass:"h-26 mb-4",attrs:{"src":"/images/100asa-logo-white.png","alt":"100ASA Logo.","width":"143","height":"26"}})])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"text-uppercase footer-links mb-4"},[_c('h4',{staticClass:"text-body-2 mb-2",class:{
              'primary--text': _vm.$vuetify.theme.dark,
              'secondary--text': _vm.$vuetify.theme.light
            }},[_vm._v("\n            Features\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/leaderboard"}},[_vm._v("\n              Leaderboard\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/public-critiques"}},[_vm._v("\n              Critiques\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/community-reviews"}},[_vm._v("\n              Community Reviews\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/albums"}},[_vm._v("\n              Albums\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/rewards"}},[_vm._v("\n              Rewards\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/learning"}},[_vm._v("\n              Learning\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/initiatives"}},[_vm._v("\n              Initiatives\n            ")])],1)])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"text-uppercase footer-links mb-4"},[_c('h4',{staticClass:"text-body-2 mb-2",class:{
              'primary--text': _vm.$vuetify.theme.dark,
              'secondary--text': _vm.$vuetify.theme.light
            }},[_vm._v("\n            Help\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/membership"}},[_vm._v("\n              Membership\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/how-does-it-work"}},[_vm._v("\n              How does it work?\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/reputation"}},[_vm._v("\n              Reputation\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/reward-points"}},[_vm._v("\n              Reward Points\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/faq"}},[_vm._v("\n              FAQ\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/contact-us"}},[_vm._v("\n              Contact us\n            ")])],1)])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"text-uppercase footer-links mb-4"},[_c('h4',{staticClass:"text-body-2 mb-2",class:{
              'primary--text': _vm.$vuetify.theme.dark,
              'secondary--text': _vm.$vuetify.theme.light
            }},[_vm._v("\n            Legal\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/terms-and-conditions"}},[_vm._v("\n              Terms & Conditions\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-0"},[_c('nuxt-link',{class:{
                'primary--text': _vm.$vuetify.theme.dark,
                'secondary--text': _vm.$vuetify.theme.light
              },attrs:{"to":"/privacy-policy"}},[_vm._v("\n              Privacy Policy\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"mt-10"},[_c('a',{attrs:{"href":"https://tree-nation.com/profile/impact/100asa#co2","target":"_blank"}},[_c('img',{staticStyle:{"width":"157px","height":"auto"},attrs:{"src":"https://tree-nation.com/images/tracking/label-co2-website-white-en.png"}})])])])])],1)],1),_vm._v(" "),_c('v-container',{staticClass:"lower-footer text-center",class:_vm.$vuetify.theme.dark ? 'dark' : '',attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 ma-0"},[_vm._v("\n          © "+_vm._s(_vm.year)+" 100ASA Ltd. - All rights reserved\n          "),_c('span',{staticClass:"mx-4"},[_vm._v("-")]),_vm._v(" "),_c('a',{attrs:{"href":"https://twitter.com/100ASAOfficial","target":"_blank"}},[_c('v-icon',{staticClass:"mr-4 primary--text",attrs:{"small":""}},[_vm._v("fab fa-twitter")])],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.facebook.com/100ASAOfficial","target":"_blank"}},[_c('v-icon',{staticClass:"mr-4 primary--text",attrs:{"small":""}},[_vm._v("fab fa-facebook")])],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.instagram.com/100asa_official","target":"_blank"}},[_c('v-icon',{staticClass:"mr-4 primary--text",attrs:{"small":""}},[_vm._v("fab fa-instagram")])],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.reddit.com/r/100ASA","target":"_blank"}},[_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("fab fa-reddit")])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }