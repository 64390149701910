import findIndex from 'lodash/findIndex'

export const strict = false

const getDefaultState = () => {
  return {
    replies: [],
    isLastPage: false,
    page: 1
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_REPLIES: (state, replies) => {
    state.replies = [...state.replies, ...replies]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_REPLY: (state, reply) => {
    state.replies.unshift(reply)
  },

  REMOVE_DISCUSSION: (state, id) => {
    const index = findIndex(state.replies, r => r.id === id)
    if (index > -1) state.replies.splice(index, 1)
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getReplies({ commit }, data) {
    const take = 30
    const page = data.page
    const query = `?take=${take}&page=${page}&commentid=${data.id}`
    const replies = await this.$axios.$get(`/discussions${query}`)
    commit('SET_REPLIES', replies.result)
    commit('SET_IS_LAST_PAGE', replies.isLastPage)
    commit('SET_PAGE', data.page)
  },

  async postReply({ commit, state }, data) {
    const reply = await this.$axios.$post(`/discussions`, data)
    commit('SET_REPLY', reply)
    if (!state.size) {
      commit('comments/SET_HAS_DISCUSSION', data.commentId, { root: true })
    }
  },

  async removeReply({ commit }, id) {
    await this.$axios.$delete(`/discussions/${id}`)
    commit('REMOVE_DISCUSSION', id)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
