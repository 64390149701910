import findIndex from 'lodash/findIndex'

export const strict = false

const getDefaultState = () => {
  return {
    isFollowing: false,

    type: '',
    data: [],
    isLastPage: false,
    page: -1
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_IS_FOLLOWING: (state, isFollowing) => {
    state.isFollowing = isFollowing
  },

  SET_IS_FOLLOWING_ON_DIALOG: (state, data) => {
    const index = findIndex(state.data, u => u.id === data.id)
    if (index > -1) {
      state.data[index].following = data.isFollowing
    }
  },

  SET_TYPE: (state, type) => {
    state.type = type
  },

  SET_DATA: (state, data) => {
    state.data = [...state.data, ...data]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_LAST_PAGE: (state, page) => {
    state.page = page
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  },

  RESET_DATA: state => {
    state.type = ''
    state.data.length = 0
    state.isLastPage = false
    state.page = -1
  }
}

export const actions = {
  async follow({ commit }, data) {
    await this.$axios.$post('/following/follow', {
      userId: data.userId
    })
    commit('users/SET_FOLLOW', data.userId, { root: true })
  },

  async unfollow({ commit }, data) {
    await this.$axios.$post('/following/unfollow', {
      userId: data.userId
    })
    commit('users/SET_UNFOLLOW', data.userId, { root: true })
  },

  async isUserFollowing({ commit }, data) {
    const isFollowing = await this.$axios.$get(
      `/following/is-following/${data.id}`
    )
    if (data.from === 'photographer') {
      commit('SET_IS_FOLLOWING', isFollowing)
    }
    if (data.from === 'dialogs') {
      commit('SET_IS_FOLLOWING_ON_DIALOG', {
        isFollowing,
        id: data.id
      })
    }
    if (data.from === 'home') {
      commit(
        'home/SET_IS_FOLLOWING',
        {
          isFollowing,
          id: data.id
        },
        { root: true }
      )
    }
  },

  setType({ commit }, type) {
    commit('SET_TYPE', type)
  },

  async getData({ commit, state }, data) {
    if (state.type) {
      const endpoint =
        state.type === 'following'
          ? `/following/following-by/${data.id}`
          : `/following/followed-by/${data.id}`
      const size = 10
      if (!state.isLastPage) {
        const query = `?page=${data.page}&size=${size}`
        const list = await this.$axios.$get(`${endpoint}${query}`)
        commit('SET_DATA', list.result)
        commit('SET_IS_LAST_PAGE', list.isLastPage)
        commit('SET_LAST_PAGE', data.page)
      }
    }
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  },

  resetData({ commit }) {
    commit('RESET_DATA')
  }
}
