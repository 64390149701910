<template>
  <v-dialog v-model="visible" hide-overlay persistent width="360">
    <v-card outlined>
      <v-card-text>
        <span class="d-block mt-4 mb-3 text-body-1 font-weight-bold">
          Please stand by
        </span>
        <v-progress-linear
          indeterminate
          rounded
          color="primary"
          class="mb-3"
        ></v-progress-linear>
        <span class="text-caption">
          Waiting time depends on your internet provider
        </span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoaderDialog',
  computed: {
    ...mapState({
      visible: state => state.loaderDialogVisible
    })
  }
}
</script>
