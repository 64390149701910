<template>
  <v-alert
    v-if="visible"
    fixed
    prominent
    dense
    tile
    dark
    class="ma-0 f-bottom w-100-percentage"
  >
    <v-row align="center" class="my-0">
      <v-col class="grow">
        <span class="text-body-2">
          By continuing to use 100asa.com you will be agreeing to the website
          <nuxt-link to="/terms-and-conditions" class="secondary--text"
            >Terms and Conditions</nuxt-link
          >
          and the
          <nuxt-link to="/use-of-cookies" class="secondary--text"
            >Use of Cookies</nuxt-link
          >
          while using the website and our services. Please also read our
          <nuxt-link to="/privacy-policy" class="secondary--text"
            >Privacy Policy</nuxt-link
          >
        </span>
      </v-col>
      <v-col class="shrink">
        <v-btn color="secondary" outlined depressed @click="onAccept">
          Accept
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      visible: false,
      prefix: process.env.NODE_ENV === 'production' ? '100asa.' : '100asadev.'
    }
  },
  mounted() {
    const cookie = this.$cookies.get(`${this.prefix}cookies-agreed`)
    if (cookie !== 'yes') {
      this.visible = true
    }
  },
  methods: {
    onAccept() {
      this.$cookies.set(`${this.prefix}cookies-agreed`, 'yes', {
        path: '/',
        maxAge: 60 * 60 * 24 * 365, // ome year
        secure: true,
        sameSite: 'none'
      })
      this.visible = false
    }
  }
}
</script>

<style scoped>
.f-bottom {
  bottom: 0;
  position: fixed;
  z-index: 9;
}
</style>
