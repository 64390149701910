import { EventBus } from '@/plugins/event-bus'

export const displayError = (text, color = 'error') => {
  EventBus.$emit('snackbar', {
    text,
    color
  })
}

export const displayConfirm = (html, callback) => {
  EventBus.$emit('confirm-dialog', {
    html,
    callback
  })
}
