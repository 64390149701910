export const strict = false

const getDefaultState = () => {
  return {
    token: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async googleAnalyticsAuth({ commit }) {
    const { token } = await this.$axios.$get(
      `${process.env.baseUrl}/proxy/google-analytics-auth`
    )
    if (token) {
      commit('SET_TOKEN', token)
    }
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
