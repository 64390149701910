export const strict = false

const getDefaultState = () => {
  return {
    featureInfoDialog: false,
    featureInfoDialogTitle: '',
    featureInfoDialogImage: '',
    featureInfoDialogContent: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_FEATURE_INFO_DIALOG: (state, payload) => {
    if (payload.show) {
      state.featureInfoDialogTitle = payload.title || ''
      state.featureInfoDialogImage = payload.image || ''
      state.featureInfoDialogContent = payload.content || ''
    }
    state.featureInfoDialog = payload.show
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}
