import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":true,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":false,"callback":"/callback"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"100asa.","options":{"path":"/","maxAge":31536000,"secure":true,"domain":"100asa.com","sameSite":"none"}},"localStorage":false,"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/auth/local","method":"POST","propertyName":"token"},"logout":{"url":"/auth/logout","method":"POST"},"user":{"url":"/me","method":"GET","propertyName":false}},"tokenRequired":true,"tokenType":"Bearer","_name":"local"}))

  // google
  $auth.registerStrategy('google', new scheme_23514a38($auth, {"client_id":"234529195908-tmeabbu3p94la9sta9slrvm49d1hb5ad.apps.googleusercontent.com","_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","userinfo_endpoint":"https://www.googleapis.com/oauth2/v3/userinfo","scope":["openid","profile","email"]}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"client_id":"255430338722005","authorization_endpoint":"https://facebook.com/v2.18/dialog/oauth","userinfo_endpoint":"https://graph.facebook.com/v2.18/me?fields=about,name,picture{url},email","scope":["public_profile","email"],"_name":"facebook"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
