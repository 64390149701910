<template>
  <section>
    <v-img
      src="https://storage.100asa.com/staging/212641/tr:n-full_l_v5/2623f349-bb02-4a99-ab14-5e866a17cd53_PDJZ-O5y7"
      lazy-src="https://storage.100asa.com/staging/212641/tr:n-thumb_v5/2623f349-bb02-4a99-ab14-5e866a17cd53_PDJZ-O5y7"
      alt="hero"
      class="hero-image mt-n16"
      cover
    >
      <div class="fill-height">
        <v-container class="fill-height">
          <v-row>
            <v-col cols="12" sm="8" lg="6" offset-lg="1">
              <h1
                class="white--text text-h2 font-weight-black text-shadow mb-4"
              >
                Create, Explore, Inspire
              </h1>
              <h2
                class="white--text text-h5 font-weight-bold text-shadow elevation--text-1"
              >
                Giving back the power to the photographers
              </h2>
              <v-btn
                :class="$vuetify.theme.dark ? 'grey--text text--darken-4' : ''"
                x-large
                nuxt
                to="/signup"
                class="white h-60 ma-0 mt-12"
                >Become a free member</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <div class="photographer-credit">
          <span class="white--text text-caption">
            Photo by
            <nuxt-link
              to="/photographer/farzadabdaliii"
              class="white--text no-underline font-weight-bold"
            >
              Farzad Abdali
            </nuxt-link>
          </span>
        </div>
      </div>
    </v-img>
  </section>
</template>

<script>
export default {
  name: 'HomeDefaultHero'
}
</script>

<style scoped>
.hero-image {
  height: 100vh;
}
.text-shadow {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.65);
}
.photographer-credit {
  position: absolute;
  bottom: 12px;
  right: 24px;
}
</style>
