import Vue from 'vue'
import VueGoodshareVkontakte from 'vue-goodshare/src/providers/Vkontakte.vue'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodsharePinterest from 'vue-goodshare/src/providers/Pinterest.vue'
import VueGoodshareReddit from 'vue-goodshare/src/providers/Reddit.vue'
import VueGoodshareTumblr from 'vue-goodshare/src/providers/Tumblr.vue'
import VueGoodshareTelegram from 'vue-goodshare/src/providers/Telegram.vue'
import VueGoodshareWhatsApp from 'vue-goodshare/src/providers/WhatsApp.vue'
import VueGoodshareEmail from 'vue-goodshare/src/providers/Email.vue'

Vue.component('VueGoodshareVkontakte', VueGoodshareVkontakte)
Vue.component('VueGoodshareFacebook', VueGoodshareFacebook)
Vue.component('VueGoodshareTwitter', VueGoodshareTwitter)
Vue.component('VueGoodshareLinkedin', VueGoodshareLinkedIn)
Vue.component('VueGoodsharePinterest', VueGoodsharePinterest)
Vue.component('VueGoodshareReddit', VueGoodshareReddit)
Vue.component('VueGoodshareTumblr', VueGoodshareTumblr)
Vue.component('VueGoodshareTelegram', VueGoodshareTelegram)
Vue.component('VueGoodshareWhatsapp', VueGoodshareWhatsApp)
Vue.component('VueGoodshareEmail', VueGoodshareEmail)
