<template>
  <div>
    <Hero />
    <div v-if="mounted">
      <Why />
      <Mobile-app />
      <Gallery />
      <Features />
      <Testimonials />
      <Join />
    </div>
  </div>
</template>

<script>
import Hero from '@/components/home/Hero.vue'

export default {
  components: {
    Hero,
    Why: () => import('@/components/home/Why.vue'),
    MobileApp: () => import('@/components/home/MobileApp.vue'),
    Gallery: () => import('@/components/home/Gallery.vue'),
    Features: () => import('@/components/home/Features.vue'),
    Testimonials: () => import( '@/components/home/Testimonials.vue'),
    Join: () => import('@/components/home/Join.vue')
  },
  middleware: ['header', 'dashboard'],
  data() {
    return {
      mounted: false
    }
  },
  async mounted() {
    this.mounted = true
    const referral = this.$route.query.refer
    if (referral) {
      await this.$store.dispatch('adverts/setAdvertSource', referral)
    }
  }
}
</script>

