export const strict = false

const getDefaultState = () => {
  return {
    advertSource: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_ADVERT_SOURCE: (state, source) => {
    state.advertSource = source
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  setAdvertSource({ commit }, source) {
    commit('SET_ADVERT_SOURCE', source)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
