var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',{class:{
    'grey lighten-4': !_vm.n.hasBeenClicked && !_vm.$vuetify.theme.dark,
    black: !_vm.n.hasBeenClicked && _vm.$vuetify.theme.dark
  }},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":_vm.getIcon(_vm.n.type).color}},[_vm._v("\n      "+_vm._s(_vm.getIcon(_vm.n.type).icon)+"\n    ")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n        New Curator note\n      ")])]),_vm._v(" "),_c('v-list-item-subtitle',[_c('small',[_vm._v("\n        "+_vm._s(_vm._f("printdate")(_vm.n.creationDate))+"\n      ")])]),_vm._v(" "),_c('v-list-item-subtitle',[_c('div',{staticClass:"text-body-2"},[(_vm.n.type === 'CuratorNote')?_c('div',[_c('span',[_c('span',{staticClass:"font-weight-bold link",on:{"click":function($event){return _vm.markNotificationAndGo(
                  _vm.n.hasBeenClicked,
                  _vm.n.id,
                  `/photographer/${_vm.n.userName || _vm.n.userId}`
                )}}},[_vm._v(_vm._s(_vm.n.userFullName))]),_vm._v("\n            has added a note to a\n            "),_c('span',{staticClass:"font-weight-bold link",on:{"click":function($event){return _vm.markNotificationAndGo(
                  _vm.n.hasBeenClicked,
                  _vm.n.id,
                  `/photo/${_vm.n.photoId}`
                )}}},[_vm._v("\n              photo\n            ")])])]):_c('div',[_c('span',[_vm._v(_vm._s(_vm.n.description))])])])])],1),_vm._v(" "),(
      _vm.n.photoThumbnail ||
        _vm.n.userAvatar ||
        ['NewUser', 'InternalCommunication'].includes(_vm.n.type)
    )?_c('v-list-item-avatar',[_c('span',{staticClass:"avatar-link link",on:{"click":function($event){_vm.markNotificationAndGo(
          _vm.n.hasBeenClicked,
          _vm.n.id,
          _vm.getMainNotificationLink(_vm.n)
        )}}},[_c('v-img',{attrs:{"src":_vm.n.photoThumbnail || _vm.n.userAvatar || '/images/100asa-round-icon.png',"alt":_vm.n.title,"width":"40","height":"40"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }