<template>
  <div v-if="header" class="main-header">
    <v-navigation-drawer
      v-model="drawer"
      height="auto"
      app
      fixed
      temporary
      width="300"
    >
      <v-list dense>
        <v-list-item
          v-if="!me || (me && !me.hasAnActiveSubscription)"
          to="/membership"
          nuxt
          exact
          class="gold"
        >
          <v-list-item-action>
            <v-icon small class="nav-icon">fal fa-crown</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="text-caption font-weight-bold text-uppercase"
            >
              Get Pro
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(n, i) in nav">
          <v-list-item
            v-if="n.to && (!n.auth || (n.auth && me))"
            :key="i"
            :to="n.to"
            :active-class="
              $vuetify.theme.dark
                ? 'secondary primary--text'
                : 'primary secondary--text'
            "
            nuxt
          >
            <v-list-item-action>
              <v-icon small class="nav-icon">{{ n.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-caption font-weight-bold text-uppercase"
              >
                {{ n.label }}
                <v-chip
                  v-if="n.new"
                  class="ma-0 ml-3 pa-2"
                  color="success"
                  x-small
                >
                  new
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :color="isHome ? 'transparent' : $vuetify.theme.dark ? '' : 'white'"
      :flat="flat"
      :dark="isHome || $vuetify.theme.dark"
      fixed
      app
    >
      <v-btn class="hidden-md-and-up" icon @click.stop="drawer = !drawer">
        <v-icon small>fal fa-bars</v-icon>
      </v-btn>
      <v-toolbar-title class="text-uppercase mr-6 ml-0 pl-2 hidden-xs-only">
        <nuxt-link to="/" class="text-h6">
          <img
            :src="`/images/100asa-logo${
              $vuetify.theme.dark || isHome ? '-white' : ''
            }.png`"
            class="d-flex logo-height"
            alt="100ASA Logo."
          />
        </nuxt-link>
      </v-toolbar-title>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down flat-nav">
        <template v-for="(n, i) in mainNav">
          <v-btn
            v-if="n.to && (!n.auth || (n.auth && me))"
            :key="i"
            :to="n.to"
            :class="`caption font-weight-bold ${n.class}`"
            text
            nuxt
          >
            <v-icon small class="mr-2">
              {{ n.icon }}
            </v-icon>
            {{ n.label }}
          </v-btn>
          <v-menu
            v-else-if="n.subMenu"
            :key="n.label"
            :nudge-width="100"
            open-on-hover
          >
            <template #activator="{ on }">
              <v-btn
                :class="n.class"
                text
                class="text-caption font-weight-bold ml-0"
                v-on="on"
              >
                <v-icon small class="mr-2">
                  {{ n.icon }}
                </v-icon>
                {{ n.label }}
                <v-icon small class="ml-2">fal fa-angle-down</v-icon>
              </v-btn>
            </template>
            <v-list class="text-caption font-weight-bold">
              <template v-for="(sn, y) in n.subMenu">
                <v-subheader
                  v-if="sn.subheader"
                  :key="sn.subheader"
                  :class="
                    $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'
                  "
                  class="menu-subheader font-weight-bold pa-0"
                >
                  <span class="px-4">
                    {{ sn.subheader }}
                  </span>
                </v-subheader>
                <v-list-item
                  v-else-if="sn.to && (!sn.auth || (sn.auth && me))"
                  :key="y"
                  :to="sn.to"
                  :class="sn.class"
                  :active-class="
                    $vuetify.theme.dark
                      ? 'secondary primary--text'
                      : 'primary secondary--text'
                  "
                  nuxt
                >
                  <v-list-item-title class="text-uppercase">
                    <small>
                      <v-icon small class="mr-2 w-20">
                        {{ sn.icon }}
                      </v-icon>
                      {{ sn.label }}
                      <v-chip
                        v-if="sn.new"
                        class="ma-0 ml-3 pa-2"
                        color="success"
                        x-small
                      >
                        new
                      </v-chip>
                    </small>
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-else-if="sn.divider" :key="sn.label" class="my-2" />
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar-items>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <template v-if="mounted">
        <template v-if="!me">
          <v-toolbar-items class="hidden-xs-only">
            <v-btn class="text-caption font-weight-bold" text nuxt to="/login">
              Login
            </v-btn>
            <v-btn class="text-caption font-weight-bold" text nuxt to="/signup">
              Sign Up
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items class="hidden-sm-and-up">
            <v-btn icon nuxt to="/login">
              <v-icon small>fal fa-sign-in</v-icon>
            </v-btn>
          </v-toolbar-items>
        </template>
        <template v-else>
          <v-btn
            :fab="$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndDown"
            class="mr-4"
            outlined
            rounded
            @click="uploadPhoto"
          >
            <v-icon x-small>fal fa-arrow-up</v-icon>
            <span class="text-caption font-weight-bold ml-2 hidden-sm-and-down">
              Upload
            </span>
          </v-btn>
          <v-btn
            v-if="me && !me.hasAnActiveSubscription"
            fab
            class="mr-4"
            depressed
            small
            color="gold"
            nuxt
            to="/membership"
          >
            <v-icon x-small>fal fa-crown</v-icon>
          </v-btn>
          <Notes
            v-if="me && (me.isAdmin || me.role === types.user.role.curator)"
          />
          <Notifications-menu />
          <User-menu :me="me" />
        </template>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Notes from '@/components/header/Notes.vue'
import NotificationsMenu from '@/components/header/NotificationsMenu.vue'
import UserMenu from '@/components/header/UserMenu.vue'
import types from '@/utils/types'
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'Header',
  components: {
    Notes,
    NotificationsMenu,
    UserMenu
  },
  props: {
    me: {
      type: [Object, Boolean],
      default: () => {}
    }
  },
  data() {
    return {
      types,
      mounted: false,
      drawer: false,
      flat: true,
      top: true,
      scrollTimeoutId: undefined
    }
  },
  computed: {
    ...mapState({
      header: state => state.header
    }),
    isHome() {
      let isHome = false
      if (this.$route.path === '/' && this.flat && (!this.me || !this.me.id)) {
        isHome = true
      }
      return isHome
    },
    nav() {
      return [
        {
          icon: 'fal fa-gavel',
          label: 'Curation',
          to: '/account/curation',
          auth: true
        },
        {
          icon: 'fal fa-star',
          label: 'Elite Gallery',
          to: '/elite'
        },
        {
          icon: 'fal fa-lightbulb-on',
          label: 'Inspiration Gallery',
          to: '/inspiration'
        },
        {
          icon: 'fas fa-images',
          label: 'Prime Gallery',
          to: '/prime'
        },
        {
          icon: 'far fa-images',
          label: 'Upcoming Gallery',
          to: '/upcoming'
        },
        {
          icon: 'fal fa-image-polaroid',
          label: 'Opening Gallery',
          to: '/opening'
        },
        {
          icon: 'fal fa-award',
          label: 'Community Awarded Gallery',
          to: '/community-awarded'
        },
        {
          icon: 'fal fa-chalkboard-teacher',
          label: 'Public Critiques',
          to: '/public-critiques'
        },
        {
          icon: 'fal fa-comments',
          label: 'Community Reviews',
          to: '/community-reviews'
        },
        {
          icon: 'fal fa-books',
          label: 'Albums',
          to: '/albums'
        },
        {
          icon: 'fal fa-gift',
          label: 'Rewards',
          to: '/rewards'
        },
        {
          icon: 'fal fa-store',
          label: 'Shop',
          to: '/shop'
        },
        // {
        //   icon: 'fal fa-flag-checkered',
        //   label: 'Competitions',
        //   to: '/competitions'
        // },
        {
          icon: 'fal fa-users',
          label: 'Photographers',
          to: '/photographers'
        },
        {
          icon: 'fal fa-users-class',
          label: 'Curators',
          to: '/curators'
        },
        {
          icon: 'fal fa-users-crown',
          label: 'Ambassadors',
          to: '/ambassadors'
        },
        {
          icon: 'fal fa-medal',
          label: 'Leaderboard',
          to: '/leaderboard'
        },
        {
          icon: 'fal fa-blog',
          label: 'Blog',
          to: '/blog'
        },
        {
          icon: 'fal fa-house-user',
          label: 'About us',
          to: '/about-us'
        },
        {
          icon: 'fal fa-envelope',
          label: 'Contact us',
          to: '/contact-us'
        }
      ]
    },
    mainNav() {
      return [
        {
          icon: 'fal fa-compass',
          label: 'Explore',
          to: false,
          class: `btn-explore${this.me ? '-logged' : ''}`,
          subMenu: [
            {
              subheader: "Editor's pick"
            },
            {
              icon: 'fal fa-star',
              label: 'Elite Gallery',
              to: '/elite'
            },
            {
              icon: 'fal fa-lightbulb-on',
              label: 'Inspiration Gallery',
              to: '/inspiration'
            },
            {
              subheader: 'Community curated'
            },
            {
              icon: 'fas fa-images',
              label: 'Prime Gallery',
              to: '/prime'
            },
            {
              icon: 'far fa-images',
              label: 'Upcoming Gallery',
              to: '/upcoming'
            },
            {
              icon: 'fal fa-image-polaroid',
              label: 'Opening Gallery',
              to: '/opening'
            },
            {
              icon: 'fal fa-award',
              label: 'Community Awarded Gallery',
              to: '/community-awarded'
            },
            {
              divider: true
            },
            {
              icon: 'fal fa-chalkboard-teacher',
              label: 'Public Critiques',
              to: '/public-critiques'
            },
            {
              icon: 'fal fa-comments',
              label: 'Community Reviews',
              to: '/community-reviews'
            },
            {
              icon: 'fal fa-books',
              label: 'Albums',
              to: '/albums'
            },
            {
              icon: 'fal fa-users',
              label: 'Photographers',
              to: '/photographers'
            },
            {
              icon: 'fal fa-medal',
              label: 'Leaderboard',
              to: '/leaderboard'
            },
            {
              icon: 'fal fa-users-class',
              label: 'Curators',
              to: '/curators'
            },
            {
              icon: 'fal fa-users-crown',
              label: 'Ambassadors',
              to: '/ambassadors'
            }
          ]
        },
        {
          icon: 'fal fa-gavel',
          label: 'Curation',
          to: '/account/curation',
          class: `btn-curation${this.me ? '-logged' : ''}`,
          auth: true
        },
        {
          icon: 'fal fa-graduation-cap',
          label: 'Learning',
          to: '/learning',
          class: `btn-learning${this.me ? '-logged' : ''}`
        },
        {
          icon: 'fal fa-gift',
          label: 'Rewards',
          to: '/rewards',
          class: `btn-rewards${this.me ? '-logged' : ''}`
        },
        // {
        //   icon: 'fal fa-flag-checkered',
        //   label: 'Competitions',
        //   to: '/competitions',
        //   class: `btn-competitions${this.me ? '-logged' : ''}`
        // },
        {
          icon: 'fal fa-blog',
          label: 'Blog',
          to: '/blog',
          class: `btn-blog${this.me ? '-logged' : ''}`
        },
        {
          icon: 'fal fa-bars',
          label: 'More',
          to: false,
          class: `btn-more${this.me ? '-logged' : ''}`,
          subMenu: [
            {
              icon: 'fal fa-crown',
              label: 'Membership',
              to: '/membership'
            },
            {
              icon: 'fal fa-gavel',
              label: 'Curation',
              to: '/account/curation',
              class: `btn-curation-more${this.me ? '-logged' : ''}`,
              auth: true
            },
            {
              icon: 'fal fa-graduation-cap',
              label: 'Learning',
              to: '/learning',
              class: `btn-learning-more${this.me ? '-logged' : ''}`
            },
            {
              icon: 'fal fa-gift',
              label: 'Rewards',
              to: '/rewards',
              class: `btn-rewards-more${this.me ? '-logged' : ''}`
            },
            {
              icon: 'fal fa-blog',
              label: 'Blog',
              to: '/blog',
              class: `btn-blog-more${this.me ? '-logged' : ''}`
            },
            // {
            //   icon: 'fal fa-flag-checkered',
            //   label: 'Competitions',
            //   to: '/competitions',
            //   class: `btn-competitions-more${this.me ? '-logged' : ''}`
            // },
            {
              icon: 'fal fa-trophy',
              label: 'Reputation',
              to: '/reputation'
            },
            {
              icon: 'fal fa-cubes',
              label: 'Reward Points',
              to: '/reward-points'
            },
            {
              icon: 'fal fa-leaf',
              label: 'Initiatives',
              to: '/initiatives'
            },
            {
              icon: 'fal fa-store',
              label: 'Shop',
              to: '/shop',
            },
            {
              icon: 'fal fa-bullseye-arrow',
              label: 'How does it work?',
              to: '/how-does-it-work'
            },
            {
              icon: 'fal fa-question-circle',
              label: 'FAQ',
              to: '/faq'
            },
            {
              icon: 'fal fa-house-user',
              label: 'About us',
              to: '/about-us'
            },
            {
              icon: 'fal fa-handshake',
              label: 'Partners',
              to: '/partners'
            },
            {
              icon: 'fal fa-envelope',
              label: 'Contact us',
              to: '/contact-us'
            }
          ]
        }
      ]
    }
  },
  destroyed() {
    if (this.scrollTimeoutId) {
      clearTimeout(this.scrollTimeoutId)
    }
  },
  mounted() {
    this.scrollTimeoutId = setTimeout(() => {
      this.mounted = true
      this.handleScroll()
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    uploadPhoto() {
      this.$router.push('/account/photos?upload=true')
      EventBus.$emit('select-photos')
    },
    handleScroll() {
      if (window.scrollY >= 50) {
        this.flat = false
        this.top = false
      } else {
        this.flat = true
        this.top = true
      }
    }
  }
}
</script>

<style scoped>
.w-20 {
  width: 20px;
}
.main-header {
  z-index: 7;
}
.logo-height {
  height: 26px;
}
.nav-icon {
  text-align: center;
  width: 25px;
}
.toolbar-title {
  position: absolute;
  left: 50%;
  margin-left: 0 !important;
  transform: translateX(-50%);
}
.flat-nav a,
.flat-nav button {
  font-size: 13px;
}

.btn-blog-more,
.btn-feeds-more,
.btn-competitions-more,
.btn-learning-more,
.btn-curation-more,
.btn-rewards-more,
.btn-blog-more-logged,
.btn-feeds-more-logged,
.btn-competitions-more-logged,
.btn-learning-more-logged,
.btn-rewards-more-logged,
.btn-curation-more-logged {
  display: none;
}

@media only screen and (max-width: 600px) {
  .logo-height {
    height: 22px;
  }
}

/* Handle responsive menu logged */
@media only screen and (max-width: 1420px) {
  .btn-blog-more-logged {
    display: flex;
  }
  .btn-blog-logged {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .btn-feeds-more-logged {
    display: flex;
  }
  .btn-feeds-logged {
    display: none;
  }
}
@media only screen and (max-width: 1110px) {
  .btn-competitions-more-logged {
    display: flex;
  }
  .btn-competitions-logged {
    display: none;
  }
}
@media only screen and (max-width: 1350px) {
  .btn-learning-more-logged {
    display: flex;
  }
  .btn-learning-logged {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .btn-rewards-more-logged {
    display: flex;
  }
  .btn-rewards-logged {
    display: none;
  }
}
@media only screen and (max-width: 1090px) {
  .btn-curation-more-logged {
    display: flex;
  }
  .btn-curation-logged {
    display: none;
  }
}

/* Handle responsive menu guest */
@media only screen and (max-width: 500px) {
  .btn-blog-more {
    display: flex;
  }
  .btn-blog {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .btn-feeds-more {
    display: flex;
  }
  .btn-feeds {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .btn-competitions-more {
    display: flex;
  }
  .btn-competitions {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .btn-learning-more {
    display: flex;
  }
  .btn-learning {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .btn-rewards-more {
    display: flex;
  }
  .btn-rewards {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .btn-curation-more {
    display: flex;
  }
  .btn-curation {
    display: none;
  }
}
</style>
