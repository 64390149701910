import findIndex from 'lodash/findIndex'
import last from 'lodash/last'

export const strict = false

const getDefaultState = () => {
  return {
    // photo comments
    list: [],
    isLastPage: false,
    page: 1,

    // Curation message
    curationMessage: false,

    // community reviews
    communityReviews: [],
    communityReviewsIsLastPage: false
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_COMMENTS: (state, comments) => {
    state.list = [...state.list, ...comments]
  },

  SET_COMMENT: (state, comment) => {
    state.list.unshift(comment)
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_CURATION_MESSAGE: (state, value) => {
    state.curationMessage = value
  },

  SET_PATCH_COMMENT: (state, comment) => {
    const index = findIndex(state.list, c => c.id === comment.id)
    if (index > -1) state.list[index].body = comment.body
  },

  REMOVE_COMMENT: (state, id) => {
    const index = findIndex(state.list, c => c.id === id)
    if (index > -1) state.list.splice(index, 1)

    const reviewIndex = findIndex(state.communityReviews, c => c.id === id)
    if (reviewIndex > -1) state.communityReviews.splice(reviewIndex, 1)
  },

  SET_LIKE_COMMENT: (state, data) => {
    const index = findIndex(state.list, c => c.id === data.id)
    if (index > -1) {
      state.list[index].numberOfLikes = data.like.numberOfLikes
      state.list[index].lastActionOnComment = data.like.lastActionOnComment
    }

    const reviewIndex = findIndex(state.communityReviews, c => c.id === data.id)
    if (reviewIndex > -1) {
      state.communityReviews[reviewIndex].commentLikes = data.like.numberOfLikes
      state.communityReviews[reviewIndex].lastActionOnComment =
        data.like.lastActionOnComment
    }
  },

  SET_DISLIKE_COMMENT: (state, data) => {
    const index = findIndex(state.list, c => c.id === data.id)
    if (index > -1) {
      state.list[index].numberOfDislikes = data.dislike.numberOfDislikes
      state.list[index].lastActionOnComment = data.dislike.lastActionOnComment
    }

    const reviewIndex = findIndex(state.communityReviews, c => c.id === data.id)
    if (reviewIndex > -1) {
      state.communityReviews[reviewIndex].commentDislikes =
        data.dislike.numberOfDislikes
      state.communityReviews[reviewIndex].lastActionOnComment =
        data.dislike.lastActionOnComment
    }
  },

  SET_HAS_DISCUSSION: (state, id) => {
    const index = findIndex(state.list, c => c.id === id)
    if (index > -1) {
      state.list[index].hasAlreadyDiscussion = true
    }
  },

  SET_COMMUNITY_REVIEWS: (state, payload) => {
    state.communityReviews = [...state.communityReviews, ...payload]
  },

  SET_COMMUNITY_REVIEWS_IS_LAST_PAGE: (state, isLastPage) => {
    state.communityReviewsIsLastPage = isLastPage
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getPhotoComments({ commit, state }, data) {
    const take = 30
    const page = data.page
    const query = `?take=${take}&page=${page}&photo=${data.id}&status=active`
    const comments = await this.$axios.$get(`/comments${query}`)
    commit('SET_COMMENTS', comments.result)
    commit('SET_IS_LAST_PAGE', comments.isLastPage)
    commit('SET_PAGE', data.page)
  },

  async postCurate({ commit }, data) {
    const endpoint = data.type ? 'curate' : 'user-curate'
    const response = await this.$axios.$post(`/photos/${endpoint}`, data)
    if (data.body) {
      commit('SET_COMMENT', response)
      commit('account/INCREASE_COMMENT_COUNTER', data.photo, { root: true })
    }
    if (response.curationMessage) {
      commit('SET_CURATION_MESSAGE', response.curationMessage)
    }
    if (response.bonusTokens) {
      commit('account/SET_BONUS_TOKENS', response.bonusTokens, { root: true })
    }
    commit('photos/SET_HAS_BEEN_CURATED', response.photoId, { root: true })
    commit('account/MOVE_CURATED_PHOTO', response.photoId, { root: true })
  },

  async postComment({ commit }, data) {
    const comment = await this.$axios.$post('/comments', data)
    commit('SET_COMMENT', comment)
  },

  async patchComment({ commit }, data) {
    const d = {
      body: data.body
    }
    const comment = await this.$axios.$patch(
      `/comments/${data.id || data.photoId}`,
      d
    )
    if (data.feedId) {
      commit('feeds/FEED_COMMENTED', data.feedId, { root: true })
    } else {
      commit('SET_PATCH_COMMENT', comment)
    }
  },

  async reportComment({ commit }, id) {
    await this.$axios.$post(`/comments/${id}/report`)
  },

  async removeComment({ commit }, id) {
    await this.$axios.$delete(`/comments/${id}`)
    commit('REMOVE_COMMENT', id)
  },

  async postLike({ commit }, id) {
    const like = await this.$axios.$post(`/comments/like`, {
      id
    })
    const likeData = {
      id,
      like
    }
      commit('SET_LIKE_COMMENT', likeData)
  },

  async postDislike({ commit }, id) {
    const dislike = await this.$axios.$post(`/comments/dislike`, {
      id
    })
    const dislikeData = {
      id,
      dislike
    }
    commit('SET_DISLIKE_COMMENT', dislikeData)
  },

  async getCommunityReviews({ commit, state }) {
    const take = 30
    let cursor = 0
    if (state.communityReviews.length > 0) {
      const lastCommunityReview = last(state.communityReviews)
      cursor = lastCommunityReview.id
    }
    const query = `?take=${take}&cursor=${cursor}`
    const communityReviews = await this.$axios.$get(`/comments/list${query}`)
    commit('SET_COMMUNITY_REVIEWS', communityReviews.result)
    commit('SET_COMMUNITY_REVIEWS_IS_LAST_PAGE', communityReviews.isLastPage)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
