import findIndex from 'lodash/findIndex'

export const strict = false

const getDefaultState = () => {
  return {
    // notifications
    list: [],
    isLastPage: false,
    page: 1,

    // notes
    notes: [],
    notesIsLastPage: false,
    notesPage: 1
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_NOTIFICATIONS: (state, notifications) => {
    state.list = [...state.list, ...notifications]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_NOTIFICATION_READ: (state, id) => {
    const i = findIndex(state.list, n => n.id === id)
    if (i > -1) {
      state.list[i].hasBeenClicked = true
    }
  },

  SET_NOTIFICATIONS_READ: state => {
    for (let i = 0; i < state.list.length; i += 1) {
      const n = state.list[i]
      n.hasBeenClicked = true
    }
  },

  SET_NOTES: (state, notes) => {
    state.notes = [...state.notes, ...notes]
  },

  SET_NOTES_IS_LAST_PAGE: (state, isLastPage) => {
    state.notesIsLastPage = isLastPage
  },

  SET_NOTES_PAGE: (state, page) => {
    state.notesPage = page
  },

  SET_NOTE_READ: (state, id) => {
    const i = findIndex(state.notes, n => n.id === id)
    if (i > -1) {
      state.notes[i].hasBeenClicked = true
    }
  },

  SET_NOTES_READ: state => {
    for (let i = 0; i < state.notes.length; i += 1) {
      const n = state.notes[i]
      n.hasBeenClicked = true
    }
  },

  RESET_NOTIFICATIONS: state => {
    state.list = []
    state.isLastPage = false
    state.page = 1
  },

  RESET_NOTES: state => {
    state.notes = []
    state.notesIsLastPage = false
    state.notesPage = 1
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getNotifications({ commit, state, rootState }, data) {
    const take = 20
    const page = data.page
    if (rootState.auth.user && rootState.auth.user.id) {
      const query = `?take=${take}&page=${page}&user=${rootState.auth.user.id}`
      const notifications = await this.$axios.$get(`/notifications${query}`)
      if (data.periodic) {
        commit('RESET_NOTIFICATIONS')
      }
      commit('SET_NOTIFICATIONS', notifications.result)
      commit('SET_IS_LAST_PAGE', notifications.isLastPage)
      commit('SET_PAGE', data.page)
    }
  },

  async getNotes({ commit, state, rootState }, data) {
    const take = 20
    const page = data.page
    if (rootState.auth.user && rootState.auth.user.id) {
      const query = `?take=${take}&page=${page}`
      const notifications = await this.$axios.$get(
        `/notifications/notes${query}`
      )
      if (data.periodic) {
        commit('RESET_NOTES')
      }
      commit('SET_NOTES', notifications.result)
      commit('SET_NOTES_IS_LAST_PAGE', notifications.isLastPage)
      commit('SET_NOTES_PAGE', data.page)
    }
  },

  async markAsRead({ commit }, id) {
    await this.$axios.$patch(`/notifications/${id}`)
    commit('SET_NOTIFICATION_READ', id)
  },

  async markAllAsRead({ commit }) {
    await this.$axios.$post('/notifications/mark-all', { read: true })
    commit('SET_NOTIFICATIONS_READ')
  },

  async markNoteAsRead({ commit }, id) {
    await this.$axios.$patch(`/notifications/notes/${id}`)
    commit('SET_NOTE_READ', id)
  },

  async markNotesAllAsRead({ commit }) {
    await this.$axios.$post('/notifications/notes/mark-all', { read: true })
    commit('SET_NOTES_READ')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
