import { set } from 'vue'
import findIndex from 'lodash/findIndex'
import moment from 'moment'
import types from '../utils/types'

export const strict = false

const getDefaultState = () => {
  return {
    // Prime gallery
    list: [],
    isLastPage: false,
    page: 1,
    galleryLoadDate: undefined,

    // Upcoming gallery
    upcomingList: [],
    upcomingIaLastPage: false,
    upcomingPage: 1,
    upcomingGalleryLoadDate: undefined,

    // Opening gallery
    openingList: [],
    openingIsLastPage: false,
    openingPage: 1,
    openingGalleryLoadDate: undefined,

    // Elite gallery
    eliteList: [],
    eliteIsLastPage: false,
    elitePage: 1,
    eliteGalleryLoadDate: undefined,

    // Inspiration gallery
    inspirationList: [],
    inspirationIsLastPage: false,
    inspirationPage: 1,
    inspirationGalleryLoadDate: undefined,

    // Community Awarded gallery
    communityAwardedList: [],
    communityAwardedIsLastPage: false,
    communityAwardedPage: 1,
    communityAwardedGalleryLoadDate: undefined,

    // Prints gallery
    printsList: [],
    printsIsLastPage: false,
    printsPage: 1,

    // Common galleries
    sorround: {},
    reload: false,

    // Photo Details
    photo: {},
    curationBreakdown: {},
    info: {},
    photoExpanded: false,
    color: '#000000',

    // Photo featured in galleries
    featuredIn: [],
    featuredInPage: -1,
    featuredInIsLastPage: false,

    // Curation info
    numberOfPhotoInCuration: 0,
    newPhotosInCuration: false,
    curateLoadDate: undefined,

    // Gallery Filters
    searchTerm: '',
    sorting: 'desc',
    selectedCategory: '0',
    selectedCategoryText: '',
    selectedTags: '0',
    isEliteVotedActive: false,
    isInspirationVotedActive: false,

    // Photos Featured in
    allFeaturedGalleriesDialogVisible: false,
    allFeaturedGalleries: [],
    allFeaturedGalleriesPage: -1,
    allFeaturedGalleriesIsLastPage: false,

    // Curator notes
    notes: [],

    // Zoom delay
    zoomDateTime: null,

    // Curators photo dialog
    curatorsPhotoDialogVisible: false,
    curatorsPhotoDialogPhoto: {},

    // Errors
    errorMessage: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_PHOTOS: (state, photos) => {
    state.list = [...state.list, ...photos]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_UPCOMING_PHOTOS: (state, photos) => {
    state.upcomingList = [...state.upcomingList, ...photos]
  },

  SET_UPCOMING_IS_LAST_PAGE: (state, isLastPage) => {
    state.upcomingIaLastPage = isLastPage
  },

  SET_UPCOMING_PAGE: (state, page) => {
    state.upcomingPage = page
  },

  SET_OPENING_PHOTOS: (state, photos) => {
    state.openingList = [...state.openingList, ...photos]
  },

  SET_OPENING_IS_LAST_PAGE: (state, isLastPage) => {
    state.openingIsLastPage = isLastPage
  },

  SET_OPENING_PAGE: (state, page) => {
    state.openingPage = page
  },

  SET_COMMUNITY_AWARDED_PHOTOS: (state, photos) => {
    state.communityAwardedList = [...state.communityAwardedList, ...photos]
  },

  SET_COMMUNITY_AWARDED_IS_LAST_PAGE: (state, isLastPage) => {
    state.communityAwardedIsLastPage = isLastPage
  },

  SET_COMMUNITY_AWARDED_PAGE: (state, page) => {
    state.communityAwardedPage = page
  },

  SET_INSPIRATION_PHOTOS: (state, photos) => {
    state.inspirationList = [...state.inspirationList, ...photos]
  },

  SET_INSPIRATION_IS_LAST_PAGE: (state, isLastPage) => {
    state.inspirationIsLastPage = isLastPage
  },

  SET_INSPIRATION_PAGE: (state, page) => {
    state.inspirationPage = page
  },

  SET_ELITE_PHOTOS: (state, photos) => {
    state.eliteList = [...state.eliteList, ...photos]
  },

  SET_ELITE_IS_LAST_PAGE: (state, isLastPage) => {
    state.eliteIsLastPage = isLastPage
  },

  SET_ELITE_PAGE: (state, page) => {
    state.elitePage = page
  },

  SET_PRINTS_PHOTOS: (state, photos) => {
    state.printsList = [...state.printsList, ...photos]
  },

  SET_PRINTS_IS_LAST_PAGE: (state, isLastPage) => {
    state.printsIsLastPage = isLastPage
  },

  SET_PRINTS_PAGE: (state, page) => {
    state.printsPage = page
  },

  SET_PHOTO: (state, photo) => {
    state.photo = photo
  },

  SET_CATEGORY_TEXT: (state, categoryText) => {
    state.selectedCategoryText = categoryText
  },

  SET_CATEGORY: (state, category) => {
    state.selectedCategory = category.value
    state.selectedCategoryText = category.text
  },

  SET_TAGS: (state, tag) => {
    state.selectedTags = tag
  },

  SET_FILTERS: (state, filters) => {
    state.searchTerm = filters._searchTerm
    state.sorting = filters._sorting
    state.selectedCategory = filters._selectedCategory
    state.selectedCategoryText = filters._selectedCategoryText
    state.selectedTags = filters._selectedTags
    state.isEliteVotedActive = filters._showVotedForElite
    state.isInspirationVotedActive = filters._showVotedForInspiration
  },

  SET_GALLERY_LOAD_DATE: (state, date) => {
    state.galleryLoadDate = date
  },

  SET_UPCOMING_GALLERY_LOAD_DATE: (state, date) => {
    state.upcomingGalleryLoadDate = date
  },

  SET_OPENING_GALLERY_LOAD_DATE: (state, date) => {
    state.openingGalleryLoadDate = date
  },

  SET_COMMUNITY_AWARDED_GALLERY_LOAD_DATE: (state, date) => {
    state.communityAwardedGalleryLoadDate = date
  },

  SET_ELITE_GALLERY_LOAD_DATE: (state, date) => {
    state.eliteGalleryLoadDate = date
  },

  SET_INSPIRATION_GALLERY_LOAD_DATE: (state, date) => {
    state.inspirationGalleryLoadDate = date
  },

  EDIT_PHOTO: (state, photo) => {
    const listMapper = {
      PRIME: 'list',
      UPCOMING: 'upcomingList',
      OPENING: 'openingList',
      ELITE: 'eliteList',
      INSPIRATION: 'inspirationList'
    }
    const list = listMapper[photo.statusName]
    const index = findIndex(state[list], p => p.id === photo.id)
    if (index > -1) {
      set(state[list], index, photo)
    }
  },

  REMOVE_PHOTO_FROM_UPCOMING: (state, id) => {
    const index = findIndex(state.upcomingList, p => p.id === id)
    if (index > -1) state.upcomingList.splice(index, 1)
  },

  SET_CURATE_LOAD_DATE: (state, date) => {
    state.curateLoadDate = date
  },

  SET_SORROUND: (state, sorround) => {
    state.sorround = sorround
  },

  SET_INFO: (state, info) => {
    state.info = info
  },

  SET_NUMBER_OF_PHOTOS_IN_CURATION: (state, numberOfPhotoInCuration) => {
    if (state.numberOfPhotoInCuration !== numberOfPhotoInCuration) {
      state.newPhotosInCuration = true
    } else {
      state.newPhotosInCuration = false
    }
    state.numberOfPhotoInCuration = numberOfPhotoInCuration
  },

  SET_PHOTO_LIKE: (state, data) => {
    state.photo.numberOfLikes = data.numberOfLikes
    state.info.isLiked = data.value === 1
  },

  SET_PHOTO_LIKE_GALLERY: (state, data) => {
    let list
    if (data.from === 'prime') list = 'list'
    if (data.from === 'upcoming') list = 'upcomingList'
    if (data.from === 'opening') list = 'openingList'
    if (data.from === 'elite') list = 'eliteList'
    if (data.from === 'inspiration') list = 'inspirationList'
    if (data.from === 'communityAwarded') list = 'communityAwardedList'
    if (list) {
      const index = findIndex(state[list], p => p.id === data.id)
      if (index > -1) {
        state[list][index].hasLikedAlready = data.value === 1
      }
    }
    if (state.photo && state.photo.id === data.id) {
      state.photo.numberOfLikes = data.numberOfLikes
    }
  },

  SET_PHOTO_SUPER_LIKE: (state, data) => {
    state.photo.numberOfSuperLikes = data.numberOfSuperLikes
    state.info.isSuperLiked = data.value === 1
  },

  SET_TECHNICAL_LIKE: (state, res) => {
    state.photo.compositionLikeCount = res.composition
    state.photo.technicalLikeCount = res.technicalQuality
    state.photo.creativityLikeCount = res.creativity
    state.photo.contentLikeCount = res.content
    switch (res.likeType) {
      case 'like_composition':
        state.info.isCompositionLiked = !state.info.isCompositionLiked
        break
      case 'like_technical':
        state.info.isTechnicalQualityLiked = !state.info.isTechnicalQualityLiked
        break
      case 'like_creativity':
        state.info.isCreativityLiked = !state.info.isCreativityLiked
        break
      case 'like_content':
        state.info.isContentLiked = !state.info.isContentLiked
        break
    }
  },

  SET_HAS_BEEN_CURATED: (state, id) => {
    if (state.photo && state.photo.id === id) {
      state.photo.hasBeenCurated = true
    }
  },

  UPDATE_COLOR: (state, hex) => {
    state.color = hex
  },

  REMOVE_PHOTO: (state, id) => {
    const index = findIndex(state.list, p => p.id === id)
    if (index > -1) state.list.splice(index, 1)
  },

  TOGGLE_EXPANDER: (state, value) => {
    state.photoExpanded = value
  },

  UPDATE_PROMOTE_VOTES: (state, votes) => {
    if (votes.errorMessage) {
      state.errorMessage = votes.errorMessage
    } else {
      state.photo.numberOfPromoteVotesCommunity =
        votes.numberOfPromoteVotesCommunity
      state.photo.numberOfPromoteVotes = votes.numberOfPromoteVotes
      state.info.isVoteToPromoteCommunity = true
    }
  },

  UPDATE_PROMOTE_ELITE_VOTES: (state, payload) => {
    if (payload.errorMessage) {
      state.errorMessage = payload.errorMessage
      return
    }

    if (state.photo && state.photo.id === payload.id) {
      state.photo.numberOfPromoteVotesElite = payload.numberOfPromoteVotesElite
    }

    if (
      state.curatorsPhotoDialogPhoto &&
      state.curatorsPhotoDialogPhoto.id === payload.id
    ) {
      state.curatorsPhotoDialogPhoto.numberOfPromoteVotesElite =
        payload.numberOfPromoteVotesElite
    }

    const index = findIndex(state.eliteList, p => p.id === payload.id)
    if (index > -1) {
      state.eliteList[index].numberOfPromoteVotesElite =
        payload.numberOfPromoteVotesElite
    }
  },

  UPDATE_PROMOTE_INSPIRATION_VOTES: (state, payload) => {
    if (payload.errorMessage) {
      state.errorMessage = payload.errorMessage
      return
    }

    if (state.photo && state.photo.id === payload.id) {
      state.photo.numberOfPromoteVotesInspiration = payload.numberOfPromoteVotesInspiration
    }

    if (
      state.curatorsPhotoDialogPhoto &&
      state.curatorsPhotoDialogPhoto.id === payload.id
    ) {
      state.curatorsPhotoDialogPhoto.numberOfPromoteVotesInspiration =
        payload.numberOfPromoteVotesInspiration
    }

    const index = findIndex(state.inspirationList, p => p.id === payload.id)
    if (index > -1) {
      state.inspirationList[index].numberOfPromoteVotesInspiration =
        payload.numberOfPromoteVotesInspiration
    }
  },

  UPDATE_PROMOTE_PRIME_PHOTO: (state, payload) => {
    const index = findIndex(state.upcomingList, p => p.id === payload.id)
    if (index > -1) {
      state.list.unshift(payload)
      state.upcomingList.splice(index, 1)
    }
    state.photo = payload
  },

  SET_RELOAD: (state, value) => {
    state.reload = value
  },

  SET_FEATURED_IN: (state, galleries) => {
    state.featuredIn = [...state.featuredIn, ...galleries]
  },

  SET_FEATURED_IN_GALLERIES_DIALOG_VISIBLE: (state, payload) => {
    state.allFeaturedGalleriesDialogVisible = payload
  },

  SET_FEATURED_IN_GALLERIES: (state, galleries) => {
    state.allFeaturedGalleries = [...state.allFeaturedGalleries, ...galleries]
  },

  SET_FEATURED_IN_GALLERIES_PAGE: (state, page) => {
    state.allFeaturedGalleriesPage = page
  },

  SET_FEATURED_IN_GALLERIES_IS_LAST_PAGE: (state, isLastPage) => {
    state.allFeaturedGalleriesIsLastPage = isLastPage
  },

  RESET_FEATURED_GALLERIES_DIALOG: state => {
    state.allFeaturedGalleries = []
    state.allFeaturedGalleriesPage = -1
    state.allFeaturedGalleriesIsLastPage = false
  },

  SET_FEATURED_IN_IS_LAST_PAGE: (state, isLastPage) => {
    state.featuredInIsLastPage = isLastPage
  },

  SET_FEATURED_IN_LAST_PAGE: (state, page) => {
    state.featuredInPage = page
  },

  SET_CURATION_BREAKDOWN: (state, breakdown) => {
    state.curationBreakdown = breakdown
  },

  SET_CURATOR_NOTES: (state, payload) => {
    state.notes = payload
  },

  SET_CURATOR_NOTE: (state, note) => {
    state.notes.unshift(note)
  },

  SET_ZOOM_DATE_TIME: (state, dateTime) => {
    state.zoomDateTime = dateTime
  },

  SET_CURATORS_PHOTO_DIALOG: (state, payload) => {
    state.curatorsPhotoDialogVisible = payload
  },

  SET_CURATORS_PHOTO_DIALOG_PHOTO: (state, payload) => {
    state.curatorsPhotoDialogPhoto = payload
  },

  RESET_FEATURED_IN: state => {
    state.featuredIn = []
    state.featuredInPage = -1
    state.featuredInIsLastPage = false
  },

  RESET_ERROR_MESSAGE: state => {
    state.errorMessage = ''
  },

  RESET_PHOTO: state => {
    state.photo = {}
    state.curationBreakdown = {}
  },

  RESET_GALLERY: state => {
    state.list = []
    state.isLastPage = false
    state.page = 1
    state.galleryLoadDate = undefined
  },

  RESET_UPCOMING_GALLERY: state => {
    state.upcomingList = []
    state.upcomingIaLastPage = false
    state.upcomingPage = 1
    state.upcomingGalleryLoadDate = undefined
  },

  RESET_OPENING_GALLERY: state => {
    state.openingList = []
    state.openingIsLastPage = false
    state.openingPage = 1
    state.openingGalleryLoadDate = undefined
  },

  RESET_COMMUNITY_AWARDED_GALLERY: state => {
    state.communityAwardedList = []
    state.communityAwardedIsLastPage = false
    state.communityAwardedPage = 1
    state.communityAwardedGalleryLoadDate = undefined
  },

  RESET_ELITE_GALLERY: state => {
    state.eliteList = []
    state.eliteIsLastPage = false
    state.elitePage = 1
    state.eliteGalleryLoadDate = undefined
  },

  RESET_INSPIRATION_GALLERY: state => {
    state.inspirationList = []
    state.inspirationIsLastPage = false
    state.inspirationPage = 1
    state.inspirationGalleryLoadDate = undefined
  },

  RESET_PRINTS_GALLERY: state => {
    state.printsList = []
    state.printsIsLastPage = false
    state.printsPage = 1
  },

  RESET_STORE: state => {
    const numberOfPhotoInCuration = state.numberOfPhotoInCuration
    const isEliteVotedActive = state.isEliteVotedActive
    const isInspirationVotedActive = state.isInspirationVotedActive
    Object.assign(state, getDefaultState())
    state.numberOfPhotoInCuration = numberOfPhotoInCuration
    state.isEliteVotedActive = isEliteVotedActive
    state.isInspirationVotedActive = isInspirationVotedActive
  },

  RESET_NEW_PHOTOS_IN_CURATION: state => {
    state.newPhotosInCuration = false
  }
}

export const actions = {
  async getPhotos({ commit, state, rootState }, data) {
    const size = 30
    const page = data.page

    // Base query
    let query = `?size=${size}&page=${page}`

    // Nude filter based on user preferences
    query +=
      !rootState.auth.user ||
      (rootState.auth.user && !rootState.auth.user.isNudeFilterActive)
        ? '&nude=true'
        : '&nude=false'

    // Text Search
    if (state.searchTerm) {
      let term = state.searchTerm
      if (term.charAt(0) === '#') {
        const splitTerm = term.split('#')[1]
        term = `tag:${splitTerm}`
      }
      query += `&searchTerm=${term}`
    }

    // Sorting
    query += `&sort=${state.sorting || 'desc'}`

    // Cateogry
    if (state.selectedCategory !== '0' && state.selectedCategoryText) {
      query += `&category=${state.selectedCategoryText}`
    }

    // Tags
    if (state.selectedTags !== '0') {
      query += `&tags=${state.selectedTags}`
    }

    // User
    if (data.user) query += `&user=${data.user}`

    // Status
    if (data.status) query += `&status=${data.status}`

    // Status
    if (data.awarded) query += '&awarded=true'

    // Show voted for Elite (Prime gallery)
    if (
      state.isEliteVotedActive &&
      (data.status === 'prime' || data.status === 'inspiration')
    ) {
      query += '&voted=true'
    }

    // Show voted for Inspiration (Prime gallery)
    if (
      state.isInspirationVotedActive &&
      data.status === 'prime'
    ) {
      query += '&votedInspiration=true'
    }

    const response = await this.$axios.$get(`/photos${query}`)
    if (data.from === 'prime') {
      commit('SET_PHOTOS', response.result)
      commit('SET_IS_LAST_PAGE', response.isLastPage)
      commit('SET_PAGE', data.page)
    }
    if (data.from === 'upcoming') {
      commit('SET_UPCOMING_PHOTOS', response.result)
      commit('SET_UPCOMING_IS_LAST_PAGE', response.isLastPage)
      commit('SET_UPCOMING_PAGE', data.page)
    }
    if (data.from === 'opening') {
      commit('SET_OPENING_PHOTOS', response.result)
      commit('SET_OPENING_IS_LAST_PAGE', response.isLastPage)
      commit('SET_OPENING_PAGE', data.page)
    }
    if (data.from === 'communityAwarded') {
      commit('SET_COMMUNITY_AWARDED_PHOTOS', response.result)
      commit('SET_COMMUNITY_AWARDED_IS_LAST_PAGE', response.isLastPage)
      commit('SET_COMMUNITY_AWARDED_PAGE', data.page)
    }
    if (data.from === 'elite') {
      commit('SET_ELITE_PHOTOS', response.result)
      commit('SET_ELITE_IS_LAST_PAGE', response.isLastPage)
      commit('SET_ELITE_PAGE', data.page)
    }
    if (data.from === 'inspiration') {
      commit('SET_INSPIRATION_PHOTOS', response.result)
      commit('SET_INSPIRATION_IS_LAST_PAGE', response.isLastPage)
      commit('SET_INSPIRATION_PAGE', data.page)
    }
    if (data.from === 'prints') {
      commit('SET_PRINTS_PHOTOS', response.result)
      commit('SET_PRINTS_IS_LAST_PAGE', response.isLastPage)
      commit('SET_PRINTS_PAGE', data.page)
    }
  },

  async getLicensed({ commit, state, rootState }, data) {
    const size = 30
    const page = data.page

    // Base query
    let query = `?size=${size}&page=${page}`

    // Text Search
    if (state.searchTerm) {
      query += `&searchTerm=${state.searchTerm}`
    }

    // Cateogry
    if (state.selectedCategory !== '0' && state.selectedCategoryText) {
      query += `&category=${state.selectedCategoryText}`
    }

    // Tags
    if (state.selectedTags !== '0') {
      query += `&tags=${state.selectedTags}`
    }

    const response = await this.$axios.$get(`/photos/licensed${query}`)
    commit('SET_PRINTS_PHOTOS', response.result)
    commit('SET_PRINTS_IS_LAST_PAGE', response.isLastPage)
    commit('SET_PRINTS_PAGE', data.page)
  },

  async editPhoto({ commit, rootState }, data) {
    const photo = await this.$axios.$patch(`/photos/${data.id}`, data)
    if (
      photo.statusName === types.photo.status.inCuration ||
      photo.photographerId === rootState.auth.user.id
    ) {
      commit('account/EDIT_PHOTO', photo, { root: true })
    } else {
      commit('EDIT_PHOTO', photo)
    }
  },

  async sendPhotoToCuration({ commit }, id) {
    const photo = await this.$axios.$post(`/photos/${id}/submit-for-curation`)
    // commit('REMOVE_PHOTO_FROM_UPCOMING', photo.id)
    commit('account/MOVE_PHOTO_TO_CURATION', photo, { root: true })
    commit('albums/UPDATE_PHOTO_SENT_TO_CURATION', photo, { root: true })
  },

  setCategory({ commit, rootState }, categoryText) {
    const category = {}
    const index = findIndex(
      rootState.account.categories,
      c => c.text.toLowerCase() === categoryText.toLowerCase()
    )
    if (index > -1) {
      category.value = rootState.account.categories[index].value
      category.text = categoryText
    } else {
      category.value = '0'
      category.text = ''
    }
    commit('SET_CATEGORY', category)
  },

  setTags({ commit }, tag) {
    commit('SET_TAGS', tag)
  },

  setFilters({ commit }, data) {
    commit('SET_FILTERS', data)
  },

  setGalleryLoadDate({ commit }, date) {
    commit('SET_GALLERY_LOAD_DATE', date)
  },

  setUpcomingGalleryLoadDate({ commit }, date) {
    commit('SET_UPCOMING_GALLERY_LOAD_DATE', date)
  },

  setOpeningGalleryLoadDate({ commit }, date) {
    commit('SET_OPENING_GALLERY_LOAD_DATE', date)
  },

  setCommunityAwardedGalleryLoadDate({ commit }, date) {
    commit('SET_COMMUNITY_AWARDED_GALLERY_LOAD_DATE', date)
  },

  setEliteGalleryLoadDate({ commit }, date) {
    commit('SET_ELITE_GALLERY_LOAD_DATE', date)
  },

  setInspirationGalleryLoadDate({ commit }, date) {
    commit('SET_INSPIRATION_GALLERY_LOAD_DATE', date)
  },

  setCurateLoadDate({ commit }, date) {
    commit('SET_CURATE_LOAD_DATE', date)
  },

  setOne({ commit, state, rootState }, data) {
    let photo
    if (data.from === 'prime') {
      photo = state.list[data.index]
    }
    if (data.from === 'upcoming') {
      photo = state.upcomingList[data.index]
    }
    if (data.from === 'opening') {
      photo = state.openingList[data.index]
    }
    if (data.from === 'communityAwarded') {
      photo = state.communityAwardedList[data.index]
    }
    if (data.from === 'elite') {
      photo = state.eliteList[data.index]
    }
    if (data.from === 'inspiration') {
      photo = state.inspirationList[data.index]
    }
    if (data.from === 'prints') {
      photo = state.printsList[data.index]
    }
    if (data.from === 'curation') {
      photo = rootState.account.list[data.index]
    }
    if (data.from === 'curated') {
      photo = rootState.account.curated[data.index]
    }
    if (data.from === 'photographer') {
      photo = rootState.users.photos[data.index]
    }
    if (data.from === 'uploaded') {
      photo = rootState.account.uploaded[data.index]
    }
    if (data.from === 'pending') {
      photo = rootState.account.pending[data.index]
    }
    if (data.from === 'profile') {
      photo = rootState.account.published[data.index]
    }
    if (data.from === 'galleries') {
      photo = rootState.galleries.galleryPhotos[data.index]
    }
    if (data.from === 'albums') {
      photo = rootState.albums.albumPhotos[data.index]
    }
    commit('SET_PHOTO', photo)
  },

  async getOne({ commit }, data) {
    let list = null

    if (data.from === 'prime') list = state.list
    if (data.from === 'upcoming') list = state.upcomingList
    if (data.from === 'opening') list = state.openingList
    if (data.from === 'communityAwarded') list = state.communityAwardedList
    if (data.from === 'inspiration') list = state.inspirationList
    if (data.from === 'elite') list = state.eliteList
    if (data.from === 'prints') list = state.printsList
    const photoIndex = findIndex(list, p => p.id === parseInt(data.id))

    if (photoIndex > -1) {
      const photo = list[photoIndex]
      commit('SET_PHOTO', photo)
    } else {
      const photo = await this.$axios.$get(`/photos/${data.id}`)
      commit('SET_PHOTO', photo)
    }
  },

  setReload({ commit }, value) {
    commit('SET_RELOAD', value)
  },

  setSorround({ commit, state, rootState }, sorroundData) {
    const prevPhotoIndex = sorroundData.prev
    const nextPhotoIndex = sorroundData.next
    const sorround = {
      previousPhotoId: null,
      previousPhotoSlug: '',
      nextPhotoId: null,
      nextPhotoSlug: ''
    }

    if (sorroundData.from === 'prime') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.list[prevPhotoIndex]) {
          sorround.previousPhotoId = state.list[prevPhotoIndex].id
          sorround.previousPhotoSlug = state.list[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.list[nextPhotoIndex]) {
          sorround.nextPhotoId = state.list[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.list[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'upcoming') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.upcomingList[prevPhotoIndex]) {
          sorround.previousPhotoId = state.upcomingList[prevPhotoIndex].id
          sorround.previousPhotoSlug = state.upcomingList[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.upcomingList[nextPhotoIndex]) {
          sorround.nextPhotoId = state.upcomingList[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.upcomingList[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'opening') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.openingList[prevPhotoIndex]) {
          sorround.previousPhotoId = state.openingList[prevPhotoIndex].id
          sorround.previousPhotoSlug = state.openingList[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.openingList[nextPhotoIndex]) {
          sorround.nextPhotoId = state.openingList[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.openingList[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'communityAwarded') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.communityAwardedList[prevPhotoIndex]) {
          sorround.previousPhotoId = state.communityAwardedList[prevPhotoIndex].id
          sorround.previousPhotoSlug = state.communityAwardedList[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.communityAwardedList[nextPhotoIndex]) {
          sorround.nextPhotoId = state.communityAwardedList[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.communityAwardedList[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'elite') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.eliteList[prevPhotoIndex]) {
          sorround.previousPhotoId = state.eliteList[prevPhotoIndex].id
          sorround.previousPhotoSlug = state.eliteList[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.eliteList[nextPhotoIndex]) {
          sorround.nextPhotoId = state.eliteList[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.eliteList[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'inspiration') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.inspirationList[prevPhotoIndex]) {
          sorround.previousPhotoId = state.inspirationList[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            state.inspirationList[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.inspirationList[nextPhotoIndex]) {
          sorround.nextPhotoId = state.inspirationList[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.inspirationList[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'prints') {
      if (typeof prevPhotoIndex === 'number') {
        if (state.printsList[prevPhotoIndex]) {
          sorround.previousPhotoId = state.printsList[prevPhotoIndex].id
          sorround.previousPhotoSlug = state.printsList[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (state.printsList[nextPhotoIndex]) {
          sorround.nextPhotoId = state.printsList[nextPhotoIndex].id
          sorround.nextPhotoSlug = state.printsList[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'curation') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.account.list[prevPhotoIndex]) {
          sorround.previousPhotoId = rootState.account.list[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.account.list[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.account.list[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.account.list[nextPhotoIndex].id
          sorround.nextPhotoSlug = rootState.account.list[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'curated') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.account.curated[prevPhotoIndex]) {
          sorround.previousPhotoId =
            rootState.account.curated[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.account.curated[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.account.curated[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.account.curated[nextPhotoIndex].id
          sorround.nextPhotoSlug =
            rootState.account.curated[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'photographer') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.users.photos[prevPhotoIndex]) {
          sorround.previousPhotoId = rootState.users.photos[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.users.photos[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.users.photos[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.users.photos[nextPhotoIndex].id
          sorround.nextPhotoSlug = rootState.users.photos[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'uploaded') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.account.uploaded[prevPhotoIndex]) {
          sorround.previousPhotoId =
            rootState.account.uploaded[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.account.uploaded[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.account.uploaded[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.account.uploaded[nextPhotoIndex].id
          sorround.nextPhotoSlug =
            rootState.account.uploaded[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'pending') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.account.pending[prevPhotoIndex]) {
          sorround.previousPhotoId =
            rootState.account.pending[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.account.pending[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.account.pending[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.account.pending[nextPhotoIndex].id
          sorround.nextPhotoSlug =
            rootState.account.pending[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'profile') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.account.published[prevPhotoIndex]) {
          sorround.previousPhotoId =
            rootState.account.published[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.account.published[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.account.published[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.account.published[nextPhotoIndex].id
          sorround.nextPhotoSlug =
            rootState.account.published[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'galleries') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.galleries.galleryPhotos[prevPhotoIndex]) {
          sorround.previousPhotoId =
            rootState.galleries.galleryPhotos[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.galleries.galleryPhotos[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.galleries.galleryPhotos[nextPhotoIndex]) {
          sorround.nextPhotoId =
            rootState.galleries.galleryPhotos[nextPhotoIndex].id
          sorround.nextPhotoSlug =
            rootState.galleries.galleryPhotos[nextPhotoIndex].slug
        }
      }
    }

    if (sorroundData.from === 'albums') {
      if (typeof prevPhotoIndex === 'number') {
        if (rootState.albums.albumPhotos[prevPhotoIndex]) {
          sorround.previousPhotoId =
            rootState.albums.albumPhotos[prevPhotoIndex].id
          sorround.previousPhotoSlug =
            rootState.albums.albumPhotos[prevPhotoIndex].slug
        }
      }
      if (typeof nextPhotoIndex === 'number') {
        if (rootState.albums.albumPhotos[nextPhotoIndex]) {
          sorround.nextPhotoId = rootState.albums.albumPhotos[nextPhotoIndex].id
          sorround.nextPhotoSlug =
            rootState.albums.albumPhotos[nextPhotoIndex].slug
        }
      }
    }

    commit('SET_SORROUND', sorround)
  },

  async getInfo({ commit, rootState }, id) {
    if (rootState.auth.loggedIn) {
      const info = await this.$axios.$get(`/photos/${id}/me`)
      commit('SET_INFO', info)
    }
  },

  async getNumberOfPhotosInCuration({ commit, rootState }) {
    if (
      rootState.auth.loggedIn &&
      rootState.auth.user &&
      rootState.auth.user.id
    ) {
      let query =
        rootState.auth.user && !rootState.auth.user.isNudeFilterActive
          ? '?nude=true'
          : '?nude=false'
      query += `&user=${rootState.auth.user.id}`
      const numberOfPhotoInCuration = await this.$axios.$get(
        `/photos/in-curation${query}`
      )
      commit('SET_NUMBER_OF_PHOTOS_IN_CURATION', numberOfPhotoInCuration)
    }
  },

  async like({ commit }, data) {
    const res = await this.$axios.$post(
      `/photos/${data.id}/${data.value === 1 ? 'like' : 'dislike'}`
    )
    const payload = { ...data, ...res }
    if (data.from === 'feeds') {
      commit('feeds/SET_USER_LIKED', payload, { root: true })
    } else if (data.from === 'photo') {
      commit('SET_PHOTO_LIKE', payload)
      commit('account/SET_PHOTO_LIKE', payload, { root: true })
    } else if (data.from === 'photographers') {
      commit('users/SET_PHOTO_LIKE', payload, { root: true })
    } else if (data.from === 'albums') {
      commit('albums/SET_PHOTO_LIKE', payload, { root: true })
    } else if (data.from === 'galleries') {
      commit('galleries/SET_PHOTO_LIKE', payload, { root: true })
    } else {
      commit('SET_PHOTO_LIKE_GALLERY', payload)
    }
  },

  async technicalLike({ commit }, data) {
    const d = {
      likeType: data.type
    }
    const res = await this.$axios.$post(
      `/photos/${data.id}/technical-like`,
      d
    )
    res.likeType = data.type
    commit('SET_TECHNICAL_LIKE', res)
  },

  async reportPhoto({ commit }, id) {
    await this.$axios.$post(`/photos/${id}/report`)
  },

  async removePhoto({ commit }, id) {
    await this.$axios.$delete(`/photos/${id}`)
    commit('REMOVE_PHOTO', id)
    commit('account/REMOVE_PHOTO', id, { root: true })
  },

  async share({ commit }, data) {
    await this.$axios.$post('/photos/social-share', data)
  },

  async promote({ commit }, id) {
    commit('RESET_ERROR_MESSAGE')
    const res = await this.$axios.$post(`photos/${id}/promote`, {})
    commit('UPDATE_PROMOTE_VOTES', res)
  },

  async promoteElite({ commit }, id) {
    commit('RESET_ERROR_MESSAGE')
    const res = await this.$axios.$post(`photos/${id}/promote-elite`, {})
    res.id = id
    commit('UPDATE_PROMOTE_ELITE_VOTES', res)
  },

  async downvotePromoteElite({ commit }, id) {
    commit('RESET_ERROR_MESSAGE')
    const res = await this.$axios.$post(`photos/${id}/downvote-promote-elite`, {})
    res.id = id
    commit('UPDATE_PROMOTE_ELITE_VOTES', res)
  },

  async promoteInspiration({ commit }, id) {
    commit('RESET_ERROR_MESSAGE')
    const res = await this.$axios.$post(`photos/${id}/promote-inspiration`, {})
    res.id = id
    commit('UPDATE_PROMOTE_INSPIRATION_VOTES', res)
  },

  async promotePrime({ commit }, id) {
    commit('RESET_ERROR_MESSAGE')
    const res = await this.$axios.$post(`photos/${id}/promote-prime`, {})
    res.id = id
    commit('UPDATE_PROMOTE_PRIME_PHOTO', res)
  },

  async getFeaturedIn({ commit }, payload) {
    if (payload.page === 1) {
      commit('RESET_FEATURED_IN')
    }
    const res = await this.$axios.$get(
      `/photos/${payload.id}/featured?page=${payload.page}&size=${payload.size}`
    )
    commit('SET_FEATURED_IN', res.result)
    commit('SET_FEATURED_IN_IS_LAST_PAGE', res.isLastPage)
    commit('SET_FEATURED_IN_LAST_PAGE', payload.page)
  },

  async getAllFeaturedGalleries({ commit }, payload) {
    const res = await this.$axios.$get(
      `/photos/${payload.id}/featured?page=${payload.page}&size=${payload.size}`
    )
    commit('SET_FEATURED_IN_GALLERIES', res.result)
    commit('SET_FEATURED_IN_GALLERIES_IS_LAST_PAGE', res.isLastPage)
    commit('SET_FEATURED_IN_GALLERIES_PAGE', payload.page)
  },

  featuredInGalleriesDialogVisible({ commit }, payload) {
    commit('SET_FEATURED_IN_GALLERIES_DIALOG_VISIBLE', payload)
  },

  async getCurationBreakdown({ commit }, id) {
    const res = await this.$axios.$get(
      `/actions/curation-summary?photoId=${id}`
    )
    commit('SET_CURATION_BREAKDOWN', res)
  },

  async getNotes({ commit }, id) {
    const res = await this.$axios.$get(`/photos/${id}/get-curator-note`)
    commit('SET_CURATOR_NOTES', res)
  },

  async postNote({ commit }, payload) {
    const res = await this.$axios.$post(
      `/photos/${payload.photoId}/add-curator-note`,
      { body: payload.body }
    )
    commit('SET_CURATOR_NOTE', res)
  },

  setZoomDateTime({ commit }) {
    const now = moment()
    commit('SET_ZOOM_DATE_TIME', now)
  },

  resetFeaturedGalleriesDialog({ commit }) {
    commit('RESET_FEATURED_GALLERIES_DIALOG')
  },

  updateColor({ commit }, hex) {
    commit('UPDATE_COLOR', hex)
  },

  setCuratorsPhotoDialog({ commit }, payload) {
    commit('SET_CURATORS_PHOTO_DIALOG', payload)
  },

  setCuratorsPhotoDialogPhoto({ commit }, payload) {
    commit('SET_CURATORS_PHOTO_DIALOG_PHOTO', payload)
  },

  resetPhoto({ commit }) {
    commit('RESET_PHOTO')
  },

  resetOpeningGallery({ commit }) {
    commit('RESET_OPENING_GALLERY')
  },

  resetCommunityAwardedGallery({ commit }) {
    commit('RESET_COMMUNITY_AWARDED_GALLERY')
  },

  resetEliteGallery({ commit }) {
    commit('RESET_ELITE_GALLERY')
  },

  resetGallery({ commit }) {
    commit('RESET_GALLERY')
  },

  resetUpcomingGallery({ commit }) {
    commit('RESET_UPCOMING_GALLERY')
  },

  resetInspirationGallery({ commit }) {
    commit('RESET_INSPIRATION_GALLERY')
  },

  resetPrintsGallery({ commit }) {
    commit('RESET_PRINTS_GALLERY')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  },

  resetNewPhotosInCuration({ commit }) {
    commit('RESET_NEW_PHOTOS_IN_CURATION')
  },

  toggleExpander({ commit }, value) {
    commit('TOGGLE_EXPANDER', value)
  }
}
