export const strict = false

const getDefaultState = () => {
  return {
    bestPhotosOfTheDay: [],
    bestPhotosOfTheWeek: [],
    bestPhotosOfTheMonth: [],

    // Most active users
    mostActiveUsers: [],
    mostActiveUsersIsLastPage: false,
    mostActiveUsersPage: -1,

    // most active critiques
    mostActiveCritique: [],
    mostActiveCritiqueIsLastPage: false,
    mostActiveCritiquePage: -1,

    // Most Published photos
    mostPublishedPhotos: [],
    mostPublishedPhotosIsLastPage: false,
    mostPublishedPhotosPage: -1
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_BEST_PHOTOS: (state, data) => {
    state.bestPhotosOfTheDay = [
      ...state.bestPhotosOfTheDay,
      ...data.bestPhotosOfTheDay
    ]
    state.bestPhotosOfTheWeek = [
      ...state.bestPhotosOfTheWeek,
      ...data.bestPhotosOfTheWeek
    ]
    state.bestPhotosOfTheMonth = [
      ...state.bestPhotosOfTheMonth,
      ...data.bestPhotosOfTheMonth
    ]
  },

  SET_MOST_ACTIVE_USERS: (state, users) => {
    state.mostActiveUsers = [...state.mostActiveUsers, ...users]
  },

  SET_MOST_ACTIVE_USERS_IS_LAST_PAGE: (state, isLastPage) => {
    state.mostActiveUsersIsLastPage = isLastPage
  },

  SET_MOST_ACTIVE_USERS_PAGE: (state, page) => {
    state.mostActiveUsersPage = page
  },

  SET_MOST_ACTIVE_CRITIQUE: (state, users) => {
    state.mostActiveCritique = [...state.mostActiveCritique, ...users]
  },

  SET_MOST_ACTIVE_CRITIQUE_IS_LAST_PAGE: (state, isLastPage) => {
    state.mostActiveCritiqueIsLastPage = isLastPage
  },

  SET_MOST_ACTIVE_CRITIQUE_PAGE: (state, page) => {
    state.mostActiveCritiquePage = page
  },

  SET_MOST_PUBLISHED_PHOTOS: (state, users) => {
    state.mostPublishedPhotos = [...state.mostPublishedPhotos, ...users]
  },

  SET_MOST_PUBLISHED_PHOTOS_IS_LAST_PAGE: (state, isLastPage) => {
    state.mostPublishedPhotosIsLastPage = isLastPage
  },

  SET_MOST_PUBLISHED_PHOTOS_PAGE: (state, page) => {
    state.mostPublishedPhotosPage = page
  }
}

export const actions = {
  async getBestPhotos({ commit }) {
    const bestPhotosOfTheDay = await this.$axios.$get(
      '/leader-board/best-photos?period=d'
    )
    const bestPhotosOfTheWeek = await this.$axios.$get(
      '/leader-board/best-photos?period=w'
    )
    const bestPhotosOfTheMonth = await this.$axios.$get(
      '/leader-board/best-photos?period=m'
    )
    commit('SET_BEST_PHOTOS', {
      bestPhotosOfTheDay,
      bestPhotosOfTheWeek,
      bestPhotosOfTheMonth
    })
  },

  async getMostActiveUsers({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(
      `/leader-board/most-active-users${query}`
    )
    commit('SET_MOST_ACTIVE_USERS', response.result)
    commit('SET_MOST_ACTIVE_USERS_IS_LAST_PAGE', response.isLastPage)
    commit('SET_MOST_ACTIVE_USERS_PAGE', payload.page)
  },

  async getMostActiveCritique({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(
      `/leader-board/most-active-critique${query}`
    )
    commit('SET_MOST_ACTIVE_CRITIQUE', response.result)
    commit('SET_MOST_ACTIVE_CRITIQUE_IS_LAST_PAGE', response.isLastPage)
    commit('SET_MOST_ACTIVE_CRITIQUE_PAGE', payload.page)
  },

  async getMostPublishedPhotos({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(
      `/leader-board/most-published-photos${query}`
    )
    commit('SET_MOST_PUBLISHED_PHOTOS', response.result)
    commit('SET_MOST_PUBLISHED_PHOTOS_IS_LAST_PAGE', response.isLastPage)
    commit('SET_MOST_PUBLISHED_PHOTOS_PAGE', payload.page)
  }
}
