import findIndex from 'lodash/findIndex'
import types from '../utils/types'

export const strict = false

const getDefaultState = () => {
  return {
    photographer: {},
    overview: {},
    achievements: {},

    selectedStatus: undefined,
    photos: [],
    isLastPage: false,
    page: 1,

    photographers: [],
    photographersIsLastPage: false,
    photographersPage: 1,

    curators: [],
    curatorsIsLastPage: false,
    curatorsPage: 1,

    ambassadors: [],
    ambassadorsIsLastPage: false,
    ambassadorsPage: 1,

    activity: {},
    activityView: types.period.weekly,

    reputation: {},
    reputationView: types.period.weekly,

    latestReputationList: [],
    latestReputationTotalPages: 0,
    latestReputationPage: 0,
    loadingReputationPeriod: false,
    lastReputationLoaded: false,

    latestUserActionsList: [],
    latestUserActionsTotalPages: 0,
    latestUserActionsPage: 0,
    loadingActivityPeriod: false,
    lastActivitiesLoaded: false,

    talkjsSignature: undefined,

    // Filters
    searchTerm: '',
    sorting: '',
    selectedCategory: '0',
    selectedCategoryText: '',
    role: '',
    country: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_PHOTOGRAPHER: (state, photographer) => {
    state.photographer = photographer
  },

  SET_OVERVIEW: (state, overview) => {
    state.overview = overview
  },

  SET_ACHIEVEMENTS: (state, achievements) => {
    state.achievements = achievements
  },

  SET_PHOTOS: (state, photos) => {
    state.photos = [...state.photos, ...photos]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_PHOTO_LIKE: (state, data) => {
    const index = findIndex(state.photos, p => p.id === data.id)
    if (index > -1) {
      state.photos[index].hasLikedAlready = data.value === 1
    }
  },

  SET_PHOTOGRAPHERS: (state, photographers) => {
    state.photographers = [...state.photographers, ...photographers]
  },

  SET_PHOTOGRAPHERS_IS_LAST_PAGE: (state, isLastPage) => {
    state.photographersIsLastPage = isLastPage
  },

  SET_PHOTOGRAPHERS_PAGE: (state, page) => {
    state.photographersPage = page
  },

  SET_CATEGORY: (state, category) => {
    state.selectedCategory = category.value
    state.selectedCategoryText = category.text
  },

  SET_FILTERS: (state, filters) => {
    state.searchTerm = filters._searchTerm
    state.sorting = filters._sorting
    state.role = filters._role
    state.selectedCategory = filters._selectedCategory
    state.selectedCategoryText = filters._selectedCategoryText
    state.country = filters._country
  },

  SET_CURATORS: (state, curators) => {
    state.curators = [...state.curators, ...curators]
  },

  SET_CURATORS_IS_LAST_PAGE: (state, isLastPage) => {
    state.curatorsIsLastPage = isLastPage
  },

  SET_CURATORS_PAGE: (state, page) => {
    state.curatorsPage = page
  },

  SET_AMBASSADORS: (state, ambassadors) => {
    state.ambassadors = [...state.ambassadors, ...ambassadors]
  },

  SET_AMBASSADORS_IS_LAST_PAGE: (state, isLastPage) => {
    state.ambassadorsIsLastPage = isLastPage
  },

  SET_AMBASSADORS_PAGE: (state, page) => {
    state.ambassadorsPage = page
  },

  SET_FOLLOW: (state, id) => {
    const index = findIndex(state.photographers, p => p.id === id)
    if (index > -1) state.photographers[index].isFollowing = true
  },

  SET_UNFOLLOW: (state, id) => {
    const index = findIndex(state.photographers, p => p.id === id)
    if (index > -1) state.photographers[index].isFollowing = false
  },

  SET_REPUTATION: (state, data) => {
    state.reputation = data.reputation
    state.reputationView = data.period
  },

  SET_REPUTATION_LOADING: (state, loading) => {
    state.loadingReputationPeriod = loading
  },

  SET_ACTIVITY: (state, data) => {
    state.activity = data.activity
    state.activityView = data.period
  },

  SET_ACTIVITY_LOADING: (state, loading) => {
    state.loadingActivityPeriod = loading
  },

  SET_LATEST_REPUTATION: (state, latestReputationList) => {
    state.latestReputationList = [
      ...state.latestReputationList,
      ...latestReputationList
    ]
    state.lastReputationLoaded = true
  },

  SET_LATEST_REPUTATION_TOTAL_PAGES: (state, totalPages) => {
    state.latestReputationTotalPages = totalPages
  },

  SET_LATEST_REPUTATION_PAGE: (state, page) => {
    state.latestReputationPage = page
  },

  SET_LATEST_USER_ACTIONS: (state, latestUserActionsList) => {
    state.latestUserActionsList = [
      ...state.latestUserActionsList,
      ...latestUserActionsList
    ]
    state.lastActivitiesLoaded = true
  },

  SET_LATEST_USER_ACTIONS_TOTAL_PAGES: (state, totalPages) => {
    state.latestUserActionsTotalPages = totalPages
  },

  SET_LATEST_USER_ACTIONS_PAGE: (state, page) => {
    state.latestUserActionsPage = page
  },

  SET_SELECTED_STATUS: (state, status) => {
    state.selectedStatus = status
  },

  SET_USER_BLOCK: (state, id) => {
    const index = findIndex(state.photographers, p => p.id === id)
    if (index > -1) state.photographers[index].isThisUserBlocked = true

    if (state.photographer && state.photographer.id === id) {
      state.photographer.isThisUserBlocked = true
    }
  },

  SET_USER_UNBLOCK: (state, id) => {
    const index = findIndex(state.photographers, p => p.id === id)
    if (index > -1) state.photographers[index].isThisUserBlocked = false

    if (state.photographer && state.photographer.id === id) {
      state.photographer.isThisUserBlocked = false
    }
  },

  SET_TALKJS_SIGNATURE: (state, signature) => {
    state.talkjsSignature = signature
  },

  RESET_PHOTOGRAPHERS: state => {
    state.photographers = []
    state.photographersIsLastPage = false
    state.photographersPage = 0
  },

  RESET_PHOTOS: state => {
    state.photos.length = 0
    state.isLastPage = false
    state.page = 1
  },

  RESET_PHOTOGRAPHER: state => {
    state.photographer = {}
    state.overview = {}
    state.achievements = {}
    state.selectedStatus = undefined
    state.photos = []
    state.isLastPage = false
    state.page = 1
    state.activity = {}
    state.activityView = types.period.weekly
    state.reputation = {}
    state.reputationView = types.period.weekly
    state.latestReputationList = []
    state.latestReputationTotalPages = 0
    state.latestReputationPage = 0
    state.loadingReputationPeriod = false
    state.lastReputationLoaded = false
    state.latestUserActionsList = []
    state.latestUserActionsTotalPages = 0
    state.latestUserActionsPage = 0
    state.loadingActivityPeriod = false
    state.lastActivitiesLoaded = false
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getUsers({ commit, state, rootState }, data) {
    const size = 30
    const page = data.page

    let query = `?page=${page}&size=${size}`
    query += rootState.auth.user ? `&user=${rootState.auth.user.id}` : ''

    if (data.useFilters) {
      if (state.sorting) {
        query += `&sort=${state.sorting}`
      }
      if (state.searchTerm) {
        query += `&search=${state.searchTerm}`
      }
      if (state.role) {
        query += `&role=${state.role}`
      }
      if (state.selectedCategory !== '0' && state.selectedCategoryText) {
        query += `&skills=${state.selectedCategoryText}`
      }
      if (state.country) {
        query += `&country=${state.country}`
      }
    }

    const photographers = await this.$axios.$get(`/users${query}`)
    commit('SET_PHOTOGRAPHERS', photographers.result)
    commit('SET_PHOTOGRAPHERS_IS_LAST_PAGE', photographers.isLastPage)
    commit('SET_PHOTOGRAPHERS_PAGE', data.page)
  },

  async getUserOverview({ commit }, id) {
    const overview = await this.$axios.$get(`/users/${id}/overview`)
    commit('SET_OVERVIEW', overview)
  },

  async getUserAchievements({ commit }, id) {
    const achievements = await this.$axios.$get(`/users/${id}/achievements`)
    commit('SET_ACHIEVEMENTS', achievements)
  },

  async getCurators({ commit, rootState }, data) {
    const size = 30
    const page = data.page

    let query = `?page=${page}&size=${size}&role=curator`
    query += rootState.auth.user ? `&user=${rootState.auth.user.id}` : ''
    if (data.searchText) {
      query += `&search=${data.searchText}`
    }
    const curators = await this.$axios.$get(`/users${query}`)
    commit('SET_CURATORS', curators.result)
    commit('SET_CURATORS_IS_LAST_PAGE', curators.isLastPage)
    commit('SET_CURATORS_PAGE', data.page)
  },

  async getAmbassadors({ commit, rootState }, data) {
    const size = 30
    const page = data.page

    let query = `?page=${page}&size=${size}&role=ambassador`
    query += rootState.auth.user ? `&user=${rootState.auth.user.id}` : ''
    if (data.searchText) {
      query += `&search=${data.searchText}`
    }
    const ambassadors = await this.$axios.$get(`/users${query}`)
    commit('SET_AMBASSADORS', ambassadors.result)
    commit('SET_AMBASSADORS_IS_LAST_PAGE', ambassadors.isLastPage)
    commit('SET_AMBASSADORS_PAGE', data.page)
  },

  async getUser({ commit, rootState }, id) {
    commit('RESET_PHOTOGRAPHER')
    if (rootState.auth.user && rootState.auth.user.id === id) {
      commit('SET_PHOTOGRAPHER', rootState.auth.user)
    } else {
      const photographer = await this.$axios.$get(`/users/${id}`)
      commit('SET_PHOTOGRAPHER', photographer)
    }
  },

  async getUserPhotos({ commit, state }, data) {
    const size = 30
    const page = data.page
    let query = `?size=${size}&page=${page}&user=${data.user}&nude=${data.nude}`
    if (data.status || state.selectedStatus)
      query += `&status=${data.status || state.selectedStatus}`
    if (data.sort) query += `&sort=${data.sort}`
    if (data.showUploaded) query += `&showUploaded=${data.showUploaded}`
    const response = await this.$axios.$get(`/photos${query}`)
    commit('SET_PHOTOS', response.result)
    commit('SET_IS_LAST_PAGE', response.isLastPage)
    commit('SET_PAGE', data.page)
  },

  async getReputation({ commit }, data) {
    commit('SET_REPUTATION_LOADING', true)
    try {
      const reputation = await this.$axios.$get(
        `/stats/reputation?user=${data.id}&period=${data.period}`
      )
      const mutate = {
        reputation,
        period: data.period
      }
      commit('SET_REPUTATION', mutate)
    } finally {
      commit('SET_REPUTATION_LOADING', false)
    }
  },

  async getActivity({ commit }, data) {
    commit('SET_ACTIVITY_LOADING', true)
    try {
      const activity = await this.$axios.$get(
        `/stats/activity?user=${data.id}&period=${data.period}`
      )
      const mutate = {
        activity,
        period: data.period
      }
      commit('SET_ACTIVITY', mutate)
    } finally {
      commit('SET_ACTIVITY_LOADING', false)
    }
  },

  async getLatestReputation({ commit }, data) {
    const size = 10
    if (
      !state.latestReputationPage ||
      state.latestReputationPage < state.latestReputationTotalPages
    ) {
      const query = `?page=${data.page}&size=${size}&user=${data.id}`
      const latestReputation = await this.$axios.$get(
        `/stats/reputation-activity${query}`
      )
      commit('SET_LATEST_REPUTATION', latestReputation.result)
      commit('SET_LATEST_REPUTATION_TOTAL_PAGES', latestReputation.totalPages)
      commit('SET_LATEST_REPUTATION_PAGE', data.page)
    }
  },

  async getLatestUserActions({ commit }, data) {
    const size = 10
    if (
      !state.latestUserActionsPage ||
      state.latestUserActionsPage < state.latestUserActionsTotalPages
    ) {
      const query = `?page=${data.page}&size=${size}&user=${data.id}`
      const latestUserActions = await this.$axios.$get(
        `/stats/user-activity${query}`
      )
      commit('SET_LATEST_USER_ACTIONS', latestUserActions.result)
      commit(
        'SET_LATEST_USER_ACTIONS_TOTAL_PAGES',
        latestUserActions.totalPages
      )
      commit('SET_LATEST_USER_ACTIONS_PAGE', data.page)
    }
  },

  async isUserBlocked({ commit }, id) {
    const isBlocked = await this.$axios.$get(`/users/is-blocked?id=${id}`)
    isBlocked ? commit('SET_USER_BLOCK', id) : commit('SET_USER_UNBLOCK', id)
  },

  async block({ commit }, id) {
    await this.$axios.$post('/users/block', { id })
    commit('SET_USER_BLOCK', id)
  },

  async unblock({ commit }, id) {
    await this.$axios.$post('/users/unblock', { id })
    commit('SET_USER_UNBLOCK', id)
  },

  async getTalkjsSignature({ commit }, id) {
    const data = {
      id
    }
    const signature = await this.$axios.$post(
      `${process.env.baseUrl}/proxy/talkjs-signature`,
      data
    )
    commit('SET_TALKJS_SIGNATURE', signature)
  },

  setCategory({ commit, rootState }, categoryText) {
    const category = {}
    const index = findIndex(
      rootState.account.categories,
      c => c.text.toLowerCase() === categoryText.toLowerCase()
    )
    if (index > -1) {
      category.value = rootState.account.categories[index].value
      category.text = categoryText
    } else {
      category.value = '0'
      category.text = ''
    }
    commit('SET_CATEGORY', category)
  },

  setFilters({ commit }, data) {
    commit('SET_FILTERS', data)
  },

  setSelectedStatus({ commit }, status) {
    commit('SET_SELECTED_STATUS', status)
  },

  resetPhotographers({ commit }) {
    commit('RESET_PHOTOGRAPHERS')
  },

  resetPhotos({ commit }) {
    commit('RESET_PHOTOS')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
