<template>
  <v-list-item
    :class="{
      'grey lighten-4': !n.hasBeenClicked && !$vuetify.theme.dark,
      black: !n.hasBeenClicked && $vuetify.theme.dark
    }"
  >
    <v-list-item-avatar>
      <v-icon :color="getIcon(n.type).color">
        {{ getIcon(n.type).icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-bold">
          New Curator note
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <small>
          {{ n.creationDate | printdate }}
        </small>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <div class="text-body-2">
          <div v-if="n.type === 'CuratorNote'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              has added a note to a
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photo/${n.photoId}`
                  )
                "
              >
                photo
              </span>
            </span>
          </div>
          <div v-else>
            <span>{{ n.description }}</span>
          </div>
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-avatar
      v-if="
        n.photoThumbnail ||
          n.userAvatar ||
          ['NewUser', 'InternalCommunication'].includes(n.type)
      "
    >
      <span
        class="avatar-link link"
        @click="
          markNotificationAndGo(
            n.hasBeenClicked,
            n.id,
            getMainNotificationLink(n)
          )
        "
      >
        <v-img
          :src="
            n.photoThumbnail || n.userAvatar || '/images/100asa-round-icon.png'
          "
          :alt="n.title"
          width="40"
          height="40"
        />
      </span>
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import moment from 'moment'
import { notificationIcon } from '@/helpers/notificationIcon'

export default {
  name: 'NotesDescription',
  filters: {
    printdate: date => {
      return moment
        .utc(date)
        .local()
        .format('D MMMM YYYY @ HH:mm')
    }
  },
  props: {
    n: {
      type: Object,
      required: true
    }
  },
  methods: {
    getIcon(type) {
      return notificationIcon(type)
    },
    getMainNotificationLink(n) {
      if (n.galleryId) {
        return `/galleries/${n.galleryId}`
      } else if (n.photoId) {
        return `/photo/${n.photoId}/${n.photoSlug}?trigger=notes`
      } else if (n.userId) {
        return `/photographer/${n.userName || n.userId}`
      } else {
        return '#'
      }
    },
    async markNotificationAndGo(hasBeenClicked, id, path) {
      if (!hasBeenClicked) {
        try {
          await this.$store.dispatch('notifications/markNoteAsRead', id)
        } catch (error) {
          this.$asa.appInsights('Comp: notifications/NotesDescription.vue - method: markNotificationAndGo', error)
        }
      }
      if (path) this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.notification-list .v-list-item__subtitle,
.v-list-item__title {
  white-space: normal;
}
.avatar-link {
  width: 40px;
  height: 40px;
}
.link {
  cursor: pointer;
}
</style>
