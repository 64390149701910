export const strict = false

const getDefaultState = () => {
  return {
    list: []
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_FAQS: (state, list) => {
    state.list = [...list]
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getFaqs({ commit, state }) {
    if (state.list.length === 0) {
      const {
        results
      } = await this.$prismic.api.query(
        this.$prismic.predicates.at('document.type', 'faq'),
        { pageSize: 100, orderings: '[my.faq.faq_position]' }
      )
      commit('SET_FAQS', results)
    }
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
