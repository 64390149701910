import findIndex from 'lodash/findIndex'
import each from 'lodash/each'
import last from 'lodash/last'

export const strict = false

const getDefaultState = () => {
  return {
    list: [],
    isLastPage: false,
    page: 1,

    othersData: [],
    othersDataIsLastPage: false,
    othersDataPage: 1
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_FEEDS: (state, list) => {
    state.list = [...state.list, ...list]
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_LAST_PAGE: (state, page) => {
    state.page = page
  },

  SET_OTHERS_DATA: (state, list) => {
    state.othersData = [...state.othersData, ...list]
  },

  SET_OTHERS_DATA_IS_LAST_PAGE: (state, isLastPage) => {
    state.othersDataIsLastPage = isLastPage
  },

  SET_OTHERS_DATA_LAST_PAGE: (state, page) => {
    state.othersDataPage = page
  },

  SET_USER_SUPER_LIKED: (state, data) => {
    const index = findIndex(state.list, f => f.id === data.feedId)
    if (index > -1) {
      const feed = state.list[index]
      feed.isSuperLiked = !feed.isSuperLiked
      feed.superLikes = feed.superLikes + data.value
    }
  },

  SET_USER_LIKED: (state, data) => {
    const index = findIndex(state.list, f => f.id === data.feedId)
    if (index > -1) {
      const feed = state.list[index]
      feed.likedAlready = data.value === 1
      feed.likes = data.numberOfLikes
    }
  },

  SET_PHOTO_CURATED: (state, id) => {
    const index = findIndex(state.list, f => f.id === id)
    if (index > -1) {
      const feed = state.list[index]
      feed.curatedAlready = true
    }
  },

  SET_SHOW_TEXTAREA: (state, id) => {
    each(state.list, f => (f.showTextarea = false))
    const index = findIndex(state.list, f => f.id === id)
    if (index > -1) {
      const feed = state.list[index]
      feed.showTextarea = !feed.showTextarea
    }
  },

  FEED_COMMENTED: (state, id) => {
    const index = findIndex(state.list, f => f.id === id)
    if (index > -1) {
      const feed = state.list[index]
      feed.showTextarea = false
      feed.commentAlready = true
    }
  },

  SET_COMMENTS_FOR_FEED: (state, data) => {
    const feed = state.list[data.index]
    if (!feed) return
    feed.comments = [...feed.comments, ...data.comments]
    feed.current = data.current
  },

  MOCK_USER_FOLLOWING: (state, id) => {
    const index = findIndex(state.othersData, u => u.userId === id)
    if (index > -1) {
      state.othersData[index].following = !state.othersData[index].following
    }
  },

  SET_MY_COMMENT: (state, data) => {
    const index = findIndex(state.list, f => f.id === data.feedId)
    if (index > -1) {
      const feed = state.list[index]
      feed.myComment = data.body
    }
  },

  RESET_OTHERS_DATA: state => {
    state.othersData.length = 0
    state.othersDataIsLastPage = false
    state.othersDataPage = -1
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getFeeds({ commit, state, rootState }, data) {
    const size = data.size || 10
    let cursor = 0
    if (state.list.length > 0) {
      const lastFeed = last(state.list)
      cursor = lastFeed.actionId
    }
    if (!state.isLastPage && rootState.auth.user && rootState.auth.user.id) {
      const query = `?page=${data.page}&size=${size}&user=${rootState.auth.user.id}&cursor=${cursor}`
      const list = await this.$axios.$get(`/feeds${query}`)
      commit('SET_FEEDS', list.result)
      commit('SET_IS_LAST_PAGE', list.isLastPage)
      commit('SET_LAST_PAGE', data.page)
    }
  },

  async getPhotoComments({ commit, state }, data) {
    const index = findIndex(state.list, f => f.id === data.feedId)
    if (index > -1) {
      const feed = state.list[index]
      const take = 3
      const skip = take * data.current
      if (feed.numberOfComments > 0 && feed.numberOfComments > skip) {
        const query = `?take=${take}&skip=${skip}&photo=${data.photoId}&status=active`
        const comments = await this.$axios.$get(`/comments${query}`)
        if (feed.current < data.current) {
          const commentsData = {
            comments: comments.result,
            current: data.current,
            index
          }
          commit('SET_COMMENTS_FOR_FEED', commentsData)
        }
      }
    }
  },

  async getOtherComments({ commit, state, rootState }, data) {
    const size = 10
    if (!state.isLastPage && rootState.auth.user && rootState.auth.user.id) {
      const query = `?page=${data.page}&size=${size}`
      const list = await this.$axios.$get(
        `/feeds/comment/${data.photoId}/${rootState.auth.user.id}${query}`
      )
      commit('SET_OTHERS_DATA', list.result)
      commit('SET_OTHERS_DATA_IS_LAST_PAGE', list.isLastPage)
      commit('SET_OTHERS_DATA_LAST_PAGE', data.page)
    }
  },

  async getOtherLikes({ commit, state, rootState }, data) {
    const size = 10
    if (!state.isLastPage && rootState.auth.user && rootState.auth.user.id) {
      const query = `?page=${data.page}&size=${size}`
      const list = await this.$axios.$get(
        `/feeds/users/${data.photoId}/3/${rootState.auth.user.id}${query}`
      )
      commit('SET_OTHERS_DATA', list.result)
      commit('SET_OTHERS_DATA_IS_LAST_PAGE', list.isLastPage)
      commit('SET_OTHERS_DATA_LAST_PAGE', data.page)
    }
  },

  setMyComment({ commit }, data) {
    commit('SET_MY_COMMENT', data)
  },

  showTextarea({ commit }, id) {
    commit('SET_SHOW_TEXTAREA', id)
  },

  mockUserFollowing({ commit }, id) {
    commit('MOCK_USER_FOLLOWING', id)
  },

  resetOthersData({ commit }) {
    commit('RESET_OTHERS_DATA')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
