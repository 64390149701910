<template>
  <v-dialog v-model="active" persistent width="400">
    <v-card outlined>
      <v-card-text class="pt-4">
        <div class="text-center mb-6">
          <v-icon x-large color="warning">
            fal fa-exclamation-triangle
          </v-icon>
        </div>
        <div class="text-center">
          <div v-html="html" />
        </div>
        <div class="text-center mt-6">
          <v-btn
            :disabled="processing"
            outlined
            depressed
            class="mx-3"
            @click="closeConfirmDialog(callback)"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="processing"
            depressed
            dark
            class="mx-3"
            @click="confirmDialogRequest(callback)"
          >
            confirm
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'ConfirmDialog',
  data() {
    return {
      active: false,
      html: '',
      callback: () => {},
      processing: false
    }
  },
  mounted() {
    EventBus.$on('confirm-dialog', data => this.showConfirmDialog(data))
  },
  methods: {
    showConfirmDialog(data) {
      this.active = true
      this.html = data.html
      this.callback = data.callback || (() => {})
    },
    closeConfirmDialog(cb) {
      this.active = false
      this.html = ''
      this.processing = false
      if (cb) {
        const data = { isConfirmed: false }
        cb(data)
      }
    },
    async confirmDialogRequest(cb) {
      try {
        this.processing = true
        const data = { isConfirmed: true }
        await cb(data)
        this.closeConfirmDialog(false)
      } catch (error) {
        this.$asa.appInsights('Comp: layouts/ConfirmDialog.vue - method: confirmDialogRequest', error)
      } finally {
        this.processing = false
      }
    }
  }
}
</script>
