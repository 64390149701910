export const strict = false

const getDefaultState = () => {
  return {
    settings: {
      textColor: 'light',
      primaryColor: '#B71C1C',
      albumIds: [],
      verticalView: false,
      showSkills: false,
      active: false
    }
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_PORTFOLIO: (state, payload) => {
    state.settings = payload
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getPortfolio({ commit }, id) {
    const response = await this.$axios.$get(`/portfolio/${id}`)
    commit('SET_PORTFOLIO', response)
  },

  async createPortfolio({ commit }, payload) {
    const response = await this.$axios.$post('/portfolio', payload)
    commit('SET_PORTFOLIO', response)
  },

  async editPortfolio({ commit }, payload) {
    await this.$axios.$patch('/portfolio', payload)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
