<template>
  <v-dialog v-model="visible" persistent width="500">
    <v-card outlined>
      <v-card-text class="mt-6">
        <prismic-rich-text
          :field="message"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-checkbox
          v-model="dontShow"
          label="Don't show this message again"
          on-icon="fal fa-check-square"
          off-icon="fal fa-square"
          small
        ></v-checkbox>
        <v-spacer />
        <v-btn depressed small @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GlobalNotificationsDialog',
  data() {
    return {
      dontShow: false
    }
  },
  computed: {
    ...mapState({
      visible: state => state.globalNotificationsDialogVisible,
      message: state => state.globalNotificationsDialogMessage,
      uid: state => state.globalNotificationsDialogUid
    })
  },
  methods: {
    close() {
      const prefix = process.env.NODE_ENV === 'production' ? '100asa.' : '100asadev.'
      if (this.dontShow) {
        this.$cookies.set(`${prefix}${this.uid}`, 'true', {
          path: '/',
          maxAge: 60 * 60 * 24 * 365, // one year
          secure: true,
          sameSite: 'none'
        })
      }
      this.$store.commit('SET_GLOBAL_NOTIFICATIONS_DIALOG', {
        visible: false
      })
    }
  }
}
</script>
