<template>
  <v-dialog v-model="visible" hide-overlay persistent width="360">
    <v-card outlined>
      <v-card-text class="mt-6">
        <p class="font-weight-bold">
          You have exceeded your Curation Submission for the period.
        </p>
        <p v-if="!$asa.hasAnActiveSubscription() || $asa.isProMember()" class="mb-0">
          Please upgrade your membership if you wish to submit more photo for curation.
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn :class="{ 'mr-3': !$asa.hasAnActiveSubscription() || $asa.isProMember() }" depressed small @click="close">
          Cancel
        </v-btn>
        <v-btn v-if="!$asa.hasAnActiveSubscription() || $asa.isProMember()" depressed small color="success" @click="upgrade">
          Get Pro{{ $asa.isProMember() ? '+' : '' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CurationSubmissionLimitDialog',
  computed: {
    ...mapState({
      visible: state => state.curationSubmissionLimitDialogVisible
    })
  },
  methods: {
    close() {
      this.$store.commit('SET_CURATION_SUBMISSION_LIMIT_DIALOG', false)
    },
    upgrade() {
      this.$router.push('/membership')
      this.close()
    }
  }
}
</script>
