export const notificationIcon = type => {
  switch (type) {
    case 'GalleryCertificateAvailable':
      return {
        icon: 'fas fa-file-certificate',
        color: 'success'
      }
    case 'PhotoPublishedByTask':
    case 'PublishedToUpcoming':
    case 'PublishToUpcoming':
    case 'PhotoRejectedByTask':
      return {
        icon: 'fas fa-image',
        color: 'success'
      }
    case 'CommentRemovedByModerator':
      return {
        icon: 'fas fa-comment-slash',
        color: 'error'
      }
    case 'RoleChanged':
      return {
        icon: 'fas fa-trophy',
        color: 'info'
      }
    case 'SubscriptionCreated':
    case 'SubscriptionRenewed':
    case 'VoteToPromoteElite':
      return {
        icon: 'fas fa-star',
        color: 'info'
      }
    case 'SubscriptionPaymentFailed':
      return {
        icon: 'fas fa-star',
        color: 'error'
      }
    case 'CommentVoteUp':
      return {
        icon: 'fas fa-thumbs-up',
        color: 'success'
      }
    case 'CommentVoteDown':
      return {
        icon: 'fas fa-thumbs-down',
        color: 'error'
      }
    case 'Like':
    case 'LikeAlbum':
    case 'LikeGallery':
      return {
        icon: 'fas fa-heart',
        color: 'success'
      }
    case 'SocialShare':
      return {
        icon: 'fas fa-share-square',
        color: 'success'
      }
    case 'TechnicalLike':
      return {
        icon: 'fas fa-magic',
        color: 'success'
      }
    case 'ShareOnFacebook':
    case 'ShareOnInstagram':
    case 'ShareOnTwitter':
      return {
        icon: 'fas fa-share',
        color: 'success'
      }
    case 'WriteComment':
    case 'DiscussionItem':
    case 'NewCritiqueChatMessage':
      return {
        icon: 'fas fa-comments',
        color: 'info'
      }
    case 'PhotoDeletedByModerator':
      return {
        icon: 'fas fa-exclamation-circle',
        color: 'error'
      }
    case 'ModeratorVote':
      return {
        icon: 'fas fa-gavel',
        color: 'info'
      }
    case 'Follower':
      return {
        icon: 'fas fa-glass-cheers',
        color: 'success'
      }
    case 'SuperLikeWon':
      return {
        icon: 'fas fa-grin-stars',
        color: 'success'
      }
    case 'TokensConverted':
      return {
        icon: 'fas fa-coins',
        color: 'info'
      }
    case 'PhotoPublishedByTaskInspiration':
      return {
        icon: 'fal fa-lightbulb-on',
        color: 'success'
      }
    case 'InternalCommunication':
      return {
        icon: 'fas fa-info-circle',
        color: 'info'
      }
    case 'NewUser':
      return {
        icon: 'fas fa-smile-beam',
        color: 'info'
      }
    case 'VoteToPromoteInspiration':
    case 'PhotoNominated':
      return {
        icon: 'fas fa-lightbulb-on',
        color: 'info'
      }
    case 'PhotoAddedToGallery':
      return {
        icon: 'fas fa-layer-plus',
        color: 'success'
      }
    case 'CuratorNote':
      return {
        icon: 'fas fa-clipboard',
        color: 'info'
      }
    case 'CritiqueCompleted':
      return {
        icon: 'fas fa-chalkboard-teacher',
        color: 'success'
      }
    default:
      return {
        icon: 'fas fa-chevron-right',
        color: 'info'
      }
  }
}
