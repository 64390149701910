/* eslint-disable camelcase */
export const strict = false

const getDefaultState = () => {
  return {
    items: [],
    page: 1,
    totalPages: null
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = [...state.items, ...items]
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_TOTAL_PAGES: (state, totalPages) => {
    state.totalPages = totalPages
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getItems({ commit, state }) {
    const pageSize = 30
    const page = state.page
    const query = [this.$prismic.predicates.at('document.type', 'reward')]
    const { results, total_pages } = await this.$prismic.api.query(query, {
      orderings: '[my.reward.order]',
      pageSize,
      page
    })
    commit('SET_ITEMS', results)
    if (state.totalPages !== total_pages) {
      commit('SET_TOTAL_PAGES', total_pages)
    }
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
