import { displayError } from '@/utils/swal'

export default function({ $axios, redirect, $asa }) {
  $axios.defaults.baseURL = process.env.apiBaseUrl
  $axios.setHeader('Content-Type', 'application/json; charset=utf-8')
  $axios.setHeader('Access-Control-Allow-Origin', true)
  $axios.setHeader('x-100asa-source', 'web')
  $axios.interceptors.response.use(responseHandler, responseErrorHandler)

  function responseHandler(response) {
    return response
  }

  function responseErrorHandler(response) {
    return httpErrorHandler(response)
  }

  function httpErrorHandler(error) {
    if (error === null) {
      $asa.appInsights('Unrecoverable error, please get in touch with the Support Team at info@100asa.com', error)
      displayError(
        'Unrecoverable error, please get in touch with the Support Team at info@100asa.com'
      )
    }
    if (error && (error.response || error.request)) {
      const response = error.response
      const request = error.request
      const status = response && response.status ? response.status : undefined
      const isStripe = response && response.config ? response.config.url.includes('stripe') : false

      if (status || (status && isStripe)) {
        $asa.appInsights(status, error)

        switch(status) {
          case 401:
            displayError('Please login to access this resource')
            redirect('/logout')
            break
          case 403:
            displayError('You are not authorised to do this action')
            break
          case 404: {
            let path = '/404'
            if (response.data === 'photo_removed') {
              path += '?err=pr'
            } else if (response.data === 'user_removed') {
              path += '?err=ur'
            } else {
              path = '/404'
            }
            if (!isStripe) {
              redirect(path)
            }
            break
          }
          case 417:
            displayError(response.data, 'info')
            break
          case 422:
            displayError(response.data)
            break
          default:
            handleErrorMessage(response)
        }
      } else if (request) {
        $asa.appInsights('No response from the server, please get in touch with the Support Team at info@100asa.com', error)
        displayError(
          'No response from the server, please get in touch with the Support Team at info@100asa.com'
        )
      }
    } else {
      $asa.appInsights(error.message, error)
      displayError(error.message)
    }
    return Promise.reject(error)
  }

  function handleErrorMessage(response) {
    if (response && response.data) {
      if (response.data.error) {
        const e = response.data.error
        switch (e.type) {
          case 'StripeCardError':
            displayError('An error occurred during a Stripe payment, please get in touch with the Support Team at info@100asa.com')
            break
          case 'StripeInvalidRequestError':
            displayError('Stripe resource not found, please get in touch with the Support Team at info@100asa.com')
            break
          case 'StripeConnectionError':
            displayError('There was a network problem between 100ASA and Stripe, please get in touch with the Support Team at info@100asa.com')
            break
          case 'StripeAPIError':
            displayError(`Something went wrong on Stripe's end, please get in touch with the Support Team at info@100asa.com`)
            break
          case 'StripeRateLimitError':
            displayError('You made too many API calls in too short a time.')
            break
          default:
            displayError(
              'Unrecoverable error, please get in touch with the Support Team at info@100asa.com'
            )
            break
        }
      } else {
        displayError(
          'Unrecoverable error, please get in touch with the Support Team at info@100asa.com'
        )
      }
    } else {
      displayError(
        'Unrecoverable error, please get in touch with the Support Team at info@100asa.com'
      )
    }
  }
}
