<template>
  <v-snackbar
    v-model="active"
    :top="true"
    :color="color"
    :timeout="timeout"
    multi-line
  >
    {{ text }}
    <template #action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        depressed
        color="white"
        @click="active = false"
      >
        <v-icon small>fal fa-times fa-md</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'Snackbar',
  data() {
    return {
      active: false,
      color: 'info',
      timeout: 5000,
      text: ''
    }
  },
  mounted() {
    EventBus.$on('snackbar', data => this.showSnackbar(data))
  },
  methods: {
    showSnackbar(data) {
      this.active = true
      this.text = data.text
      this.color = data.color || this.color
    }
  }
}
</script>
