<template>
  <v-app>
    <v-main>
      <v-container fluid class="pa-0 ma-0">
        <nuxt />
      </v-container>
    </v-main>
    <v-snackbar
      v-model="snackbar.active"
      :top="true"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      multi-line
    >
      {{ snackbar.text }}
      <v-btn depressed color="white" text @click="snackbar.active = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'PlainLayout',
  data() {
    return {
      snackbar: {
        active: false,
        color: 'info',
        timeout: 12000,
        text: ''
      }
    }
  },
  created() {
    const darkMode = this.$store.state.account.darkMode
    this.$vuetify.theme.light = !darkMode
    this.$vuetify.theme.dark = darkMode
  },
  destroyed() {
    if (this.darkModeTimeoutId) {
      clearTimeout(this.darkModeTimeoutId)
    }
  },
  mounted() {
    EventBus.$on('snackbar', data => this.showSnackbar(data))
    this.darkModeTimeoutId = setTimeout(() => {
      this.$vuetify.theme.light = !this.darkMode
      this.$vuetify.theme.dark = this.darkMode
    })
  },
  methods: {
    showSnackbar(data) {
      this.snackbar.active = true
      this.snackbar.text = data.text
      this.snackbar.color = data.color ? data.color : this.snackbar.color
    }
  }
}
</script>
