const middleware = {}

middleware['adminsOrCurators'] = require('../middleware/adminsOrCurators.js')
middleware['adminsOrCurators'] = middleware['adminsOrCurators'].default || middleware['adminsOrCurators']

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authOnMobile'] = require('../middleware/authOnMobile.js')
middleware['authOnMobile'] = middleware['authOnMobile'].default || middleware['authOnMobile']

middleware['dashboard'] = require('../middleware/dashboard.js')
middleware['dashboard'] = middleware['dashboard'].default || middleware['dashboard']

middleware['header'] = require('../middleware/header.js')
middleware['header'] = middleware['header'].default || middleware['header']

middleware['inactive'] = require('../middleware/inactive.js')
middleware['inactive'] = middleware['inactive'].default || middleware['inactive']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.js')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

export default middleware
