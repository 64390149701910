/**
 * @param {*} _url String: original image URL
 * @param {*} _type Enum[String]: 'thumb' | 'full' | 'feed' | 'zoom'
 * @param {*} _watermark Boolean: if true, 100ASA overlay will be added
 * @returns Generated Image URL for ImageKit
 *
 * Possible transformations:
 * Thumbnails: n-thumb, n-feed
 * Full: n-full_s, n-full_m, n-full_l, n-full_s_wm, n-full_m_wm, n-full_l_wm, n-full_z, n-full_z_wm
 *
 * S, M, L, Z = Small (H 720), Medium (H 1440), Large (H 2160), Zoom (H 4320)
 * WM = WaterMark
 */
export default (_url, _type, _watermark, fast) => {
  if (!_url) {
    return
  }
  const thumbnailTransformation = '/tr:n-thumbnail'
  const fullTransformation = '/tr:n-full'
  const oldWm = '_wm'

  let cleanup =  _url.replace(thumbnailTransformation, '')
  cleanup = cleanup.replace(fullTransformation, '')
  cleanup = cleanup.replace(oldWm, '')

  const finalUrl = cleanup
  const isThumb = _type && (_type === 'thumb' || _type === 'feed')
  const transformationVersion = '_v6'

  let res =
    window.innerHeight <= 2160 ? (window.innerHeight <= 720 ? 's' : 'm') : 'l'
  res = _type === 'zoom' ? 'z' : res

  const rest = finalUrl.substring(0, finalUrl.lastIndexOf('/') + 1)
  const last = finalUrl.substring(
    finalUrl.lastIndexOf('/') + 1,
    finalUrl.length
  )
  let transformation
  if (fast) {
    transformation = `tr:n-fast${transformationVersion}`
  } else {
    const isFeed = _type === 'feed'
    transformation = `tr:n-${isFeed ? 'feed' : isThumb ? 'thumb' : 'full'}${
      isThumb ? '' : `_${res}`
    }${
      _watermark || isThumb ? '' : '_wm'
    }${transformationVersion}`
  }
  const url = `${rest}${transformation}/${last}`
  return url
}
