export const strict = false

const getDefaultState = () => {
  return {
    confirmed: false,
    returningUser: false,
    message: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_EMAIL_CONFIRMED: (state, confirmed) => {
    state.confirmed = confirmed
  },

  SET_RETURNING_USER: (state, returningUser) => {
    state.returningUser = returningUser
  },

  SET_MESSAGE: (state, message) => {
    state.message = message
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async signup({ commit }, credentials) {
    await this.$axios.$post('/auth/local/register', credentials)
  },

  async oauth({ commit }, data) {
    commit('SET_MESSAGE', '')
    const { token, returningUser, message } = await this.$axios.$post(
      '/auth/provider',
      data
    )
    this.$auth.$storage.setUniversal('_token.google', false)
    this.$auth.$storage.setUniversal('_refresh_token.google', false)
    this.$auth.$storage.setUniversal('_token.facebook', false)
    this.$auth.$storage.setUniversal('_refresh_token.facebook', false)
    if (message) {
      commit('SET_MESSAGE', message)
    } else {
      this.$axios.setHeader('Authorization', `Bearer ${token}`, 'common')
      if (data.provider === 'apple') {
        await this.$auth.setStrategy('local')
        await this.$auth.setUserToken(token)
      }
      this.$auth.$storage.setUniversal('strategy', 'local')
      this.$auth.$storage.setUniversal('_token.local', `Bearer ${token}`)
      await commit('SET_RETURNING_USER', returningUser)
      if (data.provider !== 'apple') {
        await this.$auth.fetchUser()
      }
    }
  },

  cleanCookies() {
    this.$auth.$storage.setUniversal('_token.google', false)
    this.$auth.$storage.setUniversal('_refresh_token.google', false)
    this.$auth.$storage.setUniversal('_token.facebook', false)
    this.$auth.$storage.setUniversal('_refresh_token.facebook', false)
    this.$auth.$storage.setUniversal('_token.local', '')
    this.$auth.$storage.setUniversal('strategy', '')
  },

  async confirm({ commit }, confirmationToken) {
    await this.$axios.$post('/auth/email-confirmed', {
      key: confirmationToken
    })
    commit('SET_EMAIL_CONFIRMED', true)
  },

  async forgot({ commit }, data) {
    await this.$axios.$post('/auth/forgot-password', data)
  },

  async resetPassword({ commit }, data) {
    await this.$axios.$post('/auth/reset-password', data)
  },

  async logout({ commit }) {
    await this.$axios.$post('/auth/logout')
    commit('SET_AUTH_TOKEN', null)
    commit('SET_ME', null)
    this.$cookies.remove('100asa-token')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
