import findIndex from 'lodash/findIndex'
import each from 'lodash/each'
import Vue from 'vue'

export const strict = false

const getDefaultState = () => {
  return {
    // View all Albums
    list: [],
    listIsLastPage: false,
    listPage: -1,
    listLoadDate: undefined,

    // View single album
    albumInfo: {},
    albumOwner: {},
    albumInfoMe: {},
    albumPhotos: [],
    albumPhotosIsLastPage: false,
    albumPhotosPage: -1,

    // Manage user albums
    userAlbums: [],
    userAlbumsIsLastPage: false,
    userAlbumsPage: -1,

    // Manage album photos
    manageAlbumPhotos: [],
    manageAlbumPhotosIsLastPage: false,
    manageAlbumPhotosPage: -1,

    // Owner photos for selection
    selectPhotos: [],
    selectPhotosIsLastPage: false,
    selectPhotosPage: -1,

    // Manage album dialog
    manageAlbum: {},
    manageAlbumDialog: false,

    // Create Edit album dialog
    createAlbumDialog: false,
    editMode: false,
    editAlbum: null
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_AVAILABLE_PHOTOS_FOR_ALBUM: (state, photos) => {
    state.selectPhotos = [...state.selectPhotos, ...photos]
  },

  SET_AVAILABLE_PHOTOS_FOR_ALBUM_IS_LAST_PAGE: (state, isLastPage) => {
    state.selectPhotosIsLastPage = isLastPage
  },

  SET_AVAILABLE_PHOTOS_FOR_ALBUM_PAGE: (state, page) => {
    state.selectPhotosPage = page
  },

  SET_ALBUMS: (state, albums) => {
    state.list = [...state.list, ...albums]
  },

  SET_ALBUMS_IS_LAST_PAGE: (state, isLastPage) => {
    state.listIsLastPage = isLastPage
  },

  SET_ALBUMS_LAST_PAGE: (state, page) => {
    state.listPage = page
  },

  SET_ALBUMS_LOAD_DATE: (state, date) => {
    state.listLoadDate = date
  },

  SET_ALBUM_PHOTOS: (state, photos) => {
    state.albumPhotos = [...state.albumPhotos, ...photos]
  },

  SET_ALBUM_PHOTOS_IS_LAST_PAGE: (state, isLastPage) => {
    state.albumPhotosIsLastPage = isLastPage
  },

  SET_ALBUM_PHOTOS_LAST_PAGE: (state, page) => {
    state.albumPhotosPage = page
  },

  SET_ALBUM_OWNER: (state, owner) => {
    state.albumOwner = owner
  },

  SET_ALBUM_INFO: (state, info) => {
    state.albumInfo = info
  },

  SET_ALBUM_INFO_ME: (state, info) => {
    state.albumInfoMe = info
  },

  SET_USER_ALBUMS: (state, albums) => {
    state.userAlbums = [...state.userAlbums, ...albums]
  },

  SET_USER_ALBUMS_IS_LAST_PAGE: (state, isLastPage) => {
    state.userAlbumsIsLastPage = isLastPage
  },

  SET_USER_ALBUMS_LAST_PAGE: (state, page) => {
    state.userAlbumsPage = page
  },

  ADD_USER_ALBUM: (state, album) => {
    state.userAlbums.unshift(album)
  },

  UPDATE_USER_ALBUM: (state, payload) => {
    const index = findIndex(state.userAlbums, a => a.id === payload.id)
    if (index > -1) {
      Vue.set(state.userAlbums, index, payload)
    }
  },

  UPDATE_PUBLIC_USER_ALBUM: (state, payload) => {
    state.albumInfo = payload
  },

  REMOVE_USER_ALBUM: (state, id) => {
    const index = findIndex(state.userAlbums, a => a.id === id)
    if (index > -1) {
      state.userAlbums.splice(index, 1)
    }
  },

  ADD_PHOTOS_TO_ALBUM_BY_IDS: (state, pids) => {
    each(pids, pid => {
      const index = findIndex(state.selectPhotos, p => p.id === pid)
      if (index > -1) {
        state.manageAlbumPhotos.unshift(state.selectPhotos[index])
      }
    })
  },

  ADD_PHOTOS_TO_PUBLIC_ALBUM_BY_IDS: (state, pids) => {
    each(pids, pid => {
      const index = findIndex(state.selectPhotos, p => p.id === pid)
      if (index > -1) {
        state.albumPhotos.unshift(state.selectPhotos[index])
      }
    })
  },

  ADD_PHOTO_TO_ALBUM_FROM_UPLOAD: (state, photo) => {
    state.manageAlbumPhotos.unshift(photo)
  },

  ADD_PUBLIC_PHOTO_TO_ALBUM_FROM_UPLOAD: (state, photo) => {
    state.albumPhotos.unshift(photo)
  },

  REMOVE_PHOTOS_FROM_SELECT: (state, indexes) => {
    each(indexes, i => {
      state.selectPhotos.splice(i, 1)
    })
  },

  PUSH_PHOTO_TO_SELECT: (state, id) => {
    const index = findIndex(state.manageAlbumPhotos, p => p.id === id)
    if (index > -1) {
      state.selectPhotos.push(state.manageAlbumPhotos[index])
    }
  },

  REMOVE_PHOTO_FROM_ALBUM: (state, id) => {
    const index = findIndex(state.manageAlbumPhotos, p => p.id === id)
    if (index > -1) {
      state.manageAlbumPhotos.splice(index, 1)
    }
  },

  REMOVE_PHOTO_FROM_PUBLIC_ALBUM: (state, id) => {
    const index = findIndex(state.albumPhotos, p => p.id === id)
    if (index > -1) {
      state.albumPhotos.splice(index, 1)
    }
  },

  SET_MANAGE_ALBUM_PHOTOS: (state, photos) => {
    state.manageAlbumPhotos = [...state.manageAlbumPhotos, ...photos]
  },

  SET_MANAGE_ALBUM_PHOTOS_IS_LAST_PAGE: (state, isLastPage) => {
    state.manageAlbumPhotosIsLastPage = isLastPage
  },

  SET_MANAGE_ALBUM_PHOTOS_PAGE: (state, page) => {
    state.manageAlbumPhotosPage = page
  },

  ORDER_MANAGE_ALBUM_PHOTOS: (state, payload) => {
    state.manageAlbumPhotos = payload
  },

  ORDER_PUBLIC_ALBUM_PHOTOS: (state, payload) => {
    state.albumPhotos = payload
  },

  SET_THUMBNAIL: (state, payload) => {
    const albumIndex = findIndex(
      state.userAlbums,
      a => a.id === payload.albumId
    )
    const photoIndex = findIndex(
      state.manageAlbumPhotos,
      p => p.id === payload.photoId
    )
    if (albumIndex > -1 && photoIndex > -1) {
      state.userAlbums[albumIndex].thumbnail =
        state.manageAlbumPhotos[photoIndex].thumbnailUrl
      state.userAlbums[albumIndex].thumbnailLarge =
        state.manageAlbumPhotos[photoIndex].url
    }
  },

  SET_PUBLIC_THUMBNAIL: (state, payload) => {
    const photoIndex = findIndex(
      state.manageAlbumPhotos,
      p => p.id === payload.photoId
    )
    state.albumInfo.thumbnailLarge = state.manageAlbumPhotos[photoIndex].url
  },

  SET_MANAGE_ALBUM_TOTAL_PHOTOS: (state, payload) => {
    const index = findIndex(state.userAlbums, a => a.id === payload.albumId)
    if (index > -1) {
      Vue.set(state.userAlbums[index], 'totalNumberOfPhotos', payload.count)
    }
  },

  SET_MANAGE_ALBUM_DIALOG: (state, album) => {
    state.manageAlbum = album
    state.manageAlbumDialog = true
  },

  CLOSE_MANAGE_ALBUM_DIALOG: state => {
    state.manageAlbum = {}
    state.manageAlbumDialog = false
  },

  SET_ALBUM_LIKE: (state, data) => {
    state.albumInfo.numberOfLikes = data.numberOfLikes
    state.albumInfoMe.likedAlready = data.value === 1
  },

  SET_CREATE_EDIT_ALBUM_DIALOH: (state, value) => {
    state.createAlbumDialog = value
    if (!value) {
      state.editAlbum = null
    }
  },

  SET_EDIT_MODE: (state, value) => {
    state.editMode = value
  },

  SET_EDIT_ALBUM: (state, album) => {
    state.editAlbum = album
  },

  UPDATE_PHOTO_SENT_TO_CURATION: (state, photo) => {
    const managePhotoIndex = findIndex(
      state.manageAlbumPhotos,
      p => p.id === photo.id
    )
    const albumPhotosIndex = findIndex(
      state.albumPhotos,
      p => p.id === photo.id
    )
    if (managePhotoIndex > -1) {
      state.manageAlbumPhotos[managePhotoIndex] = photo
    }
    if (albumPhotosIndex > -1) {
      state.albumPhotos[albumPhotosIndex] = photo
    }
  },

  SET_PHOTO_LIKE: (state, data) => {
    const index = findIndex(state.albumPhotos, p => p.id === data.id)
    if (index > -1) {
      state.albumPhotos[index].numberOfLikes = data.numberOfLikes
      state.albumPhotos[index].hasLikedAlready = data.value === 1
    }
  },

  RESET_ALBUM: state => {
    state.albumInfo = {}
    state.albumOwner = {}
    state.albumInfoMe = {}
    state.albumPhotos = []
    state.albumPhotosIsLastPage = false
    state.albumPhotosPage = -1
  },

  RESET_MANAGE_ALBUM: state => {
    state.manageAlbumPhotos = []
    state.manageAlbumPhotosIsLastPage = false
    state.manageAlbumPhotosPage = -1
    state.selectPhotos = []
    state.selectPhotosIsLastPage = false
    state.selectPhotosPage = -1
  },

  RESET_USER_ALBUMS: state => {
    state.userAlbums = []
    state.userAlbumsIsLastPage = false
    state.userAlbumsPage = -1
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getSelectPhotoForAlbum({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(
      `/albums/${payload.albumId}/select-photos${query}`
    )
    commit('SET_AVAILABLE_PHOTOS_FOR_ALBUM', response.result)
    commit('SET_AVAILABLE_PHOTOS_FOR_ALBUM_IS_LAST_PAGE', response.isLastPage)
    commit('SET_AVAILABLE_PHOTOS_FOR_ALBUM_PAGE', payload.page)
  },

  async getAlbums({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(`/albums${query}`)
    commit('SET_ALBUMS', response.result)
    commit('SET_ALBUMS_IS_LAST_PAGE', response.isLastPage)
    commit('SET_ALBUMS_LAST_PAGE', payload.page)
  },

  setAlbumsLoadDate({ commit }, date) {
    commit('SET_ALBUMS_LOAD_DATE', date)
  },

  async getUserAlbums({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?userid=${payload.userId}&page=${page}&size=${size}`
    const response = await this.$axios.$get(`/albums${query}`)
    commit('SET_USER_ALBUMS', response.result)
    commit('SET_USER_ALBUMS_IS_LAST_PAGE', response.isLastPage)
    commit('SET_USER_ALBUMS_LAST_PAGE', payload.page)
  },

  async createAlbum({ commit }, payload) {
    const response = await this.$axios.$post('/albums', payload)
    commit('ADD_USER_ALBUM', response)
  },

  async updateAlbum({ commit }, payload) {
    const response = await this.$axios.$patch(
      `/albums/${payload.id}`,
      payload
    )
    commit('UPDATE_USER_ALBUM', response)
    if (payload.publicView) {
      commit('UPDATE_PUBLIC_USER_ALBUM', response)
    }
  },

  async removeAlbum({ commit }, id) {
    await this.$axios.$delete(`/albums/${id}`)
    commit('REMOVE_USER_ALBUM', id)
    commit('RESET_MANAGE_ALBUM')
  },

  async addPhotosToAlbum({ commit, state }, payload) {
    const data = {
      photos: payload.pids
    }
    await this.$axios.$post(`/albums/${payload.albumId}/add-photos`, data)
    commit('ADD_PHOTOS_TO_ALBUM_BY_IDS', payload.pids)
    commit('SET_MANAGE_ALBUM_TOTAL_PHOTOS', {
      albumId: payload.albumId,
      count: state.manageAlbumPhotos.length
    })
    if (payload.publicView) {
      commit('ADD_PHOTOS_TO_PUBLIC_ALBUM_BY_IDS', payload.pids)
    }
    commit('REMOVE_PHOTOS_FROM_SELECT', payload.indexes)
  },

  async removePhoto({ commit, state }, payload) {
    const data = {
      photo: payload.photoId
    }
    await this.$axios.$post(`/albums/${payload.albumId}/remove-photo`, data)
    commit('PUSH_PHOTO_TO_SELECT', payload.photoId)
    commit('REMOVE_PHOTO_FROM_ALBUM', payload.photoId)
    commit('SET_MANAGE_ALBUM_TOTAL_PHOTOS', {
      albumId: payload.albumId,
      count: state.manageAlbumPhotos.length
    })
    if (state.manageAlbumPhotos.length === 0) {
      commit('RESET_MANAGE_ALBUM')
    }
    if (payload.publicView) {
      commit('REMOVE_PHOTO_FROM_PUBLIC_ALBUM', payload.photoId)
    }
  },

  async setThumbnail({ commit }, payload) {
    const data = {
      photoId: payload.photoId
    }
    await this.$axios.$post(`/albums/${payload.albumId}/set-thumbnail`, data)
    commit('SET_THUMBNAIL', payload)
    if (payload.publicView) {
      commit('SET_PUBLIC_THUMBNAIL', payload)
    }
  },

  async addPhotoToAlbumFromUpload({ commit, state }, payload) {
    const data = {
      photos: [payload.photo.id]
    }
    await this.$axios.$post(`/albums/${payload.albumId}/add-photos`, data)
    commit('ADD_PHOTO_TO_ALBUM_FROM_UPLOAD', payload.photo)
    commit('SET_MANAGE_ALBUM_TOTAL_PHOTOS', {
      albumId: payload.albumId,
      count: state.manageAlbumPhotos.length
    })
    if (payload.albumId) {
      commit('ADD_PUBLIC_PHOTO_TO_ALBUM_FROM_UPLOAD', payload.photo)
    }
  },

  async getAlbum({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    const response = await this.$axios.$get(
      `/albums/${payload.albumId}${query}`
    )
    if (payload.manage) {
      commit('SET_MANAGE_ALBUM_PHOTOS', response.result)
      commit('SET_MANAGE_ALBUM_PHOTOS_IS_LAST_PAGE', response.isLastPage)
      commit('SET_MANAGE_ALBUM_PHOTOS_PAGE', payload.page)
    } else {
      commit('SET_ALBUM_PHOTOS', response.result)
      commit('SET_ALBUM_PHOTOS_IS_LAST_PAGE', response.isLastPage)
      commit('SET_ALBUM_PHOTOS_LAST_PAGE', payload.page)
      if (payload.page === 1) {
        commit('SET_ALBUM_OWNER', response.owner)
        commit('SET_ALBUM_INFO', response.info)
      }
    }
  },

  async getAlbumInfoMe({ commit, rootState }, id) {
    if (rootState.auth.loggedIn) {
      const info = await this.$axios.$get(`/albums/${id}/me`)
      commit('SET_ALBUM_INFO_ME', info)
    }
  },

  async likeAlbum({ commit }, data) {
    const res = await this.$axios.$post(
      `/albums/${data.id}/${data.value === 1 ? 'like' : 'dislike'}`
    )
    res.value = data.value
    if (data.from === 'feeds') {
      const payload = { ...data, ...res }
      commit('feeds/SET_USER_LIKED', payload, { root: true })
    } else {
      commit('SET_ALBUM_LIKE', res)
    }
  },

  async reportAlbum({ commit }, id) {
    await this.$axios.$post(`/albums/${id}/report`)
  },

  async movePhoto({ commit }, payload) {
    await this.$axios.$post(`/albums/${payload.albumId}/move-photo`, payload)
  },

  setManageAlbumDialog({ commit }, album) {
    commit('SET_MANAGE_ALBUM_DIALOG', album)
  },

  closeManageAlbumDialog({ commit }) {
    commit('CLOSE_MANAGE_ALBUM_DIALOG')
  },

  setCreateEditAlbumDialog({ commit }, value) {
    commit('SET_CREATE_EDIT_ALBUM_DIALOH', value)
  },

  setEditMode({ commit }, value) {
    commit('SET_EDIT_MODE', value)
  },

  setEditAlbum({ commit }, album) {
    commit('SET_EDIT_ALBUM', album)
  },

  resetAlbum({ commit }) {
    commit('RESET_ALBUM')
  },

  resetManageAlbum({ commit }) {
    commit('RESET_MANAGE_ALBUM')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
