export default {
  user: {
    role: {
      guest: 'Guest',
      user: 'User',
      scholar: 'Scholar',
      student: 'Student',
      photographer: 'Photographer',
      guru: 'Guru',
      master: 'Master',
      grandMaster: 'Grandmaster',
      artist: 'Artist',
      legend: 'Legend',
      ambassador: 'Ambassador',
      curator: 'Curator'
    }
  },
  photo: {
    status: {
      uploaded: 'UPLOADED',
      inCuration: 'IN_CURATION',
      inspiration: 'INSPIRATION',
      elite: 'ELITE',
      prime: 'PRIME',
      upcoming: 'UPCOMING',
      opening: 'OPENING',
      album: 'ALBUM'
    }
  },
  period: {
    weekly: 'weekly',
    monthly: 'monthly',
    quarterly: 'quarterly'
  },
  subscription: {
    type: {
      basic: 'BASIC',
      pro: 'PREMIUM',
      proPlus: 'PREMIUM_PLUS'
    }
  },
  critique: {
    status: {
      submitted: 'Submitted',
      inReview: 'InReview',
      reviewed: 'Reviewed'
    },
    type: {
      photo: 'Photo',
      text: 'Text',
      pin: 'Pin'
    },
    requestType: {
      photo: 'Photo',
      album: 'Album',
      portfolio: 'Portfolio'
    },
    tier: {
      classic: 'Classic',
      advanced: 'Advanced'
    }
  },
  reward: {
    type: {
      virtual: 'virtual',
      physical: 'physical',
      passive: 'passive'
    },
    membershipDiscount15: 'membership_discount_15',
    oneMonthPro: 'one_month_pro',
    threeMonthPro: 'three_month_pro',
    oneMonthProPlus: 'one_month_pro_plus',
    threeMonthProPlus: 'three_month_pro_Plus',
    professionalClassicCritique: 'professional_classic_critique',
    bookAeternum1: 'book_aeternum_1',
    plantTree: 'plant_a_tree'
  },
  categories: {
    15: 'Abstract',
    17: 'Aerial',
    19: 'Animals',
    21: 'Black and White',
    23: 'Boudoir',
    25: 'Celebrities',
    27: 'City and Architecture',
    29: 'Commercial',
    31: 'Concert',
    34: 'Fashion',
    36: 'Film',
    38: 'Fine Art',
    40: 'Food',
    42: 'Journalism',
    44: 'Landscapes',
    16: 'Macro',
    18: 'Nature',
    20: 'Night',
    22: 'Nude',
    24: 'People',
    26: 'Performing Arts',
    45: 'Portrait',
    28: 'Sport',
    30: 'Still Life',
    32: 'Street',
    33: 'Transportation',
    35: 'Travel',
    43: 'Uncategorized',
    37: 'Underwater',
    39: 'Urban',
    41: 'Wedding'
  }
}
