export default function (context, inject) {
    if (typeof window !== 'undefined') {
      const stripePlugin = {
        import() {
          return window['Stripe'] && window['Stripe']('pk_live_51HD9Q6JHHTOBQBKKl3tHGcB8sfRkImAJmZhNcVknHD7Ycl0FrU0OkYpORPBsgD2xFjOjK14kvtLjPTg6Srg7554K001E7Lltme', {
            locale: 'en',
          });
        },
      };

      inject('stripe', stripePlugin);
      context.app.stripe = stripePlugin;
    }
}
