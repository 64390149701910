<template>
  <v-footer
    :class="$vuetify.theme.dark ? 'dark' : ''"
    class="px-0 pt-4 pb-0 main-footer"
    height="auto"
  >
    <v-container class="px-6 py-2" fluid>
      <v-row class="my-0">
        <v-col cols="12" sm="6" md="3">
          <nuxt-link to="/" class="text-h6">
            <img
              class="h-26 mb-4"
              src="/images/100asa-logo-white.png"
              alt="100ASA Logo."
              width="143"
              height="26"
            />
          </nuxt-link>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="text-uppercase footer-links mb-4">
            <h4
              :class="{
                'primary--text': $vuetify.theme.dark,
                'secondary--text': $vuetify.theme.light
              }"
              class="text-body-2 mb-2"
            >
              Features
            </h4>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/leaderboard"
              >
                Leaderboard
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/public-critiques"
              >
                Critiques
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/community-reviews"
              >
                Community Reviews
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/albums"
              >
                Albums
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/rewards"
              >
                Rewards
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/learning"
              >
                Learning
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/initiatives"
              >
                Initiatives
              </nuxt-link>
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="text-uppercase footer-links mb-4">
            <h4
              :class="{
                'primary--text': $vuetify.theme.dark,
                'secondary--text': $vuetify.theme.light
              }"
              class="text-body-2 mb-2"
            >
              Help
            </h4>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/membership"
              >
                Membership
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/how-does-it-work"
              >
                How does it work?
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/reputation"
              >
                Reputation
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/reward-points"
              >
                Reward Points
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/faq"
              >
                FAQ
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/contact-us"
              >
                Contact us
              </nuxt-link>
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="text-uppercase footer-links mb-4">
            <h4
              :class="{
                'primary--text': $vuetify.theme.dark,
                'secondary--text': $vuetify.theme.light
              }"
              class="text-body-2 mb-2"
            >
              Legal
            </h4>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/terms-and-conditions"
              >
                Terms &amp; Conditions
              </nuxt-link>
            </p>
            <p class="text-body-2 mb-0">
              <nuxt-link
                :class="{
                  'primary--text': $vuetify.theme.dark,
                  'secondary--text': $vuetify.theme.light
                }"
                to="/privacy-policy"
              >
                Privacy Policy
              </nuxt-link>
            </p>
            <div class="mt-10">
              <a href="https://tree-nation.com/profile/impact/100asa#co2" target="_blank">
                <img src="https://tree-nation.com/images/tracking/label-co2-website-white-en.png" style="width:157px;height:auto;">
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      :class="$vuetify.theme.dark ? 'dark' : ''"
      class="lower-footer text-center"
      fluid
    >
      <v-row class="my-0">
        <v-col cols="12">
          <p class="text-body-2 ma-0">
            &copy; {{ year }} 100ASA Ltd. - All rights reserved
            <span class="mx-4">-</span>
            <a href="https://twitter.com/100ASAOfficial" target="_blank">
              <v-icon small class="mr-4 primary--text">fab fa-twitter</v-icon>
            </a>
            <a href="https://www.facebook.com/100ASAOfficial" target="_blank">
              <v-icon small class="mr-4 primary--text">fab fa-facebook</v-icon>
            </a>
            <a href="https://www.instagram.com/100asa_official" target="_blank">
              <v-icon small class="mr-4 primary--text">fab fa-instagram</v-icon>
            </a>
            <a href="https://www.reddit.com/r/100ASA" target="_blank">
              <v-icon small class="primary--text">fab fa-reddit</v-icon>
            </a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  mounted() {
    const isProd =
      process.env.NODE_ENV === 'production' && process.env.ENV_SLOT === 'production'
    if (isProd) {
      // eslint-disable-next-line no-undef
      treenation_track("63ea7791dd781")
    }
  }
}
</script>

<style scoped>
.h-26 {
  height: 26px;
}
.main-footer {
  background-color: #212121 !important;
  color: #fafafa !important;
}
.lower-footer {
  background-color: #fafafa !important;
  color: #212121 !important;
}
.lower-footer .v-icon {
  color: #212121 !important;
}
.lower-footer.dark {
  background-color: #212121 !important;
  color: #fafafa !important;
}
.lower-footer.dark .v-icon {
  color: #fafafa !important;
}
</style>
