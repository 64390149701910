<template>
  <div>
    <v-menu offset-y open-on-click>
      <template #activator="{ on }">
        <div
          v-if="me.avatarUrl"
          class="d-inline-block top-user-info p-relative"
          v-on="on"
        >
          <v-badge :value="!!amountOfUnreads" color="error" overlap dot>
            <v-avatar dark size="36">
              <img :src="me.avatarUrl" />
            </v-avatar>
          </v-badge>
        </div>
        <v-btn v-else fab small depressed outlined color="primary" v-on="on">
          <v-badge
            :value="!!amountOfUnreads"
            :offset-x="1"
            color="error"
            overlap
            dot
          >
            <v-icon small>fal fa-user</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="!me.hasAnActiveSubscription"
          to="/membership"
          nuxt
          exact
          class="gold"
        >
          <v-list-item-action>
            <v-icon small>fal fa-crown</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-caption font-weight-bold text-uppercase">
                Get Pro
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(n, i) in nav">
          <v-list-item
            v-if="n.to"
            :key="n.label"
            :to="n.to"
            :active-class="
              $vuetify.theme.dark
                ? 'secondary primary--text'
                : 'primary secondary--text'
            "
            dense
            nuxt
            exact
          >
            <v-list-item-action>
              <v-icon small>{{ n.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-caption font-weight-bold text-uppercase">
                  {{ n.label }}
                  <v-chip
                    v-if="n.new"
                    class="ma-0 ml-3 pa-2"
                    color="success"
                    x-small
                  >
                    new
                  </v-chip>
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="n.triggerChat"
            :key="n.label"
            @click="openChat"
          >
            <v-list-item-action>
              <v-icon small>{{ n.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-caption font-weight-bold text-uppercase">
                  <span class="mr-3">{{ n.label }}</span>
                  <v-chip v-if="amountOfUnreads" x-small color="error">
                    {{ amountOfUnreads }}
                  </v-chip>
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-else-if="n.divider" :key="i" class="my-2"></v-divider>
        </template>
      </v-list>
    </v-menu>
    <span class="ml-3 hidden-sm-and-down">
      <span>
        <v-dialog v-model="reputationDialog" width="400">
          <template #activator="{ on }">
            <span class="clickable" v-on="on">
              <v-icon small>fal fa-trophy</v-icon>
              <span class="text-body-2 rep ml-1 mr-3">{{ me.reputation ? me.reputation.toLocaleString() : 0 }}</span>
            </span>
          </template>
          <reputation-dialog-text />
        </v-dialog>
        <v-dialog v-model="tokensDialog" width="800">
          <template #activator="{ on }">
            <span class="clickable" v-on="on">
              <v-icon small color="gold">fal fa-coins</v-icon>
              <span class="text-body-2 rep ml-1">{{ me.userTokens ? me.userTokens.toLocaleString() : 0 }}</span>
            </span>
          </template>
          <tokens-dialog-text :me="me" />
        </v-dialog>
      </span>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Talk from 'talkjs'
import types from '@/utils/types'
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'HeaderUserMenu',
  components: {
    ReputationDialogText: () => import('@/components/common/ReputationDialogText.vue'),
    TokensDialogText: () => import('@/components/common/TokensDialogText.vue')
  },
  props: {
    me: {
      type: [Object, Boolean],
      default: () => {}
    }
  },
  data() {
    return {
      types,
      reputationDialog: false,
      tokensDialog: false,
      amountOfUnreads: undefined
    }
  },
  computed: {
    ...mapState({
      talkjsSignature: state => state.users.talkjsSignature
    }),
    nav() {
      return [
        {
          icon: 'fal fa-user',
          label: 'View Profile',
          to: `/photographer/${this.me ? this.me.userName || this.me.id : ''}`
        },
        {
          icon: 'fal fa-user-edit',
          label: 'Edit Profile',
          to: '/account/profile'
        },
        {
          icon: 'fal fa-images',
          label: 'Manage Photos',
          to: '/account/photos'
        },
        {
          icon: 'fal fa-books',
          label: 'Manage Albums',
          to: '/account/albums'
        },
        {
          icon: 'fal fa-layer-group',
          label: 'Manage Galleries',
          to: '/account/galleries'
        },
        {
          icon: 'fal fa-chalkboard-teacher',
          label: 'Critiques',
          to: '/account/critiques'
        },
        {
          icon: 'fal fa-briefcase',
          label: 'Portfolio',
          to: '/account/portfolio'
        },
        {
          icon: 'fal fa-comment-lines',
          label: 'Messages',
          triggerChat: true
        },
        {
          icon: 'fal fa-money-bill-wave',
          label: 'Licensing',
          to: '/account/licensing'
        },
        {
          icon: 'fal fa-cogs',
          label: 'Settings',
          to: '/account/settings'
        },
        {
          icon: 'fal fa-gavel',
          label: 'Curation',
          to: '/account/curation'
        },
        {
          icon: 'fal fa-comment-dots',
          label: 'Curated',
          to: '/account/curated'
        },
        {
          divider: true
        },
        {
          icon: 'fal fa-sign-out-alt',
          label: 'Logout',
          to: '/logout'
        }
      ]
    }
  },
  created() {
    EventBus.$on('close:reputation:dialog', () => {
      this.reputationDialog = false
    })
    EventBus.$on('close:tokens:dialog', () => {
      this.tokensDialog = false
    })
  },
  async mounted() {
    try {
      await Talk.ready
      if (!this.talkjsSignature) {
        await this.$store.dispatch('users/getTalkjsSignature', this.me.id)
      }
      const me = new Talk.User({
        id: this.me.id,
        name: this.me.fullName,
        email: this.me.email,
        photoUrl: this.me.avatarUrl,
        role: 'user'
      })
      const talkSession = new Talk.Session({
        appId: process.env.talkjsAppId,
        me,
        signature: this.talkjsSignature
      })
      talkSession.unreads.on('change', conversationIds => {
        this.amountOfUnreads = conversationIds.length
      })
    } catch (error) {
      this.$asa.appInsights('Comp: header/UserMenu.vue - method: mounted', error)
    }
  },
  methods: {
    openChat() {
      const data = {
        receiver: null
      }
      EventBus.$emit('chat', data)
    }
  }
}
</script>

<style scoped>
.top-user-info {
  cursor: pointer;
}
.top-user-info .rep {
  vertical-align: middle;
}
.clickable {
  cursor: pointer;
}
</style>
