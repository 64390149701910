<template>
  <v-list-item
    :class="{
      'grey lighten-4': !n.hasBeenClicked && !$vuetify.theme.dark,
      black: !n.hasBeenClicked && $vuetify.theme.dark
    }"
  >
    <v-list-item-avatar>
      <v-icon :color="getIcon(n.type).color">
        {{ getIcon(n.type).icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-bold">
          {{ n.title }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <small>
          {{ n.creationDate | printdate }}
        </small>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <div class="text-body-2">
          <div v-if="n.type === 'PhotoPublishedByTaskElite'">
            <span>
              Congratulations! Your photo has been promoted to the
              <span
                class="font-weight-bold link"
                @click="markNotificationAndGo(n.hasBeenClicked, n.id, '/elite')"
                >Elite Gallery</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'PhotoPublishedByTaskInspiration'">
            <span>
              Congratulations! Your photo has been promoted to the
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(n.hasBeenClicked, n.id, '/inspiration')
                "
                >Inspiration Gallery</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'PublishToUpcoming'">
            <span>
              Congratulations! Your photo has been published to the
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(n.hasBeenClicked, n.id, '/upcoming')
                "
                >Upcoming Gallery</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'GalleryCertificateAvailable'">
            <span>
              Your
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    '/account/photos'
                  )
                "
                >certificate</span
              >
              is ready, don’t miss the opportunity to share it on social media
            </span>
          </div>
          <div v-else-if="n.type === 'CommentVoteUp'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              likes your comment
            </span>
          </div>
          <div v-else-if="n.type === 'WriteComment'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              commented on your photo
            </span>
          </div>
          <div v-else-if="n.type === 'DiscussionItem'">
            <span>
              New activity on
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photo/${n.photoId}?comment=${n.commentId}`
                  )
                "
                >comment discussion</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'Like'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              likes your photo
            </span>
          </div>
          <div v-else-if="n.type === 'Follower'">
            <span v-if="n.userName || n.userId">
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              is now following you
            </span>
            <span v-else>
              {{ n.description }}
            </span>
          </div>
          <div v-else-if="n.type === 'NewUser'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(n.hasBeenClicked, n.id, `/membership`)
                "
                >{{ n.description }}</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'PhotoPublishedByTask'">
            <span>
              Congratulations! Your photo has been published on the
              <span
                class="font-weight-bold link"
                @click="markNotificationAndGo(n.hasBeenClicked, n.id, '/prime')"
                >Prime Gallery</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'PhotoRejectedByTask'">
            <span>
              Your photo has been published on the
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(n.hasBeenClicked, n.id, '/upcoming')
                "
                >Upcoming Gallery</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'CritiqueCompleted'">
            <span>
              Your 
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(n.hasBeenClicked, n.id, `/account/critiques?t=1&id=${n.critiqueId}`)
                "
                >professional critique</span
              > request has been completed
            </span>
          </div>
          <div v-else-if="n.type === 'NewCritiqueChatMessage'">
            <span>
              There is a new message for the 
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(n.hasBeenClicked, n.id, `/account/critiques?t=${me.role === types.user.role.curator || me.isAdmin ? '2' : '1'}&id=${n.critiqueId}&chat=true`)
                "
                >professional critique</span
              >
            </span>
          </div>
          <div v-else-if="n.type === 'PhotoAddedToGallery'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              added your photo to a
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/galleries/${n.galleryId}`
                  )
                "
              >
                gallery
              </span>
            </span>
          </div>
          <div v-else-if="n.type === 'LikeAlbum'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              liked your
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/albums/${n.albumId}`
                  )
                "
              >
                album
              </span>
            </span>
          </div>
          <div v-else-if="n.type === 'LikeGallery'">
            <span>
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/photographer/${n.userName || n.userId}`
                  )
                "
                >{{ n.userFullName }}</span
              >
              liked your
              <span
                class="font-weight-bold link"
                @click="
                  markNotificationAndGo(
                    n.hasBeenClicked,
                    n.id,
                    `/galleries/${n.galleryId}`
                  )
                "
              >
                gallery
              </span>
            </span>
          </div>
          <div v-else-if="n.type === 'PhotoNominated'">
            <span>
              Your photo has been nominated for the Inspiration gallery
            </span>
          </div>
          <div v-else-if="n.type === 'TokensConverted'">
            <span>
              {{ n.description }}
            </span>
          </div>
          <div v-else-if="n.type === 'InternalCommunication'">
            <span>
              <a :href="n.link || ''" class="no-underline">
                {{ n.description }}
              </a>
            </span>
          </div>
          <div v-else>
            <span>{{ n.description }}</span>
          </div>
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-avatar
      v-if="
        n.photoThumbnail ||
          n.userAvatar ||
          ['NewUser', 'InternalCommunication', 'TokensConverted'].includes(
            n.type
          )
      "
    >
      <span
        class="avatar-link link"
        @click="
          markNotificationAndGo(
            n.hasBeenClicked,
            n.id,
            getMainNotificationLink(n)
          )
        "
      >
        <v-img
          :src="
            n.photoThumbnail || n.userAvatar || '/images/100asa-round-icon.png'
          "
          :alt="n.title"
          width="40"
          height="40"
        />
      </span>
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { notificationIcon } from '@/helpers/notificationIcon'
import types from '@/utils/types'

export default {
  name: 'NotificationsDescription',
  filters: {
    printdate: date => {
      return moment
        .utc(date)
        .local()
        .format('D MMMM YYYY @ HH:mm')
    }
  },
  props: {
    n: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      types
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.user
    })
  },
  methods: {
    getIcon(type) {
      return notificationIcon(type)
    },
    getMainNotificationLink(n) {
      if (n.galleryId) {
        return `/galleries/${n.galleryId}`
      } else if (n.photoId) {
        return `/photo/${n.photoId}/${n.photoSlug}`
      } else if (n.userId) {
        return `/photographer/${n.userName || n.userId}`
      } else if (n.critiqueId) {
        return `/account/critiques?t=1&id=${n.critiqueId}`
      } else {
        return '#'
      }
    },
    async markNotificationAndGo(hasBeenClicked, id, path) {
      if (!hasBeenClicked) {
        try {
          await this.$store.dispatch('notifications/markAsRead', id)
        } catch (error) {
          this.$asa.appInsights('Comp: notifications/NotificationsDescription.vue - method: markNotificationAndGo', error)
        }
      }
      if (path) this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.notification-list .v-list-item__subtitle,
.v-list-item__title {
  white-space: normal;
}
.avatar-link {
  width: 40px;
  height: 40px;
}
.link {
  cursor: pointer;
}
</style>
