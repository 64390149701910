<template>
  <v-app :dark="darkMode" :style="{ background: 'secondary' }">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="text-center mt-12">
          <div v-if="error.statusCode === 404">
            <h1>Page not found</h1>
            <p>
              {{ errorMessage }}
            </p>
          </div>
          <div v-else>
            <h1>An error occurred</h1>
            <p>
              Something is going wrong with your request. Please retry
            </p>
          </div>
          <v-btn depressed large dark class="ma-0 mt-6 mr-4" @click="goBack()">
            Back
          </v-btn>
          <v-btn nuxt to="/" depressed large dark class="ma-0 mt-6">
            Home page
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ErrorLayout',
  props: {
    error: {
      type: [Object, Boolean],
      default: () => {}
    }
  },
  data() {
    return {
      darkMode: false,
      darkModeTimeoutId: undefined
    }
  },
  computed: {
    errorMessage() {
      const query = this.$route.query.err
      if (query === 'pr')
        return 'Photo not found or not longer available on 100ASA'
      else if (query === 'ur')
        return 'User not found or not longer active on 100ASA'
      else
        return 'Something is going wrong with your request. Please retry later'
    }
  },
  created() {
    this.darkMode = this.$store.state.account.darkMode
    this.$vuetify.theme.light = !this.darkMode
    this.$vuetify.theme.dark = this.darkMode
  },
  destroyed() {
    if (this.darkModeTimeoutId) {
      clearTimeout(this.darkModeTimeoutId)
    }
  },
  mounted() {
    this.darkModeTimeoutId = setTimeout(() => {
      this.$vuetify.theme.light = !this.darkMode
      this.$vuetify.theme.dark = this.darkMode
    })
  },
  methods: {
    goBack() {
      const query = this.$route.query.err
      this.$router.go(query === 'pr' || query === 'ur' ? -2 : -1)
    }
  }
}
</script>
