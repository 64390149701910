<template>
  <v-btn
    v-if="showButton"
    :color="$vuetify.theme.dark ? 'grey darken-4' : 'primary'"
    fab
    large
    fixed
    bottom
    right
    @click="goOnTop"
  >
    <v-icon>fal fa-arrow-to-top</v-icon>
  </v-btn>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'

export default {
  name: 'BackToTop',
  props: {
    offsetTop: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showButton() {
      return this.offsetTop > 5000
    }
  },
  methods: {
    goOnTop() {
      goTo(0)
    }
  }
}
</script>
