export const strict = false

const getDefaultState = () => {
  return {
    header: true,
    loaderDialogVisible: false,
    curationSubmissionLimitDialogVisible: false,
    globalNotificationsDialogVisible: false,
    globalNotificationsDialogMessage: [],
    globalNotificationsDialogUid: undefined
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_HEADER: (state, value) => {
    state.header = value
  },

  SET_LOADER_DIALOG: (state, value) => {
    state.loaderDialogVisible = value
  },

  SET_CURATION_SUBMISSION_LIMIT_DIALOG: (state, value) => {
    state.curationSubmissionLimitDialogVisible = value
  },

  SET_GLOBAL_NOTIFICATIONS_DIALOG: (state, payload) => {
    state.globalNotificationsDialogVisible = payload.visible || false
    state.globalNotificationsDialogMessage = payload.message || []
    state.globalNotificationsDialogUid = payload.uid || undefined
  }
}

export const actions = {
  async nuxtServerInit({ dispatch }) {
    if (this.$auth.user) {
      await dispatch('account/setDarkMode', this.$auth.user.darkMode)
    }
  },

  setHeader({ commit }, value) {
    commit('SET_HEADER', value)
  },

  clear({ dispatch }) {
    dispatch('account/resetStore')
    dispatch('adverts/resetStore')
    dispatch('albums/resetStore')
    dispatch('analytics/resetStore')
    dispatch('authentication/resetStore')
    dispatch('comments/resetStore')
    dispatch('discussion/resetStore')
    dispatch('feeds/resetStore')
    dispatch('following/resetStore')
    dispatch('galleries/resetStore')
    dispatch('notifications/resetStore')
    dispatch('photos/resetStore')
    dispatch('portfolio/resetStore')
    dispatch('stats/resetStore')
    dispatch('users/resetStore')
    dispatch('critiques/resetStore')
  }
}
