export const strict = false

export const state = () => ({
  bestSellingList: [],
  firstToBuyList: [],
  topCategoriesList: [],
  selectedPhoto: {},
  showDialog: false
})

export const mutations = {
  OPEN_DIALOG: (state, photo) => {
    state.selectedPhoto = photo
    state.showDialog = true
  },

  CLOSE_DIALOG: state => {
    state.selectedPhoto = {}
    state.showDialog = false
  },

  SET_BEST_SELLING_LIST: (state, list) => {
    state.bestSellingList = list
  },

  SET_FIRST_TO_BUY_LIST: (state, list) => {
    state.firstToBuyList = list
  },

  SET_TOP_CATEGORIES_LIST: (state, list) => {
    state.topCategoriesList = list
  }
}

export const actions = {
  openDialog({ commit }, photo) {
    commit('OPEN_DIALOG', photo)
  },

  closeDialog({ commit }) {
    commit('CLOSE_DIALOG')
  },

  async getShopPhotos({ commit }) {
    const bestSellingListResponse = await this.$axios.$get(
      '/photos/best-selling'
    )
    const firstToBuyListResponse = await this.$axios.$get(
      '/photos/first-to-buy'
    )
    const topCategoriesListResponse = await this.$axios.$get(
      '/photos/top-categories'
    )
    commit('SET_BEST_SELLING_LIST', bestSellingListResponse.result)
    commit('SET_FIRST_TO_BUY_LIST', firstToBuyListResponse.result)
    commit('SET_TOP_CATEGORIES_LIST', topCategoriesListResponse.result)
  }
}
