<template>
  <v-app
    ref="app"
    v-scroll="onScroll"
    :dark="darkMode"
    :style="{ background: 'secondary' }"
  >
    <Header :me="me" />
    <v-main fill-height>
      <v-container
        fluid
        class="pa-0 mb-12 fill-height align-baseline w-100-percentage"
      >
        <div class="w-100-percentage">
          <nuxt />
        </div>
      </v-container>
    </v-main>
    <Footer />
    <Cookies-consent />
    <Snackbar />
    <Confirm-dialog />
    <New-version-dialog />
    <Chat v-if="me" :me="me" />
    <Loader-dialog />
    <Curation-message-dialog />
    <Curation-submission-limit-dialog />
    <Global-notifications-dialog />
    <Tutorial />
    <Back-to-top :offset-top="offsetTop" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/layouts/Header.vue'
import Footer from '@/components/layouts/Footer.vue'
import types from '@/utils/types'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer,
    CookiesConsent: () => import('@/components/layouts/CookiesConsent.vue'),
    Chat: () => import('@/components/layouts/Chat.vue'),
    LoaderDialog: () => import('@/components/layouts/LoaderDialog.vue'),
    BackToTop: () => import('@/components/layouts/BackToTop.vue'),
    Snackbar: () => import('@/components/layouts/Snackbar.vue'),
    ConfirmDialog: () => import('@/components/layouts/ConfirmDialog.vue'),
    CurationMessageDialog: () =>
      import('@/components/layouts/CurationMessageDialog.vue'),
    NewVersionDialog: () => import('@/components/layouts/NewVersionDialog.vue'),
    CurationSubmissionLimitDialog: () => import('@/components/layouts/CurationSubmissionLimitDialog.vue'),
    GlobalNotificationsDialog: () => import('@/components/layouts/GlobalNotificationsDialog.vue'),
    Tutorial: () => import('@/components/layouts/Tutorial.vue')
  },
  data() {
    return {
      darkMode: false,
      offsetTop: 0,
      darkModeTimeoutId: undefined,
      notificationsIntervalId: undefined
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.user,
      notifications: state => state.notifications.list,
      notes: state => state.notifications.notes
    })
  },
  created() {
    this.darkMode = this.$store.state.account.darkMode
    this.$vuetify.theme.light = !this.darkMode
    this.$vuetify.theme.dark = this.darkMode
  },
  destroyed() {
    if (this.darkModeTimeoutId) {
      clearTimeout(this.darkModeTimeoutId)
    }
    if (this.notificationsIntervalId) {
      clearInterval(this.notificationsIntervalId)
    }
  },
  async mounted() {
    this.darkModeTimeoutId = setTimeout(() => {
      this.$vuetify.theme.light = !this.darkMode
      this.$vuetify.theme.dark = this.darkMode
    })
    if (this.me && this.me.id) {
      try {
        await this.$auth.fetchUser()
        const notificationData = {
          page: 1,
          periodic: true
        }
        if (this.me.isAdmin || this.me.role === types.user.role.curator) {
          await this.$store.dispatch('notifications/getNotes', notificationData)
        }
        await this.$store.dispatch(
          'notifications/getNotifications',
          notificationData
        )
        await this.$store.dispatch('photos/getNumberOfPhotosInCuration')
        const globalNotification = await this.$prismic.api.getSingle('global_notifications')
        const isGlobalNotificationActive = globalNotification.data.active
        const prefix = process.env.NODE_ENV === 'production' ? '100asa.' : '100asadev.'
        const globalNotificationCookieName = `${prefix}${globalNotification.uid}`
        const globalNotificationCookie = this.$cookies.get(globalNotificationCookieName)
        if (isGlobalNotificationActive && globalNotificationCookie !== 'true') {
          this.$store.commit('SET_GLOBAL_NOTIFICATIONS_DIALOG', {
            visible: true,
            message: globalNotification.data.message,
            uid: globalNotification.uid
          })
        }
        this.notificationsIntervalId = setInterval(async () => {
          if (
            this.$route.path !== '/notes' &&
            (this.me.isAdmin || this.me.role === types.user.role.curator)
          ) {
            await this.$store.dispatch(
              'notifications/getNotes',
              notificationData
            )
          }
          if (this.$route.path !== '/notifications') {
            await this.$store.dispatch(
              'notifications/getNotifications',
              notificationData
            )
          }
          await this.$auth.fetchUser()
          await this.$store.dispatch('photos/getNumberOfPhotosInCuration')
        }, 1000 * 600)
      } catch (error) {
        this.$asa.appInsights('Layout: default.vue - method: mounted', error)
      }
    }
  },
  methods: {
    onScroll() {
      setTimeout(() => {
        const top = document.getElementById('app').getBoundingClientRect().top * -1
        this.offsetTop = top
      })
    }
  }
}
</script>

<style>
/* Global Style */
.h-60 {
  height: 60px;
}
.va-top {
  vertical-align: top;
}
.z-1 {
  position: relative;
  z-index: 1;
}
.w-100-percentage {
  width: 100%;
}
.w-100 {
  width: 100px;
}
.h-100-percentage {
  height: 100%;
}
.w-30 {
  width: 30px;
}
.mw-480 {
  display: inline-block;
  max-width: 480px;
}
.minor-line-height {
  line-height: 1.1 !important;
}
.no-underline {
  text-decoration: none;
}
.text-nowrap {
  white-space: nowrap;
}
.text-normal {
  white-space: normal;
}
.p-relative {
  position: relative;
}
.v-tabs.tile .v-tabs-bar {
  border-radius: 0;
}
.main-footer {
  flex-direction: column;
}
.main-footer a {
  font-size: 75%;
  text-decoration: none;
}
.footer-links a:hover {
  text-decoration: underline;
}
.layout-enter-active,
.layout-leave-active {
  transition: opacity 0.5s;
}
.layout-enter,
.layout-leave-active {
  opacity: 0;
}
.no-list-style {
  list-style: none;
  padding-left: 0 !important;
}
.v-align-sub {
  vertical-align: sub !important;
}
p,
a,
span {
  word-break: break-word;
}
.list-inline {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
}
.list-inline li {
  display: inline-block;
}
.page-enter-active,
.page-leave-active {
  transition: opacity 0.45s ease-in-out;
}
.page-enter,
.page-leave-active {
  opacity: 0.75;
}
</style>
