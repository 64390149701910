import { set } from 'vue'
import uniqBy from 'lodash/uniqBy'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import moment from 'moment'
import types from '../utils/types'

export const strict = false

const getDefaultState = () => {
  return {
    countries: [],
    categories: [],
    selectedCategory: '0',
    selectedCategoryText: '',
    selectedSorting: 'desc',
    selectedPopularity: '',
    proMembers: false,

    uploaded: [],
    uploadedIsLastPage: false,
    uploadedPage: 1,

    pending: [],
    pendingIsLastPage: false,
    pendingPage: 1,

    published: [],
    publishedIsLastPage: false,
    publishedPage: 1,

    totalUploaded: 0,
    totalInCuration: 0,
    totalPublished: 0,

    bgPhotos: [],
    bgPhotosSize: undefined,
    bgPhotosLastFetched: -1,

    list: [],
    isLastPage: false,
    page: 1,

    curated: [],
    curatedIsLastPage: false,
    curatedPage: 1,

    viewCurated: false,

    uploadLoader: 0,

    darkMode: false,

    // Curation Dialog
    curationDialogVisible: false,
    curationDialogPhoto: null,

    // Curation sorround
    sorround: {},

    // Reload alert delay
    reloadDateTime: moment(),

    // Short store for album id
    // Before upload image to Album we store the id here
    // It get cleared once added
    tempAlbumId: null,

    // Curation Interval
    curationInterval: null,

    // Bonus Tokens
    bonusTokens: 0
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_COUNTRIES: (state, countries) => {
    state.countries = countries
  },

  SET_CATEGORIES: (state, categories) => {
    state.categories = categories
  },

  SET_UPLOADED_PHOTOS: (state, photos) => {
    const uploaded = [...state.uploaded, ...photos]
    state.uploaded = uniqBy(uploaded, 'id')
  },

  SET_UPLOADED_IS_LAST_PAGE: (state, isLastPage) => {
    state.uploadedIsLastPage = isLastPage
  },

  SET_UPLOADED_PAGE: (state, page) => {
    state.uploadedPage = page
  },

  SET_PENDING_PHOTOS: (state, photos) => {
    const pending = [...state.pending, ...photos]
    state.pending = uniqBy(pending, 'id')
  },

  SET_PENDING_IS_LAST_PAGE: (state, isLastPage) => {
    state.pendingIsLastPage = isLastPage
  },

  SET_PENDING_PAGE: (state, page) => {
    state.pendingPage = page
  },

  SET_PUBLISHED_PHOTOS: (state, photos) => {
    const published = [...state.published, ...photos]
    state.published = uniqBy(published, 'id')
  },

  SET_PUBLISHED_IS_LAST_PAGE: (state, isLastPage) => {
    state.publishedIsLastPage = isLastPage
  },

  SET_PUBLISHED_PAGE: (state, page) => {
    state.publishedPage = page
  },

  SET_BG_PHOTOS: (state, photos) => {
    const bgPhotos = [...state.bgPhotos, ...photos]
    state.bgPhotos = uniqBy(bgPhotos, 'id')
  },

  SET_BG_SIZE: (state, size) => {
    state.bgPhotosSize = size
  },

  SET_BG_LAST_FETCHED: (state, lastFetched) => {
    state.bgPhotosLastFetched = lastFetched
  },

  SET_UPLOADED_PHOTO: (state, photo) => {
    // state.pending.unshift(photo)
    state.uploaded.unshift(photo)
  },

  SET_PUBLISHED_PHOTO: (state, photo) => {
    state.published.unshift(photo)
  },

  SET_PHOTOS: (state, photos) => {
    const list = [...state.list, ...photos]
    state.list = uniqBy(list, 'id')
  },

  SET_PHOTO_LIKE: (state, data) => {
    if (state.curationDialogPhoto && state.curationDialogPhoto.id) {
      state.curationDialogPhoto.numberOfLikes = data.numberOfLikes
    }
  },

  SET_BONUS_TOKENS: (state, tokens) => {
    state.bonusTokens = tokens
  },

  EDIT_PHOTO: (state, photo) => {
    const list =
      photo.statusName === types.photo.status.inCuration
        ? 'pending'
        : 'published'
    const index = findIndex(state[list], p => p.id === photo.id)
    if (index > -1) {
      set(state[list], index, photo)
    }
  },

  SET_IS_LAST_PAGE: (state, isLastPage) => {
    state.isLastPage = isLastPage
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_CURATED_PHOTOS: (state, photos) => {
    const curated = [...state.curated, ...photos]
    state.curated = uniqBy(curated, 'id')
  },

  SET_CURATED_IS_LAST_PAGE: (state, isLastPage) => {
    state.curatedIsLastPage = isLastPage
  },

  SET_CURATED_PAGE: (state, page) => {
    state.curatedPage = page
  },

  MOVE_PHOTO_TO_CURATION: (state, photo) => {
    // set photo in curate gallery
    state.list.unshift(photo)
    // remove photo from manage photos published tab
    const index = findIndex(state.published, p => p.id === photo.id)
    if (index > -1) {
      state.published.splice(index, 1)
    }
    // add photo to in curation tab
    state.pending.unshift(photo)
  },

  MOVE_CURATED_PHOTO: (state, id) => {
    const i = findIndex(state.list, p => p.id === id)
    if (i > -1) {
      const photo = state.list[i]
      state.list.splice(i, 1)
      state.curated.unshift(photo)
    }
  },

  REMOVE_PHOTO: (state, id) => {
    // uploaded
    const uploadedIndex = findIndex(state.uploaded, p => p.id === id)
    if (uploadedIndex > -1) {
      state.uploaded.splice(uploadedIndex, 1)
      state.totalUploaded = state.totalUploaded - 1
    }
    // pending
    const pendingIndex = findIndex(state.pending, p => p.id === id)
    if (pendingIndex > -1) {
      state.pending.splice(pendingIndex, 1)
      state.totalInCuration = state.totalInCuration - 1
    }
    // published
    const publishedIndex = findIndex(state.published, p => p.id === id)
    if (publishedIndex > -1) {
      state.published.splice(publishedIndex, 1)
      state.totalPublished = state.totalPublished - 1
    }
    // curated
    const curatedIndex = findIndex(state.curated, p => p.id === id)
    if (curatedIndex > -1) state.curated.splice(curatedIndex, 1)
    // list
    const listIndex = findIndex(state.list, p => p.id === id)
    if (listIndex > -1) state.list.splice(listIndex, 1)
  },

  SET_SORTING: (state, value) => {
    state.selectedPopularity = ''
    state.selectedSorting = value
  },

  SET_POPULARITY: (state, value) => {
    state.selectedSorting = ''
    state.selectedPopularity = value
  },

  TOGGLE_VIEW_CURATED: state => {
    state.viewCurated = !state.viewCurated
  },

  SET_SORROUND: (state, payload) => {
    state.sorround.previousPhotoId = payload.previousPhotoId
    state.sorround.nextPhotoId = payload.nextPhotoId
  },

  SET_CURATION_DIALOG: (state, payload) => {
    state.curationDialogVisible = payload.visible
    state.curationDialogPhoto = payload.photo || null
  },

  SET_RELOAD_ALERT_DATE_TIME: (state, dateTime) => {
    state.reloadDateTime = dateTime
  },

  INCREASE_COMMENT_COUNTER: (state, id) => {
    if (state.curationDialogPhoto && state.curationDialogPhoto.id === id) {
      state.curationDialogPhoto.numberOfComments = state.curationDialogPhoto.numberOfComments + 1
    }
  },

  SET_DARK_MODE: (state, value) => {
    state.darkMode = value
  },

  SET_TOTAL_PHOTOS: (state, payload) => {
    state.totalUploaded = payload.totalUploaded
    state.totalInCuration = payload.totalInCuration
    state.totalPublished = payload.totalPublished
  },

  SET_SUBMIT_FOR_CURATION: (state, photo) => {
    const index = findIndex(state.uploaded, p => p.id === photo.id)
    if (index > -1) {
      state.uploaded.splice(index, 1)
      state.pending.unshift(photo)
      state.totalInCuration = state.totalInCuration + 1
      state.totalUploaded = state.totalUploaded - 1
      if (state.list) {
        state.list.unshift(photo)
      }
    }
  },

  SET_RESUBMIT_FOR_CURATION: (state, photo) => {
    const index = findIndex(state.published, p => p.id === photo.id)
    if (index > -1) {
      state.published.splice(index, 1)
      state.pending.unshift(photo)
      state.totalInCuration = state.totalInCuration + 1
      state.totalPublished = state.totalPublished - 1
      if (state.list) {
        state.list.unshift(photo)
      }
    }
  },

  RESET_SORROUND: state => {
    state.sorround = {}
  },

  RESET_IN_CURATION_STORE: state => {
    state.list.length = 0
    state.isLastPage = false
    state.page = 1
  },

  RESET_CURATED_STORE: state => {
    state.curated.length = 0
    state.curatedIsLastPage = false
    state.curatedPage = 1
  },

  RESET_PHOTOS: state => {
    state.uploaded = []
    state.uploadedIsLastPage = false
    state.uploadedPage = 1
    state.pending = []
    state.pendingIsLastPage = false
    state.pendingPage = 1
    state.published = []
    state.publishedIsLastPage = false
    state.publishedPage = 1
  },

  SET_CATEGORY: (state, category) => {
    state.selectedCategory = category === '0' ? '' : category
  },

  SET_CATEGORY_TEXT: (state, categoryText) => {
    state.selectedCategoryText = categoryText
  },

  SET_PRO_MEMBERS: (state, value) => {
    state.proMembers = value
  },

  SET_UPLOAD_LOADER: (state, value) => {
    state.uploadLoader = value
  },

  SET_TEMP_ALBUM_ID: (state, value) => {
    state.tempAlbumId = value
  },

  SET_CURATION_INTERVAL: (state, value) => {
    state.curationInterval = value
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getPhotosInCuration({ commit, state, rootState }, data) {
    const size = 30
    const page = data.page
    let query = `?size=${size}&page=${page}&curated=false`
    query +=
      rootState.auth.user && !rootState.auth.user.isNudeFilterActive
        ? '&nude=true'
        : '&nude=false'
    if (data.category && data.category !== state.selectedCategory) {
      const categoryData = getCategoryData(data.category, state.categories)
      commit('RESET_IN_CURATION_STORE')
      commit('SET_CATEGORY', categoryData.id)
      commit('SET_CATEGORY_TEXT', categoryData.text || '')
    }
    if (state.selectedCategory !== '0') {
      query += `&category=${state.selectedCategoryText}`
    }
    if (data.sort && data.sort !== state.selectedSorting) {
      commit('RESET_IN_CURATION_STORE')
      commit('SET_SORTING', data.sort)
    }
    if (state.selectedSorting) {
      query += `&sort=${state.selectedSorting}`
    }
    if (data.popularity && data.popularity !== state.selectedPopularity) {
      commit('RESET_IN_CURATION_STORE')
      commit('SET_POPULARITY', data.popularity)
    }
    if (state.selectedPopularity) {
      query += `&popularity=${state.selectedPopularity}`
    }
    if (state.proMembers) {
      query += `&promembers=true`
    }
    const response = await this.$axios.$get(`/me/curate${query}`)
    commit('SET_PHOTOS', response.result)
    commit('SET_IS_LAST_PAGE', response.isLastPage)
    commit('SET_PAGE', data.page)
  },

  async getCuratedPhotos({ commit, state, rootState }, data) {
    const size = 30
    const page = data.page
    let query = `?size=${size}&page=${page}&curated=true&sort=desc`
    query +=
      rootState.auth.user && !rootState.auth.user.isNudeFilterActive
        ? '&nude=true'
        : '&nude=false'
    if (data.category && data.category !== state.selectedCategory) {
      const categoryData = getCategoryData(data.category, state.categories)
      commit('RESET_IN_CURATION_STORE')
      commit('SET_CATEGORY', categoryData.id)
      commit('SET_CATEGORY_TEXT', categoryData.text || '')
    }
    if (state.selectedCategory !== '0')
      query += `&category=${state.selectedCategoryText}`
    const response = await this.$axios.$get(`/me/curate${query}`)
    commit('SET_CURATED_PHOTOS', response.result)
    commit('SET_CURATED_IS_LAST_PAGE', response.isLastPage)
    commit('SET_CURATED_PAGE', data.page)
  },

  async getUploadedPhotos({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&sort=desc&status=uploaded`
    const response = await this.$axios.$get(`/me/photos${query}`)
    commit('SET_UPLOADED_PHOTOS', response.result)
    commit('SET_UPLOADED_IS_LAST_PAGE', response.isLastPage)
    commit('SET_UPLOADED_PAGE', data.page)
  },

  async getPendingPhotos({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&sort=desc&status=in_curation`
    const response = await this.$axios.$get(`/me/photos${query}`)
    commit('SET_PENDING_PHOTOS', response.result)
    commit('SET_PENDING_IS_LAST_PAGE', response.isLastPage)
    commit('SET_PENDING_PAGE', data.page)
  },

  async getPublishedPhotos({ commit }, data) {
    const size = 30
    const page = data.page
    const query = `?size=${size}&page=${page}&sort=desc`
    const response = await this.$axios.$get(`/me/photos${query}`)
    commit('SET_PUBLISHED_PHOTOS', response.result)
    commit('SET_PUBLISHED_IS_LAST_PAGE', response.isLastPage)
    commit('SET_PUBLISHED_PAGE', data.page)
  },

  async getBackgroundPhotos({ commit, state }, data) {
    const take = 30
    const skip = take * data.current
    if (!state.bgPhotosSize || state.bgPhotosSize > skip) {
      const query = `?size=${take}&skip=${skip}&sort=desc`
      const response = await this.$axios.$get(`/me/bg-photos${query}`)
      if (
        !state.bgPhotosLastFetched ||
        state.bgPhotosLastFetched < data.current
      ) {
        commit('SET_BG_PHOTOS', response.result)
        commit('SET_BG_SIZE', response.totalCount)
        commit('SET_BG_LAST_FETCHED', data.current)
      }
    }
  },

  async getCountries({ commit }) {
    const countries = await this.$axios.$get('/countries')
    const sortCountries = sortBy(countries, ['text'])
    commit('SET_COUNTRIES', sortCountries)
  },

  async getCategories({ commit }) {
    const categories = await this.$axios.$get('/categories')
    const sortCategories = sortBy(categories, ['text'])
    commit('SET_CATEGORIES', sortCategories)
  },

  async upload({ commit, dispatch, state }, data) {
    try {
      const photo = await this.$axios.$post('/photos/upload/v2', data)
      if (photo.statusName === types.photo.status.album) {
        const data = {
          photo,
          albumId: state.tempAlbumId
        }
        dispatch('albums/addPhotoToAlbumFromUpload', data, { root: true })
      } else {
        commit('SET_UPLOADED_PHOTO', photo)
      }
    } finally {
      commit('SET_UPLOAD_LOADER', 0)
    }
  },

  setTempAlbumId({ commit }, value) {
    commit('SET_TEMP_ALBUM_ID', value)
  },

  async backgroundImageUpload({ commit }, data) {
    try {
      await this.$axios.$post('/me/background-profile-image', data, {
        onUploadProgress: progressEvent => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader(
                'x-decompressed-content-length'
              )
          if (totalLength !== null) {
            const loader = Math.round(
              (progressEvent.loaded * 100) / totalLength
            )
            commit('SET_UPLOAD_LOADER', loader)
          }
        }
      })
    } finally {
      commit('SET_UPLOAD_LOADER', 0)
    }
  },

  async patchUserAvatar({ commit }, data) {
    await this.$axios.$patch('/me/avatar', data)
    await this.$auth.fetchUser()
  },

  async patchUserProfile({ commit }, data) {
    await this.$axios.$patch('/me', data)
    await this.$auth.fetchUser()
  },

  async patchUserNotifications({ commit }, data) {
    await this.$axios.$patch('/me/notifications', {
      notificationSettings: data
    })
    await this.$auth.fetchUser()
  },

  async deactivate({ commit }, id) {
    await this.$axios.$post('/me/deactivate', {
      user: id
    })
  },

  setSorround({ commit, state }, photoId) {
    const index = findIndex(state.list, p => p.id === photoId)
    if (index > -1) {
      const previousPhotoId = index === 0 ? null : state.list[index - 1].id
      const nextPhotoId =
        state.list[index + 1] && state.list[index + 1].id
          ? state.list[index + 1].id
          : null
      commit('SET_SORROUND', {
        previousPhotoId,
        nextPhotoId
      })
    }
  },

  async getTotalPhotoCount({ commit }) {
    const response = await this.$axios.$get('/me/total-photos')
    commit('SET_TOTAL_PHOTOS', response)
  },

  async submitForCuration({ commit }, id) {
    const photo = await this.$axios.$post(
      `/photos/${id}/submit-for-curation`,
      {
        id
      }
    )
    commit('SET_SUBMIT_FOR_CURATION', photo)
  },

  async reSubmitForCuration({ commit }, id) {
    const photo = await this.$axios.$post(
      `/photos/${id}/re-submit-curation`,
      { id }
    )
    commit('SET_RESUBMIT_FOR_CURATION', photo)
  },

  async saveFeedsSettings({ commit }, data) {
    await this.$axios.$post('/me/save-feeds-settings', data)
  },

  setDarkMode({ commit }, value) {
    commit('SET_DARK_MODE', value)
  },

  resetSorround({ commit }) {
    commit('RESET_SORROUND')
  },

  setCurationDialog({ commit }, payload) {
    commit('SET_CURATION_DIALOG', payload)
  },

  setReloadAlertDateTime({ commit }) {
    const now = moment()
    commit('SET_RELOAD_ALERT_DATE_TIME', now)
  },

  toggleViewCurated({ commit }) {
    commit('TOGGLE_VIEW_CURATED')
  },

  resetInCurationStore({ commit }) {
    commit('RESET_IN_CURATION_STORE')
  },

  resetCuratedStore({ commit }) {
    commit('RESET_CURATED_STORE')
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}

function getCategoryData(category, categories) {
  const isCategoryValue = parseInt(category)
  let categoryId
  let categoryText = ''
  if (isNaN(isCategoryValue) && category !== '0') {
    categoryId = find(categories, c => c.text.toLowerCase() === category).value
  } else {
    categoryId = category
  }
  if (categoryId !== '0') {
    categoryText = find(categories, c => {
      return c.value === categoryId
    }).text.toLowerCase()
  }
  return {
    id: categoryId,
    text: categoryText
  }
}
