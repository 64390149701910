export const strict = false

const getDefaultState = () => {
  return {
    visible: false,
    title: '',
    link: ''
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_VISIBLE: (state, payload) => {
    state.visible = payload.visible
    state.title = payload.title || ''
    state.link = payload.link || ''
  }
}

export const actions = {
  setVisibility({ commit }, payload) {
    commit('SET_VISIBLE', payload)
  }
}
