/* eslint-disable camelcase */
export const strict = false

const getDefaultState = () => {
  return {
    competitions: [],
    page: 1,
    totalPages: null,
    competition: null,
    loading: false
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_COMPETITIONS: (state, competitions) => {
    state.competitions = [...state.competitions, ...competitions]
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_TOTAL_PAGES: (state, totalPages) => {
    state.totalPages = totalPages
  },

  SET_COMPETITION: (state, competition) => {
    state.competition = competition
  },

  SET_LOADING: (state, value) => {
    state.loading = value
  },

  RESET_COMPETITIONS: state => {
    state.competitions = []
  }
}

export const actions = {
  async getCompetitions({ commit, state }) {
    const pageSize = 10
    const page = state.page
    const query = [this.$prismic.predicates.at('document.type', 'competition')]
    if (state.competitions.length === 0) {
      try {
        commit('SET_LOADING', true)
        const { results, total_pages } = await this.$prismic.api.query(query, {
          orderings: '[my.competition.date desc]',
          pageSize,
          page
        })
        commit('SET_COMPETITIONS', results)
        if (state.totalPages !== total_pages) {
          commit('SET_TOTAL_PAGES', total_pages)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },

  async getCompetition({ commit, state }, uid) {
    if (state.competition && state.competition.uid) {
      commit('SET_COMPETITION', null)
    }
    try {
      commit('SET_LOADING', true)
      const competition = await this.$prismic.api.getByUID('competition', uid)
      commit('SET_COMPETITION', competition)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  setPage({ commit }, page) {
    commit('SET_PAGE', page)
  }
}
