export const strict = false

const getDefaultState = () => {
  return {
    ga: {},
    api: {}
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_GA_STATS: (state, gaStats) => {
    state.ga = gaStats
  },

  SET_API_STATS: (state, apiStats) => {
    state.api = apiStats
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getGapiStats({ commit }) {
    const gaStats = await this.$getGapiClient()
    commit('SET_GA_STATS', gaStats)
  },

  async getApiStats({ commit }) {
    const apiStats = await this.$axios.$get('/stats')
    commit('SET_API_STATS', apiStats)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
