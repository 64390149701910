/* eslint-disable camelcase */
export const strict = false

const getDefaultState = () => {
  return {
    posts: [],
    page: 1,
    totalPages: null,
    post: null,
    category: {
      id: '',
      name: ''
    },
    loading: false
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_POSTS: (state, posts) => {
    state.posts = [...state.posts, ...posts]
  },

  SET_PAGE: (state, page) => {
    state.page = page
  },

  SET_TOTAL_PAGES: (state, totalPages) => {
    state.totalPages = totalPages
  },

  SET_POST: (state, post) => {
    state.post = post
  },

  SET_LOADING: (state, value) => {
    state.loading = value
  },

  SET_CATEGORY: (state, category) => {
    state.category.id = category.id
    state.category.name = category.slug
  },

  RESET_POSTS: state => {
    state.posts = []
  },

  RESET_STORE: state => {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getPosts({ commit, state }) {
    const pageSize = 10
    const page = state.page
    const query = [this.$prismic.predicates.at('document.type', 'post')]
    if (state.category && state.category.id) {
      query.push(
        this.$prismic.predicates.at('my.post.post_category', state.category.id)
      )
    }
    try {
      commit('SET_LOADING', true)
      const { results, total_pages } = await this.$prismic.api.query(query, {
        orderings: '[my.post.date desc]',
        pageSize,
        page
      })
      commit('SET_POSTS', results)
      if (state.totalPages !== total_pages) {
        commit('SET_TOTAL_PAGES', total_pages)
      }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getPost({ commit, state }, uid) {
    if (state.post && state.post.uid) {
      commit('SET_POST', null)
    }
    try {
      commit('SET_LOADING', true)
      const post = await this.$prismic.api.getByUID('post', uid)
      if (!post) {
        throw new Error('Exception message')
      } else {
        commit('SET_POST', post)
      }
    } catch (error) {
      if (error) {
        throw error
      } else {
        throw new Error('Exception message')
      }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  setPage({ commit }, page) {
    commit('SET_PAGE', page)
  },

  changeCategory({ commit, dispatch }, category) {
    commit('SET_CATEGORY', category)
    commit('RESET_POSTS')
    dispatch('setPage', 1)
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}
