import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import types from '@/utils/types'
import getImageUrl from '@/helpers/transformer'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.azureIkey
  }
})
appInsights.loadAppInsights()

export default function (ctx, inject) {
  const asa = {}

  asa.appInsights = (text = '', error = null) => {
    const userId =
      ctx.store.state.auth.user &&
      ctx.store.state.auth.user
        ? ctx.store.state.auth.user.id
        : undefined

    // appInsights.trackTrace({
    //   message: `User: ${
    //     userId || 'Guest'
    //   }, Error: ${text}, Time: ${new Date().toUTCString()}`
    // })
    appInsights.trackException({
      exception: error,
      properties: {
        user: userId || 'Guest',
        text,
        time: new Date().toUTCString(),
        errorToString: error.toString()
      }
    })
  }

  asa.getFriendlyPhotoStatusName = status => {
    switch (status) {
      case types.photo.status.uploaded:
        return 'uploaded'
      case types.photo.status.inspiration:
        return 'inspiration'
      case types.photo.status.elite:
        return 'elite'
      case types.photo.status.prime:
        return 'prime'
      case types.photo.status.upcoming:
        return 'upcoming'
      case types.photo.status.opening:
        return 'opening'
      case types.photo.status.album:
        return 'in album'
      case types.photo.status.inCuration:
        return 'in curation'
      default:
        return 'unknown'
    }
  }

  asa.getPublishedPhotoStatusIcon = status => {
    switch (status) {
      case types.photo.status.elite:
        return 'grin-stars'
      case types.photo.status.prime:
      case types.photo.status.inspiration:
        return 'grin-hearts'
      case types.photo.status.upcoming:
        return 'smile-beam'
      case types.photo.status.opening:
        return 'smile'
      default:
        return ''
    }
  }

  asa.getPhotoStatusColor = status => {
    switch (status) {
      case types.photo.status.uploaded:
        return 'brown'
      case types.photo.status.inspiration:
        return 'lime darken-2'
      case types.photo.status.elite:
        return 'gold'
      case types.photo.status.prime:
        return 'success'
      case types.photo.status.upcoming:
        return 'info'
      case types.photo.status.opening:
        return 'purple'
      case types.photo.status.album:
        return 'teal'
      case types.photo.status.inCuration:
        return 'indigo'
      default:
        return 'grey'
    }
  }

  asa.checkAndSetTagsAndCategory = (selectedTags, selectedCategoryText) => {
    // Check if the gallery must be reloaded
    let reload = false

    // Check for tags and category in the URL
    // If present, update the store
    const queryTags = ctx.route.query.tags
    const queryCategoryText = ctx.route.query.category

    if (queryTags && queryTags !== selectedTags) {
      ctx.store.dispatch('photos/setTags', queryTags)
      reload = true
    }
    if (queryCategoryText && queryCategoryText !== selectedCategoryText) {
      ctx.store.dispatch('photos/setCategory', queryCategoryText)
      reload = true
    }
    if (ctx.store.state.photos.reload) {
      reload = true
      ctx.store.dispatch('photos/setReload', false)
    }

    // Check for tags and category in the store
    // If present, update the URL
    const q = {}
    if (selectedTags && !queryTags) {
      q.tags = selectedTags
    }
    if (selectedCategoryText && !queryCategoryText) {
      q.category = selectedCategoryText
    }
    if (Object.keys(q).length) {
      ctx.query = q
    }

    return reload
  }

  asa.capitalize = str => {
    if (typeof str !== 'string') return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  asa.hasAnActiveSubscription = () => {
    return (
      ctx.store.state.auth.user &&
      ctx.store.state.auth.user.hasAnActiveSubscription
    )
  }

  asa.isBasicMember = () => {
    return (
      ctx.store.state.auth.user &&
      ctx.store.state.auth.user.hasAnActiveSubscription &&
      ctx.store.state.auth.user.subscriptionType === types.subscription.type.basic
    )
  }

  asa.isProMember = () => {
    return (
      ctx.store.state.auth.user &&
      ctx.store.state.auth.user.hasAnActiveSubscription &&
      ctx.store.state.auth.user.subscriptionType === types.subscription.type.pro
    )
  }

  asa.isProPlusMember = () => {
    return (
      ctx.store.state.auth.user &&
      ctx.store.state.auth.user.hasAnActiveSubscription &&
      ctx.store.state.auth.user.subscriptionType ===
        types.subscription.type.proPlus
    )
  }

  asa.getMeSubscriptionType = () => {
    let subType
    if (asa.isBasicMember()) {
      subType = 'BASIC'
    } else if (asa.isProMember()) {
      subType = 'PRO'
    } else if (asa.isProPlusMember()) {
      subType = 'PRO +'
    } else {
      subType = ''
    }
    return subType
  }

  asa.canNominate = () => {
    const hasAnActiveSubscription = asa.hasAnActiveSubscription()
    const isGrandmasterOrAbove =
      ctx.store.state.auth.user &&
      (ctx.store.state.auth.user.role === types.user.role.grandMaster ||
        ctx.store.state.auth.user.role === types.user.role.artist ||
        ctx.store.state.auth.user.role === types.user.role.legend ||
        ctx.store.state.auth.user.role === types.user.role.ambassador)
    return hasAnActiveSubscription || isGrandmasterOrAbove
  }

  asa.getImageUrl = (photo, type, fast = false) => {
    let photoPath

    if (typeof photo === 'string') {
      photoPath = photo
    } else if (photo && (photo.url || photo.thumbnailUrl || type === 'feed')) {
      photoPath =
        type === 'full' || type === 'zoom' ? photo.url : photo.thumbnailUrl
    } else {
      photoPath = undefined
    }

    if (photoPath) {
      return getImageUrl(
        photoPath,
        type,
        photo.personalWatermark || false,
        fast
      )
    }
  }

  inject('asa', asa)
}
