<template>
  <div v-if="loading" class="loading-page">
    <v-icon large>fal fa-spinner fa-spin</v-icon>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data: () => ({
    loading: false
  }),
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.loading-page {
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
</style>
