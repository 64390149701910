import Vue from 'vue'

let delayedCalls = []
let SentryMock = {}

let sentryReadyResolve
let loadInitiated = false
let loadCompleted = false

let delayedGlobalErrors = []
let delayedUnhandledRejections = []
/** @param {ErrorEvent} event */
const delayGlobalError = function (event) {
  delayedGlobalErrors.push([event.message, event.filename, event.lineno, event.colno, event.error])
}
const delayUnhandledRejection = function (event) {
  delayedUnhandledRejections.push('reason' in event ? event.reason : 'detail' in event && 'reason' in event.detail ? event.detail.reason : event)
}

const vueErrorHandler = Vue.config.errorHandler

Vue.config.errorHandler = (error, vm, info) => {
  if (!loadCompleted) {
    if (vm) {
      vm.$sentry.captureException(error)
    }

    if (Vue.util) {
      Vue.util.warn(`Error in ${info}: "${error.toString()}"`, vm)
    }
    console.error(error)
  }

  if (vueErrorHandler) {
    return vueErrorHandler(error, vm, info)
  }
}

export default function SentryPlugin (ctx, inject) {
  /* eslint-disable-next-line quotes, comma-spacing */
  const apiMethods = ["attachErrorHandler","createTracingMixins","init","vueRouterInstrumentation","attachErrorHandler","createTracingMixins","FunctionToString","Hub","InboundFilters","Scope","addBreadcrumb","addGlobalEventProcessor","addTracingExtensions","captureEvent","captureException","captureMessage","configureScope","createTransport","extractTraceparentData","getActiveTransaction","getCurrentHub","getHubFromCarrier","makeMain","setContext","setExtra","setExtras","setTag","setTags","setUser","spanStatusfromHttpCode","startTransaction","trace","withScope","BrowserClient","makeFetchTransport","makeXHRTransport","defaultStackParser","eventFromException","eventFromMessage","close","flush","forceLoad","lastEventId","onLoad","showReportDialog","wrap","Replay","BrowserTracing","makeBrowserOfflineTransport","onProfilingStartRouteTransaction","BrowserProfilingIntegration","GlobalHandlers","TryCatch","Breadcrumbs","LinkedErrors","HttpContext","Dedupe","init","vueRouterInstrumentation"]
  apiMethods.forEach((key) => {
    SentryMock[key] = (...args) => delayedCalls.push([key, args])
  })

  window.addEventListener('error', delayGlobalError)
  window.addEventListener('unhandledrejection', delayUnhandledRejection)

  inject('sentry', SentryMock)
  ctx.$sentry = SentryMock

  const loadSentryHook = () => attemptLoadSentry(ctx, inject)

  window.onNuxtReady(loadSentryHook)

  const sentryReadyPromise = new Promise((resolve) => {
    sentryReadyResolve = resolve
  })

  const sentryReady = () => sentryReadyPromise

  inject('sentryReady', sentryReady)
  ctx.$sentryReady = sentryReady
}

async function attemptLoadSentry (ctx, inject) {
  if (loadInitiated) {
    return
  }

  loadInitiated = true

  if (!window.$nuxt) {
    // Wait for onNuxtReady hook to trigger.

    return
  }

  await loadSentry(ctx, inject)
}

async function loadSentry (ctx, inject) {
  if (loadCompleted) {
    return
  }

  const Sentry = await import(/* webpackChunkName: 'sentry' */ '~@sentry/vue')
  const { BrowserTracing } = await import(/* webpackChunkName: 'sentry' */ '~@sentry/tracing')
  const { ExtraErrorData, ReportingObserver, RewriteFrames } = await import(/* webpackChunkName: 'sentry' */ '~@sentry/integrations')

  /* eslint-disable quotes, key-spacing */
  const config = {
    Vue,
    dsn: "https:\u002F\u002Fb7b99ef01b514215829bbccd820c42e4@o4504904476196864.ingest.sentry.io\u002F4504904655241216",
    environment: "production",
    tracesSampleRate: 1,
    trackComponents: true,
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    const { default: merge } = await import(/* webpackChunkName: 'sentry' */ '~lodash.mergewith')
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
  ]

  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: Sentry.vueRouterInstrumentation(ctx.app.router) } : {}),
  }))

  Sentry.init(config)

  loadCompleted = true

  window.removeEventListener('error', delayGlobalError)
  window.removeEventListener('unhandledrejection', delayUnhandledRejection)
  if (delayedGlobalErrors.length) {
    if (window.onerror) {
      console.info('Reposting global errors after Sentry has loaded')
      for (const errorArgs of delayedGlobalErrors) {
        window.onerror.apply(window, errorArgs)
      }
    }
    delayedGlobalErrors = []
  }
  if (delayedUnhandledRejections.length) {
    if (window.onunhandledrejection) {
      console.info('Reposting unhandled promise rejection errors after Sentry has loaded')
      for (const reason of delayedUnhandledRejections) {
        window.onunhandledrejection(reason)
      }
    }
    delayedUnhandledRejections = []
  }
  delayedCalls.forEach(([methodName, args]) => Sentry[methodName].apply(Sentry, args))

  forceInject(ctx, inject, 'sentry', Sentry)

  sentryReadyResolve(Sentry)

  // help gc

  // Dont unset delayedCalls & SentryMock during
  // development, this will cause HMR issues

  delayedCalls = undefined
  SentryMock = undefined

  sentryReadyResolve = undefined
}

// Custom inject function that is able to overwrite previously injected values,
// which original inject doesn't allow to do.
// This method is adapted from the inject method in nuxt/vue-app/template/index.js
function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  window.$nuxt.$options[injectKey] = value
}
